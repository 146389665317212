import _ from "lodash"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import { LoadingIndicator } from "../../components/LoadingIndicator"
import AppContext from "../../Context"
import { PointItem } from "./components/point-item"
import iconMoney from "./images/money.png"

import { fetchPointHistoriesRequest } from "../../actions/mypage"

import "./style.scss"

const Plan = () => {
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)

  const me = useSelector((state) => {
    return state.auth.profile
  })

  const pointHistories = useSelector((state) => {
    return state.mypage.pointHistories
  })

  const isLoading = useSelector((state) => {
    return state.mypage.isLoading
  })

  useEffect(() => {
    dispatch(
      fetchPointHistoriesRequest(
        {},
        {
          onSuccess: (response) => {
            setContext("overlay", null)
          },
          onFailed: () => {
            setContext("overlay", null)
          },
        }
      )
    )
  }, [])

  const toStringNumber = (num) => {
    if (num == null || num == '') {
      return '0'
    }
    var nums = (num + '').split('.');
    return (Math.round(nums[0]) + "").replace(/(\d)(?=(\d{3})+$)/g, "$1,") + (nums.length > 1 && nums[1] != '0' ? ('.' + nums[1]) : '')
  }

  return (
    <Layout>
      <AppBar title="ポイント履歴" withBack />
      <div className="bg-white d-flex flex-column full-height-with-navbar">
        <div className="container point-histories-page">
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <div className="main-content">
              <b>ポイント残高の内訳</b>
              <div className="current-point">
                <b>ポイント残高</b>
                <div className="number-of-point">
                  <img src={iconMoney} alt="point" />
                  <h3>{toStringNumber(me.points_balance)}</h3>
                </div>
              </div>
              {pointHistories.user_points && pointHistories.user_points.length > 0 &&
                <>
                  <b>ポイント履歴</b>
                  <div className="point-histories">
                    {pointHistories.user_points.map((item, idx) => (
                      <PointItem key={idx} point={item} />
                    ))}
                  </div>
                </>
              }
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Plan
