import moment from "moment"
import InfiniteScroll from "react-infinite-scroll-component"

import { Message } from "./Message"
import { useRef, useEffect } from "react"
import { LoadingIndicator } from "../../../components/LoadingIndicator"
import _ from 'lodash'

export const MessageList = ({ messages, currentUserId, avatarUrl, textSearch, myProfile }) => {
  const showDate = (message, index) => {
    try {
      const currentDate = message.createdAt.toDate()
      const nextMsgDate = messages[index + 1]?.createdAt.toDate() || null
      const prevMsgDate = messages[index - 1]?.createdAt.toDate() || null
      // if (!moment(currentDate).isSame(moment(nextMsgDate), "day")) return true
      if (!moment(currentDate).isSame(moment(nextMsgDate), "day")) return true
      return false
    } catch (err) {
      console.log("Parse message date error: ")
    }
  }

  const bottomRef = useRef(null);
  useEffect(() => {
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [messages]);

  if(textSearch?.value) {
    var messageFilter = [];
    messages.map((message) => {
      if(message.message && message.message.includes(textSearch.value)) {
        messageFilter.push(message)
      }
    })
    messages = _.cloneDeep(messageFilter)
  }

  const showAvatar = (message, index) => {
    const currentId = message.userId
    const prevId = messages[index - 1]?.userId
    const nextId = messages[index + 1]?.userId
    if (currentId === nextId) return false
    return true
  }

  return (
    <div
      id="scrollableDiv"
      className="yu-gothic-regular mb-0"
      style={{
        height: "100%",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column-reverse",
        overflowX: 'hidden',
        padding: myProfile.contract_type === 2 ? "1rem 1rem" : "1rem 1rem 4rem"
      }}
    >
      <InfiniteScroll
        dataLength={messages.length}
        inverse
        // hasMore={true}
        next={(params) => console.log("next params: ", params)}
        loader={<LoadingIndicator sm />}
        initialScrollY={0}
        style={{ display: "flex", flexDirection: "column-reverse" }}
        scrollableTarget="scrollableDiv"
      >
        <div ref={bottomRef} />
        {messages.map((message, index) => (
          <Message
            key={index}
            message={message}
            isFromMe={message.userId === currentUserId}
            showDate={showDate(message, index)}
            showAvatar={showAvatar(message, index)}
            avatarUrl={avatarUrl}
          />
        ))}
      </InfiniteScroll>
    </div>
  )
}
