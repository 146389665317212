import { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { runProfileTrackingScript } from "./profileTrackingScript";

import "./styles.scss"
import {
  AppBar,
  Layout,
  ProfileForm,
  LoadingIndicator
} from "../../../components"
import {
  updateUserProfileRequest,
  updateUserProfileSuccess
} from "../../../actions/auth"
import AppContext from "../../../Context"
import routes from "../../../constants/routes"
import { registerWithPhoneRequest } from "../../../actions/register"

const Profile = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { setContext } = useContext(AppContext)
  const { phone, phoneVerified } = useSelector((state) => {
    return state.auth
  })

  const profile = useSelector((state) => {
    return state.auth.profile
  })

  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()

  useEffect(() => {
    runProfileTrackingScript();
  }, []);

  useEffect(() => {
    if (query.get("login") == null) {
      if (phoneVerified !== true) {
        history.push(routes.REGISTER_VERIFY_PHONE)
      } else if (!profile.is_created) {
        setContext("overlay", <LoadingIndicator />)
        dispatch(
          registerWithPhoneRequest(phone, {
            onSuccess: () => {
              setContext("overlay", null)
              localStorage.removeItem("registing")
            },
            onFailed: () => {
              setContext("overlay", null)
            }
          })
        )
      }
    }
  }, [])

  const handleSubmit = (values) => {
    setContext("overlay", <LoadingIndicator />)
    dispatch(
      updateUserProfileRequest(values, {
        onSuccess: (response) => {
          setContext("overlay", null)
          dispatch(updateUserProfileSuccess({ payload: { is_created: 1 } }))
          setTimeout(() => {
            history.push(routes.REGISTER_AVATARS)
          }, 100)
        },
        onFailed: () => {
          setContext("overlay", null)
        }
      })
    )
  }

  return (
    <Layout>
      <AppBar withBack title="新規登録" colorTitle="red"/>
      <div className="container register-profile">
        <ProfileForm onSubmit={handleSubmit} initial={profile} />
      </div>
    </Layout>
  )
}

export default Profile
