import { useState, useEffect } from "react"
import { TabPanel } from "react-headless-tabs"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import { ScheduleList } from "./components"
import {
  scheduleListRequested,
  myScheduleRequested
} from "../../actions/schedule"
import { useDispatch, useSelector } from "react-redux"

import "./styles.scss"
import { LoadingIndicator } from "../../components"

const Schedules = () => {
  const dispatch = useDispatch()
  const [bannerHeight, setBannerHeight] = useState(0)

  const myPlan = useSelector((state) => {
    return state?.schedule?.mySchedule
  })
  const users = useSelector((state) => {
    return state?.schedule?.schedule
  })

  const loading = useSelector((state) => state.schedule.isLoading)

  useEffect(() => {
    dispatch(myScheduleRequested())
    dispatch(scheduleListRequested())
  }, [dispatch])

  return (
    <Layout nav>
      <AppBar
        title="今からどう？"
        colorTitle="#666666"
        search
        placeholder=" "
        banner
        setBannerHeight={setBannerHeight}
      />
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className="bg-white d-flex flex-column border-top wrap-list-schedule" style={{ top: bannerHeight + 85 }}>
          <div className="h-100">
            <div className="h-100">
              <TabPanel className="h-100">
                <ScheduleList myPlan={myPlan} users={users} />
              </TabPanel>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Schedules
