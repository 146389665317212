import _ from "lodash"
import { useContext, useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Loader from "react-loader-spinner"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import { LoadingIndicator } from "../../components/LoadingIndicator"
import AppContext from "../../Context"
import { PointItem } from "./components/point-item"

import { fetchAuthProfileRequest } from "../../actions/auth"
import {
  fetchPointPackageRequest,
  purchasePointRequest
} from '../../actions/buy-point'

import "./style.scss"

const Plan = () => {
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(true)
  const [showPaymentPopup, setShowPaymentPopup] = useState(false)
  const [paymentUrl, setPaymentUrl] = useState('')
  const [showClose, setShowClose] = useState(true)
  const [showLoadingFrame, setShowLoadingFrame] = useState(false)
  const popupPayment = useRef(null);

  const profile = useSelector((state) => {
    return state.auth.profile
  })

  const pointPackages = useSelector((state) => {
    return state.buyPoint.pointPackages
  })

  useEffect(() => {
    getPointPackages();
    window.addEventListener('message', (e) => {
      try{
        if (e.data == 'payment-complete') {
          reload()
        }
        if (e.data == 'hidden-close-button') {
          setShowClose(false)
        }
      }
      catch(e) {}
    })
  }, [])

  const reload = () => {
    setIsLoading(true)
    dispatch(
      fetchAuthProfileRequest(
        {},
        {
          onSuccess: (response) => {
            setIsLoading(false)
            setContext("overlay", null)
          },
          onFailed: () => {
            setIsLoading(false)
            setContext("overlay", null)
          },
        }
      )
    )
    // getPointPackages()
    setPaymentUrl('')
    setShowPaymentPopup(false)
  }

  const getPointPackages = () => {
    dispatch(
      fetchPointPackageRequest(
        {
          onSuccess: (response) => {
            setIsLoading(false)
            setContext("overlay", null)
          },
          onFailed: () => {
            setIsLoading(false)
            setContext("overlay", null)
          },
        }
      )
    )
  }

  const onPurchase = (points_package_id) => {
    setIsLoading(true)
    dispatch(
      purchasePointRequest(
        {
          app_type: "Web",
          points_package_id: points_package_id,
          redirect_url: window.location.origin + "/payment-complete"
        },
        {
          onSuccess: (response) => {
            setIsLoading(false)
            setContext("overlay", null)
            if(response.url) {
              setPaymentUrl(response.url)
              setShowPaymentPopup(true)
              setShowClose(true)
              setShowLoadingFrame(true)
              setTimeout(() => {
                setShowLoadingFrame(false)
              }, 400);
            }
            else if(response.data.error && response.data.error.message) {
              alert(response.data.error.message)
            }
            else {
              alert("支払いに失敗しました。")
            }
          },
          onFailed: (response) => {
            setIsLoading(false)
            setContext("overlay", null)
            alert("支払いに失敗しました。")
          },
        }
      )
    )
  }

  return (
    <Layout>
      <AppBar title="ポイント購入" withBack />
      <div className="bg-white d-flex flex-column full-height-with-navbar">
        <div className="container pt-2 buy-point-page">
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <div className="main-content">
              <b>ご希望のポイントを選んでください。</b>
              <div className="point-plans">
                {pointPackages.map((item, idx) => (
                  item.points > 0 && <PointItem key={idx} point={item} onPurchase={onPurchase} />
                ))}
              </div>
              <div>
                <a href="point-histories" className="btn-point-histories">ポイント履歴</a>
              </div>
              <div className="note">
                <h2>ポイントプラン</h2>
                <p>
                  1,000ポイント　100,000円<br/>
                  500ポイント　50,500円<br/>
                  300ポイント　30,400円<br/>
                  100ポイント　11,000円<br/>
                  50ポイント　5,500円<br/>
                  30ポイント　3,300円<br/>
                  10ポイント　1,100円
                </p>
                <p>
                  ※ポイント説明<br/>
                  いいね交換できる<br/>
                  ユーザーに対してギフトを贈れる
                </p>
                <p>
                  ※ポイントは一度退会されますと全て抹消されますのでご注意下さい。
                </p>
                <h2>注意事項</h2>
                <ul>
                  <li>・お支払いはクレジットカードのみご利用いただけます。</li>
                  <li>
                    ・クレジットカードの情報は、SSL暗号化通信により、送信され管理されております。当サービスにはカード情報は全く届きませんのでご安心ください。
                  </li>
                  <li>
                    ・次回の更新日は会員ステータスページでご確認いただけ ます。
                  </li>
                  <li>
                    ・更新日前の解約のお手続きをなさった場合、残りの期間についての月割り・日割りでの返金はいたしません。
                  </li>
                  <li>
                    ・自動継続課金は期間の期限が切れる24時間以内に自動で行われます。
                  </li>
                  <li>
                    ・「購読の終了日の００：００」の24時間以上前に自動更新をオフにしなければ、継続決済は自動的に更新されます。
                  </li>
                  <li>
                    ・自動継続課金の手続き完了後、次回更新日を過ぎますと無料会員へ移行されます。（更新日までは有料会員のご利用が可能です）
                  </li>
                  <li>
                    ・【交換返品について】<br/>
                    本サービスで提供するサービスについては、購入手続き完了後の返品またはキャンセルはお受けできません。途中退会の場合も返金はお受けできません。
                  </li>
                </ul>
              </div>
            </div>
          )}
          {showPaymentPopup && paymentUrl !== '' &&
            <>
              <div className="popup-payment">
                {showClose && <button type="button" className="btn-cancel-payment" onClick={reload}>x</button>}
                <iframe src={paymentUrl} ref={popupPayment} title="Credix株式会社" frameBorder="0"></iframe>
              </div>
              {showLoadingFrame && 
                <div className="loading-frame">
                  <Loader
                    type="Oval"
                    color="grey"
                    width="4rem"
                    height="4rem"
                  />
                </div>
              }
            </>
          }
        </div>
      </div>
    </Layout>
  )
}

export default Plan
