import IdCardImage from "../../../../images/ic-card.svg"
import CheckSuccess from "../../../../icons/check-success.svg"
import { Image } from "react-bootstrap"
import "../styles.scss"

export const VerifySuccess = () => {
  return (
    <div className="h-100 d-flex flex-column align-items-center p-2">
      <div className="d-flex">
        <Image src={IdCardImage} width="150" className="m-4 with-success" />
        <Image src={CheckSuccess} width="30" className="success-icon" />
      </div>
      <h6 className="text-center p-2">
        いただいた本人確認書類より年齢確認がとれました。引き続きTutuをお楽しみください。
      </h6>
    </div>
  )
}

export const VerifyLoading = () => {
  return (
    <div className="h-100 d-flex flex-column align-items-center p-2">
      <Image src={IdCardImage} width="150" className="m-4" />
      <h6 className="text-center p-2">
        ただいま、いただいた本人確認書類より年齢確認中です。もうしばらくお待ちください。
      </h6>
    </div>
  )
}
