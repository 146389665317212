import { takeLatest, put, call, select } from "redux-saga/effects"
import {
  getMatchListSuccess,
  getMatchListFailed,
  getChatListSuccess,
  getChatListFailed,
  getUnreadMatchCountSuccess,
  getUnreadMatchCountFailed,
  getUnreadMessageCountSuccess,
  getUnreadMessageCountFailed,
  clearUnreadMatchCountSuccess,
  clearUnreadMatchCountFailed,
  getMessageTemplateSuccess,
  getMessageTemplateFailed,
  getMessageTemplateMeSuccess,
  getMessageTemplateMeFailed,
  createAndUpdateMessageTemplateSuccess,
  createAndUpdateMessageTemplateFailed,
  deleteMessageTemplateSuccess,
  deleteMessageTemplateFailed,
  getHideListSuccess,
  getHideListFailed,
  setUnreadMessageNumber
} from "../actions/message"
import {
  GET_MATCH_LIST_REQUESTED,
  GET_CHAT_LIST_REQUESTED,
  GET_UNREAD_MATCH_COUNT_REQUESTED,
  GET_UNREAD_MESSAGE_COUNT_REQUESTED,
  CLEAR_UNREAD_MATCH_COUNT_REQUESTED,
  GET_MESSAGE_TEMPLATE_REQUESTED,
  GET_MESSAGE_TEMPLATE_ME_REQUESTED,
  CREATE_AND_UPDATE_MESSAGE_TEMPLATE,
  DELETE_MESSAGE_TEMPLATE,
  GET_HIDE_LIST_REQUESTED,
  GET_CHAT_LIST_SUCCESS
} from "../constants/actions"
import {
  getMatchList,
  getChatList,
  getUnreadMatchCount,
  getUnreadMessageCount,
  clearUnreadMatchCount,
  getHideList,
} from "../utils/firebase"
import {
  createAndUpdateMessageTemplate,
  deleteMessageTemplate,
  getMessageTemplate
} from "../services/messageTemplate"

function* updateUnreadMessageNumber() {
  const users = yield select((state) => state.message.chatList);
  const currentUserId = yield select((state) => state.auth.profile.user_id);

  let count = 0;
  users.forEach((user) => {
    if (conditionRead(user, currentUserId) === '#FFE3E0') {
      count++;
    }
  });

  yield put(setUnreadMessageNumber(count));
}

function conditionRead(user, currentUserId) {
  if (user.lastMessage?.userId === currentUserId) {
    return 'white';
  }
  if (user.isMatchingRead === 1 && user.lastMessage?.isRead === 1) {
    return 'white';
  }
  return '#FFE3E0';
}

export function* watchChatListSuccess() {
  yield takeLatest(GET_CHAT_LIST_SUCCESS, updateUnreadMessageNumber);
}

function* handleGetMatches(action) {
  try {
    yield getMatchList(action.payload.userId, (users) => {
      const dispatch = action.dispatch
      dispatch(getMatchListSuccess(users))
    })
  } catch (error) {
    yield put(getMatchListFailed(error.toString()))
  }
}

function* handleGetConnectedUsers(action) {
  try {
    yield getChatList(
      action.payload.userId,
      (users) => {
        action.dispatch(getChatListSuccess(users))
      },
      (error) => {
        action.dispatch(getChatListFailed(error.toString()))
      }
    )
  } catch (error) {
    yield put(getChatListFailed(error.toString()))
  }
}

function* handleGetHideUsers(action) {
  try {
    yield getHideList(
      action.payload.userId,
      (users) => {
        action.dispatch(getHideListSuccess(users))
      },
      (error) => {
        action.dispatch(getHideListFailed(error.toString()))
      }
    )
  } catch (error) {
    yield put(getHideListFailed(error.toString()))
  }
}

function* handleGetUnreadMatchCount(action) {
  try {
    yield getUnreadMatchCount(action.payload.userId, (count) => {
      action.dispatch(getUnreadMatchCountSuccess(count))
    })
  } catch (error) {
    yield put(getUnreadMatchCountFailed(error.toString()))
  }
}

function* handleGetUnreadMessageCount(action) {
  try {
    yield getUnreadMessageCount(action.payload.userId, (count) => {
      action.dispatch(getUnreadMessageCountSuccess(count))
    })
  } catch (error) {
    yield put(getUnreadMessageCountFailed(error.toString()))
  }
}

function* handleClearUnreadMatchCount(action) {
  try {
    yield clearUnreadMatchCount(action.payload.userId)
    yield put(clearUnreadMatchCountSuccess())
  } catch (error) {
    yield put(clearUnreadMatchCountFailed(error.toString()))
  }
}

function* handleGetMessageTemplate() {
  try {
    const response = yield getMessageTemplate(false)

    if (response.status === 200) {
      yield put(getMessageTemplateSuccess(response.data))
    } else {
      yield put(getMessageTemplateFailed(response.error))
    }
  } catch (error) {
    yield put(getMessageTemplateFailed(error))
  }
}

function* handleGetMessageTemplateMe(action) {
  const { onSuccess } = action.callbacks

  try {
    const response = yield getMessageTemplate(true)

    if (response.status === 200) {
      yield put(getMessageTemplateMeSuccess(response.data))
      onSuccess(response.data)
    } else {
      yield put(getMessageTemplateMeFailed(response.error))
    }
  } catch (error) {
    yield put(getMessageTemplateMeFailed(error))
  }
}

function* handleCreateAndUpdateMessageTemplate(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield createAndUpdateMessageTemplate(action.payload)

    if (response.status === 200) {
      yield put(createAndUpdateMessageTemplateSuccess(response.data))
      onSuccess(response)
    } else {
      yield put(createAndUpdateMessageTemplateFailed(response.error))
      onFailed(response)
    }
  } catch (error) {
    yield put(createAndUpdateMessageTemplateFailed(error))
    onFailed(error)
  }
}

function* handleDeleteTemplateMessage(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield deleteMessageTemplate(action.payload.messageId)

    if (response.status === 200) {
      yield put(deleteMessageTemplateSuccess(response.data))
      onSuccess(action.payload.messageId)
    } else {
      yield put(deleteMessageTemplateFailed(response.error))
      onFailed(response)
    }
  } catch (error) {
    yield put(deleteMessageTemplateFailed(error))
    onFailed(error)
  }
}

export default function* messageSaga() {
  yield takeLatest(GET_MATCH_LIST_REQUESTED, handleGetMatches)
  yield takeLatest(GET_CHAT_LIST_REQUESTED, handleGetConnectedUsers)
  yield takeLatest(GET_HIDE_LIST_REQUESTED, handleGetHideUsers)
  yield takeLatest(DELETE_MESSAGE_TEMPLATE, handleDeleteTemplateMessage)
  yield takeLatest(GET_MESSAGE_TEMPLATE_REQUESTED, handleGetMessageTemplate)
  yield takeLatest(GET_UNREAD_MATCH_COUNT_REQUESTED, handleGetUnreadMatchCount)
  yield takeLatest(
    GET_UNREAD_MESSAGE_COUNT_REQUESTED,
    handleGetUnreadMessageCount
  )
  yield takeLatest(
    CLEAR_UNREAD_MATCH_COUNT_REQUESTED,
    handleClearUnreadMatchCount
  )
  yield takeLatest(
    GET_MESSAGE_TEMPLATE_ME_REQUESTED,
    handleGetMessageTemplateMe
  )
  yield takeLatest(
    CREATE_AND_UPDATE_MESSAGE_TEMPLATE,
    handleCreateAndUpdateMessageTemplate
  )
}
