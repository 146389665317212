import _ from "lodash"
import { takeLatest, put, call } from "redux-saga/effects"
import {
  updateUserProfileFailed,
  updateUserProfileSuccess
} from "../actions/auth"

import {
  UPDATE_USER_PROFILE_REQUEST,
  UPSERT_AUTH_PROFILE
} from "../constants/actions"
import { updateUserProfile } from "../services/profile"

function* handleUpdateUserProfile(action) {
  const { data, token } = action?.payload || null
  const userId = action?.payload?.data?.userId
  const { onSuccess, onFailed } = action?.payload?.callbacks || {}
  try {
    const response = yield updateUserProfile(data, token, userId, action)
    if (response.status === 200) {
      yield put(
        updateUserProfileSuccess({ payload: data, response: response.data })
      )
      if (typeof onSuccess === "function") {
        onSuccess(response)
      }
    } else {
      yield put(updateUserProfileFailed())
      if (typeof onFailed === "function") {
        onFailed(response)
      }
    }
  } catch (error) {
    yield put(updateUserProfileFailed())
    if (typeof onFailed === "function") {
      yield call(onFailed, error)
    }
  }
}

function* handleUpsertAuthProfile(action) {
  const { onSuccess, onFailed } = action?.payload?.callbacks || {}
  try {
    if (typeof onSuccess === "function") {
      yield call(onSuccess)
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
  }
}

export default function* watchUpdateUserProfiles() {
  yield takeLatest(UPDATE_USER_PROFILE_REQUEST, handleUpdateUserProfile)
  yield takeLatest(UPSERT_AUTH_PROFILE, handleUpsertAuthProfile)
}
