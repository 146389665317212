import _ from "lodash"
import { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import Button from "react-bootstrap/Button"
import Carousel from "react-bootstrap/Carousel"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"

import "./styles.scss"
import heartGroup from "../../images/heart-group.png"
import circleGroup from "../../images/circle-group.png"
import routes from "../../constants/routes"

const Guides = () => {
  const history = useHistory()
  const params = useParams()
  const maxIndex = 3
  const guide = _.isEmpty(params.guide) ? 0 : parseInt(params.guide)
  const isIos = [
                    'iPad Simulator',
                    'iPhone Simulator',
                    'iPod Simulator',
                    'iPad',
                    'iPhone',
                    'iPod'
                  ].includes(navigator.platform)
                  // iPad on iOS 13 detection
                  || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

  const handleSwipe = () => {
    if (guide >= maxIndex) {
      history.push(routes.SIGNUP)
      return
    }

    history.push(`${routes.GUIDES}/${guide + 1}`)
  }

  const handleSelect = (selectedIndex, e) => {
    history.push(`${routes.GUIDES}/${selectedIndex}`)
    //setGuide(selectedIndex);
  }

  return (
    <Layout className="pt-15">
      <AppBar className="hidden" />
      <div className="container">
        <div className={`d-flex flex-column full-height-without-navbar overflow-visible ${isIos ? 'is-iphone' : ''}`}>
          <div className="d-flex justify-content-between">
            <h1
              className="text-start mb-3 h6"
              onClick={() => history.push("/")}
            >
              キャンセル
            </h1>
            <h1
              className="text-end mb-3 h6"
              onClick={() => history.push(routes.SIGNUP)}
            >
              スキップ
            </h1>
          </div>
          <Carousel
            className="text-center carousel-full-height carousel-guides"
            interval={null}
            wrap={false}
            activeIndex={guide}
            onSelect={handleSelect}
          >
            <Carousel.Item>
              <img src={heartGroup} alt="" />
              <h2 className="my-4">
                Tutuから
                <br />
                始まる理想の出会いに新しい形を
              </h2>
              <p>
                今までにないオンライン顔合わせでお互いの相性を
                チェック！しっかりお話して素敵な出会いをサポート！
              </p>
            </Carousel.Item>
            <Carousel.Item>
              <div className="white-box">
                <h2 className="title-bar">安心安全の取り組み</h2>
                <ul className="check-list list text-start">
                  <li>匿名のやり取りで信頼できる相手探しを実現</li>
                  <li>公的身分証での身元確認で厳しい審査基準</li>
                  <li>24時間監視体制構築で常に安心安定な環境作り</li>
                  <li>個人情報保護士による厳重な管理システム</li>
                  <li>当アプリは定額料金制によるいいね付与</li>
                </ul>
              </div>
              <h2 className="my-4">
                Tutuがどこよりも安心安全で素敵
                <br />
                な出会いができる理由
              </h2>
              <p>
                Tutuは上記体制を維持し全ユーザーに寄り添った環境作りに尽力し安心安全で素敵な出会いに確かな形で貢献して参ります！
              </p>
            </Carousel.Item>
            <Carousel.Item>
              <img className="w-50" src={circleGroup} alt="" />
              <h2 className="my-3">
                Tutuのここが凄い！
                <br />
                業界初、気に入ったお相手へ投げ銭による
                <br />
                サポートで応援しながらアピール！
              </h2>
              <p>
                メッセージや顔写真のみで出会いで失敗した等の経験を踏まえTutuは一歩踏み込んだやり取りを実現致します。お顔合わせの前にはまずは5分間でもおしゃべり！オンライン顔合わせでフィーリングが合えば出会いが現実に！
                <br />
                メッセージだけだとドタキャンの割合が高く、お相手の言葉違いや雰囲気などわからない為まずはオンラインをお勧めします！
                <br />
                男性の方はオンラインによる投げ銭での遠方の方にもサポート可能！女性の方はオンラインサポートをして頂けます！
              </p>
            </Carousel.Item>
            <Carousel.Item>
              <div className="white-box">
                <h2 className="title-bar">簡単４ステップ</h2>
                <ul className="text-small list text-start">
                  <li>
                    <span className="text-warning d-block">STEP1</span>
                    好みのお相手にいいねでアピールしてマッチングが成立したらメッセージでやり取りしましょう
                  </li>
                  <li>
                    <span className="text-warning d-block">STEP２</span>
                    メッセージでフィーリングが合えば業界初のオンラインチャットでお相手の雰囲気や喋り方など自身のお考えとの相違のチェック
                  </li>
                  <li>
                    <span className="text-warning d-block">STEP３</span>
                    気に入ったお相手には投げ銭でサポートし他の方との差をつけダメ押しアピールしちゃいましょう
                  </li>
                  <li>
                    <span className="text-warning d-block">STEP４</span>
                    ここまで来ればもう大丈夫！お互いの気持ちは盛り上がり、直接お会いして頂き素敵なお時間になる事は言うまでもないですね！
                  </li>
                </ul>
              </div>
              <h2 className="my-3">
                Tutuで素敵な出会いは
                <br />
                簡単な４ステップ
              </h2>
              <p>
                Tutuで業界初の新たな出会いの形で
                <br />
                素敵な出会いをお楽しみ下さい！
              </p>
            </Carousel.Item>
          </Carousel>
          <div className="mt-auto py-3">
            <div className="d-grid">
              <Button
                className="align-self-center"
                variant="danger"
                type="button"
                onClick={handleSwipe}
              >
                次へ
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Guides
