import _ from "lodash"
import classNames from "classnames"
import Modal from "react-bootstrap/Modal"
import htmlParser from "react-html-parser"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useState, useEffect } from "react"
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons"

import "./styles.scss"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import Ticker from "../../components/Elements/Ticker"
import useWindowSize from "../../hooks/use-window-size"
import defaultAvatar from "../../images/avatar-app.png"
import { useSelector } from "react-redux"

const Review = () => {
  let user = useSelector((state) => state.auth.profile)
  let userOption = JSON.parse(localStorage.getItem("profileEditOption"))
  const windowSize = useWindowSize()
  const [_heightAvatar, setHeightAvatar] = useState(0)
  const [_heightChildAvatar, setHeightChildAvatar] = useState(0)

  let imagesAvatar = {}
  let imagesGif = []
  let avatarActive = []

  // get image saved
  for (let i = 0; i < user?.images?.length; i++) {
    const item = user.images[i]

    if (item.display_order > 0 && item.display_order <= 9) {
      if (item.display_order === 1) {
        avatarActive = item
      }
      imagesAvatar[item.display_order - 1] = item
    }
  }
  // get image new upload
  if (user?.images_change) {
    Object.keys(user.images_change).map(function (key) {
      if (user.images_change[key].src) {
        imagesAvatar[key] = user.images_change[key].src
      } else {
        delete imagesAvatar[key]
      }
    })
  }
  avatarActive = imagesAvatar[0]

  // get gif new upload
  if (user?.gif_url_change) {
    imagesGif = []
    imagesGif[0] = user.gif_url_change[0].src
  }

  const [avatars, setAvatars] = useState(imagesAvatar)
  const [gifs, setGifs] = useState(imagesGif)

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const [message, setMessage] = useState(0)
  const handleSwitchModel = () => {
    if (message >= 2) {
      handleClose()
      setMessage(0)
    } else {
      setMessage(message + 1)
    }
  }

  const [showImg, setShowImg] = useState(avatarActive)
  const [browser, setBrowser] = useState("")

  const activateShow = (item) => {
    setShowImg(item)
  }

  let residence
  let birthplace
  let baby_aspiration
  let alcohol
  let occupation
  let annual_income
  let cigarette
  if (user && userOption) {
    residence = userOption.residences.find(
      (element) => element.residence_id == user.residence_id
    )
    birthplace = userOption.birthplaces.find(
      (element) => element.birthplace_id == user.birthplace_id
    )
    baby_aspiration = userOption.baby_aspirations.find(
      (element) => element.baby_aspiration_id == user.baby_aspiration_id
    )
    alcohol = userOption.alcohols.find(
      (element) => element.alcohol_id == user.alcohol_id
    )
    occupation = userOption.occupations.find(
      (element) => element.occupation_id == user.occupation_id
    )
    annual_income = userOption.annual_incomes.find(
      (element) => element.annual_income_id == user.annual_income_id
    )
    cigarette = userOption.cigarettes.find(
      (element) => element.cigarette_id == user.cigarette_id
    )
  }

  useEffect(() => {
    let obj = document.querySelector(".field-avatar.main-avatar")
    if (obj != null) {
      setHeightAvatar(obj.clientWidth)
    }
    obj = document.querySelector(".field-avatar.child-avatar")
    if (obj != null) {
      setHeightChildAvatar(obj.clientWidth)
    }
  }, [windowSize])

  useEffect(() => {
    const userAgent = navigator.userAgent

    if (userAgent.includes("Chrome")) {
      setBrowser("Chrome")
    } else if (userAgent.includes("Firefox")) {
      setBrowser("Firefox")
    } else if (userAgent.includes("Safari")) {
      setBrowser("Safari")
    }
  }, [])

  return (
    <Layout>
      <AppBar
        title="プレビュー"
        onGoBack={() => (window.location.href = "/profile")}
      />
      <div className="bg-white d-flex flex-column px-3 wrap-child-setting user-review preview">
        <div className="user-detail">
          <div
            className="field field-avatar rounded main-avatar mb-3"
            style={{ height: _heightAvatar }}
          >
            {user?.is_new == 0 && <Ticker />}
            {showImg ? (
              <>
                {showImg.type === 1 ? (
                  <img
                    className="rounded"
                    src={showImg?.image_url}
                    alt="Avatar"
                  />
                ) : (
                  <>
                    {browser === "Chrome" ? (
                      <video
                        playsInline
                        autoPlay
                        loop
                        muted
                        className="rounded video"
                        alt="Avatar"
                      >
                        <source src={showImg?.image_url} />
                      </video>
                    ) : (
                      <video
                        poster={showImg.image_url}
                        playsInline
                        autoPlay
                        loop
                        muted
                        className="rounded video"
                        alt="Avatar"
                      >
                        <source src={showImg?.image_url} />
                      </video>
                    )}
                  </>
                )}
              </>
            ) : (
              <img
                className="rounded"
                src={defaultAvatar}
                alt="Default avatar"
              />
            )}
          </div>
          <Container className="shadow-bottom">
            <div className="user-fields">
              {!_.isEmpty(avatars) && (
                <div className="swipe-scroll">
                  {_.map(avatars, (avatar) => (
                    <div
                      key={avatar?.id}
                      className={`swipe-item field field-avatar child-avatar rounded ${
                        avatar?.id === showImg?.id ? "active" : ""
                      }`}
                      style={{ height: _heightChildAvatar }}
                    >
                      {avatar?.type === 1 ? (
                        <img
                          onClick={() => activateShow(avatar)}
                          className="rounded"
                          src={avatar?.image_url}
                          alt="avatar"
                        />
                      ) : (
                        <>
                          {browser === "Chrome" ? (
                            <video
                              onClick={() => activateShow(avatar)}
                              className="rounded video"
                              src={avatar?.image_url}
                              alt="Avatar"
                            />
                          ) : (
                            <video
                              onClick={() => activateShow(avatar)}
                              poster={avatar?.image_url}
                              className="rounded video"
                              src={avatar?.image_url}
                              alt="Avatar"
                            />
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div className="fields field-group field-group-info field-label">
                <div className="field field-nickname cut-text">
                  {user?.nickname}
                </div>
                &nbsp;
                <div className="field field-residence">
                  &nbsp;{_.get(residence, "name", null)}
                </div>
                <div
                  className={classNames(
                    "field field-status",
                    "field-status-online"
                  )}
                >
                  オンライン
                </div>
              </div>
              <div className="field field-memo clearfix pr-0">
                <div className="field-label">ひとこと</div>
                <p style={{ whiteSpace: "break-spaces" }}>{user?.comment}</p>
              </div>
              {!_.isEmpty(gifs) && (
                <div className="field field-gift clearfix">
                  <div className="field-label">GIF画像</div>
                  <div className="swipe-scroll">
                    {_.map(gifs, (path, indx) => (
                      <div
                        key={indx}
                        className="swipe-item field field-avatar ratio ratio-1x1 rounded"
                      >
                        <img className="rounded" src={path} alt="" />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {user?.biography && (
                <div className="field field-bio clearfix pr-0">
                  <div className="field-label black-text">自己紹介</div>
                  <p
                    className="black-text"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {htmlParser(user.biography)}
                  </p>
                </div>
              )}
              <div className="field-group field-others clearfix">
                <div className="field-group-label">プロフィール</div>
                <div className="fields-wrapper">
                  <div className="field field-age clearfix">
                    <div className="field-label">年齢</div>
                    <div className="field-value">{user?.age}歳</div>
                  </div>

                  <div className="field field-residence clearfix">
                    <div className="field-label">居住地</div>
                    <div className="field-value">
                      {_.get(residence, "name", null)}
                    </div>
                  </div>

                  <div className="field field-residence clearfix">
                    <div className="field-label">希望エリア</div>
                    <div className="field-value">
                      {user?.match_area_1 !== null
                        ? JSON.parse(user?.match_area_1).map(
                            (item) => item.name + " / "
                          )
                        : ""}
                    </div>
                  </div>

                  <div className="field field-alcohol clearfix">
                    <div className="field-label">職業</div>
                    <div className="field-value">
                      {_.get(occupation, "name", null)}
                    </div>
                  </div>

                  <div className="field field-height clearfix">
                    <div className="field-label">身長</div>
                    <div className="field-value">
                      {user?.height}
                      {user?.height != null && user?.height > 0 ? "cm" : ""}
                    </div>
                  </div>

                  <div className="field field-figure clearfix">
                    <div className="field-label">体型</div>
                    <div className="field-value">{user?.body}</div>
                  </div>

                  <div className="field field-birthplace clearfix">
                    <div className="field-label">出身地</div>
                    <div className="field-value">
                      {_.get(birthplace, "name", null)}
                    </div>
                  </div>

                  {user.sex === 1 &&
                    <div className="field field-annual_income clearfix">
                      <div className="field-label">年収</div>
                      <div className="field-value">
                        {_.get(annual_income, "name", null)}
                      </div>
                    </div>
                  }

                  <div className="field field-baby_aspiration clearfix">
                    <div className="field-label">子供の有無</div>
                    <div className="field-value">
                      {_.get(baby_aspiration, "name", null)}
                    </div>
                  </div>

                  <div className="field field-baby_aspiration clearfix">
                    <div className="field-label">自分の性格</div>
                    <div className="field-value">
                      {user?.personality !== null
                        ? JSON.parse(user?.personality).map(
                            (item) => item.name + " / "
                          )
                        : ""}
                    </div>
                  </div>

                  <div className="field field-alcohol clearfix">
                    <div className="field-label">タバコ</div>
                    <div className="field-value">
                      {_.get(cigarette, "name", null)}
                    </div>
                  </div>

                  <div className="field field-alcohol clearfix">
                    <div className="field-label">飲酒</div>
                    <div className="field-value">
                      {_.get(alcohol, "name", null)}
                    </div>
                  </div>

                  <div className="field field-alcohol clearfix">
                    <div className="field-label">休日</div>
                    <div className="field-value">{user?.holiday}</div>
                  </div>
                </div>
              </div>
            </div>
          </Container>

          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
              {message === 0 && (
                <Fragment>
                  <h3>スクリーンショットを検知しました。</h3>
                  <p>
                    お相手の内容など、他者に関する情報はインター
                    ネットなどで公開しないようお願いします
                  </p>
                </Fragment>
              )}
              {message === 1 && (
                <Fragment>
                  <h3>いいね不足しています。</h3>
                  <p>
                    いいねが不足しています。ポイント購入は下記
                    よりご購入ください
                  </p>
                </Fragment>
              )}
              {message === 2 && (
                <div className="text-center">
                  <h3>いいねを押せる回数が増えました。</h3>
                  <p>※30日に1回の間隔で、100いいねが追加されます。</p>
                  <p className="mt-5">残いいね</p>
                  <p>
                    <FontAwesomeIcon
                      className="text-danger"
                      icon={faHeartSolid}
                      size="lg"
                    />{" "}
                    30
                  </p>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button
                className="text-danger border-0 py-0 disable-focus"
                variant="light"
                onClick={handleSwitchModel}
              >
                {message === 1 ? "ポイント購入はこちら" : "OK"}
              </Button>
            </Modal.Footer>
          </Modal>
          <Container className="fixed-bottom">
            <div className="d-grid position-relative py-3 bg-white">
              <Button
                className="align-self-center"
                variant="danger"
                type="button"
                onClick={() => {
                  window.location = "/profile/edit"
                }}
              >
                編集画面に戻る
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default Review
