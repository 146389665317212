import _ from "lodash"
import Form from "react-bootstrap/Form"
import { useDispatch } from "react-redux"
import "react-image-crop/src/ReactCrop.scss"
import { useRef, useState, useContext } from "react"
import imageCompression from "browser-image-compression"

import "./styles.scss"
import CropImages from "./Crop"
import Select from "../../Elements/Select"
import AppContext from "../../../Context"
import messages from "../../../constants/messages"
import { updateUserProfileRequest } from "../../../actions"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { LoadingIndicator } from "../../LoadingIndicator"

const Avatars = ({ user, Picker, PickerSmall, browser }) => {
  const files = useRef([])
  const history = useHistory()
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)

  const [readImg, setReadImg] = useState(0)
  const [activeIndex, setActiveIndex] = useState(null)

  const videoRef = useRef(null)

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1200,
    useWebWorker: true
  }

  let imagesAvatar = []
  for (let i = 0; i < user.images.length; i++) {
    const item = user.images[i]
    if (item.display_order > 0) {
      imagesAvatar[item.display_order - 1] = {
        id: item.id,
        src: item.image_url,
        change: false,
        type: item.type === 1 ? "image" : "video",
        status: item.status
      }
    }
  }

  const [avatars, setAvatars] = useState(imagesAvatar)

  const handleChooseOption = (name, value) => {
    switch (value) {
      case 0:
        handleSelectFile(activeIndex, value)
        break

      case 1:
        handleSelectFile(activeIndex, value)
        break
      case 2:
        setActiveIndex(null)
        break
      case 3:
        if (avatars[activeIndex]) {
          avatars[activeIndex].file = ""
          avatars[activeIndex].src = ""
          avatars[activeIndex].type = ""
          avatars[activeIndex].status = ""
        }
        localStorage.setItem("profileEdit", JSON.stringify(user))
        setActiveIndex(null)
        break
      default:
        setActiveIndex(null)
        break
    }
  }

  const handleSelectFile = (idx, device) => {
    if (device === 0) {
      files.current[idx].setAttribute("accept", "image/*")
      if (navigator.userAgent.indexOf("like Mac") != -1) {
        files.current[idx].setAttribute("accept", "image/*, video/*")
      };
      files.current[idx].setAttribute("capture", "camera")
    } else {
      files.current[idx].removeAttribute("capture")
    }
    files.current[idx].click()
  }

  const handleChange = async () => {
    const file = files.current[activeIndex]
      ? files.current[activeIndex].files[0]
      : null

    if (file) {
      if (file.type === "image/gif") {
        alert("GIF画像を選択できません。")
        return
      }

      const reader = new FileReader()

      if (file.size > 5000000) {
        alert(messages.imageTooLarge)
        return false
      }

      if (file.type.slice(0, 5) === "image") {
        const compressedFile = await imageCompression(file, options)
        reader.readAsDataURL(compressedFile)
        reader.onloadend = async (e) => {
          let img = await resizeImage(reader.result)

          if (avatars[activeIndex]) {
            setAvatars({
              ...avatars,
              [activeIndex]: {
                ...avatars[activeIndex],
                file: compressedFile,
                src: img,
                type: compressedFile.type.slice(0, 5),
                change: true,
                status: 2
              }
            })
          } else {
            setAvatars({
              ...avatars,
              [activeIndex]: {
                id: 0,
                file: compressedFile,
                src: img,
                type: compressedFile.type.slice(0, 5),
                change: true,
                status: 2
              }
            })
          }

          if (activeIndex === 0) {
            setContext(
              "overlay",
              <CropImages
                src={img}
                onCancel={cancelCrop}
                onComplete={completeCrop}
              />
            )
          } else {
            setActiveIndex(null)
          }

          setReadImg(readImg + 1)
        }
      } else if (file.type.slice(0, 5) === "video") {
        let vid = document.createElement("video")
        const fileURL = URL.createObjectURL(file)
        vid.src = fileURL
        vid.ondurationchange = function () {
          if (this.duration > 10) {
            alert(messages.imageTooLarge)
          } else {
            if (avatars[activeIndex]) {
              setAvatars({
                ...avatars,
                [activeIndex]: {
                  ...avatars[activeIndex],
                  file: file,
                  src: URL.createObjectURL(file),
                  type: file.type.slice(0, 5),
                  change: true,
                  status: 2
                }
              })
            } else {
              setAvatars({
                ...avatars,
                [activeIndex]: {
                  id: 0,
                  file: file,
                  src: URL.createObjectURL(file),
                  type: file.type.slice(0, 5),
                  change: true,
                  status: 2
                }
              })
            }
          }
        }

        setActiveIndex(null)
      }
    }
  }

  const cancelCrop = () => {
    setContext("overlay", null)
    setActiveIndex(null)
  }

  const dataUrlToFile = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  }

  const completeCrop = async (src) => {
    const currentFile = true ? await dataUrlToFile(src,'abc.txt') : files.current[activeIndex].files[0];
    const compressedFile = await imageCompression(
      currentFile,
      options
    )

    setAvatars({
      ...avatars,
      [activeIndex]: {
        ...avatars[activeIndex],
        file: compressedFile,
        src: src,
        change: true,
        type: "image",
        status: 2
      }
    })
    setContext("overlay", null)
    setActiveIndex(null)
  }

  const getOption = (index) => {
    if (avatars[index] && avatars[index]?.src !== "") {
      return [
        "写真・動画を登録",
        "ライブラリから選択",
        "キャンセル",
        "写真・動画を削除する"
      ]
    } else {
      return ["写真・動画を登録", "ライブラリから選択", "キャンセル"]
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setContext("overlay", <LoadingIndicator />)

    const form = new FormData()
    var idx = 0
    var idx2 = 0
    if (avatars) {
      Object.keys(avatars).map((key) => {
        if (
          !(
            !(avatars[key].id && avatars[key].id > 0) && avatars[key].src === ""
          )
        ) {
          if (
            avatars[key].id &&
            avatars[key].id > 0 &&
            avatars[key].src === ""
          ) {
            form.append(`deleteImageIds[${idx2}]`, avatars[key].id)
            idx2++
          } else {
            if (avatars[key].change === true) {
              if (avatars[key].id && avatars[key].id > 0) {
                form.append(`images[${idx}][id]`, avatars[key].id)
              }
              if (avatars[key].type === "image") {
                form.append(`images[${idx}][type]`, 1)
              } else {
                form.append(`images[${idx}][type]`, 5)
              }
              form.append(`images[${idx}][display_order]`, parseInt(key) + 1)
              form.append(`images[${idx}][image]`, avatars[key].file)
            }
            idx++
          }
        }
      })
    }

    dispatch(
      updateUserProfileRequest(form, {
        onSuccess: () => {
          setContext("overlay", null)
          alert("投稿しました。審査完了までお待ちください。")
          history.push("/profile")
        },
        onFailed: (error) => {
          alert(_.get(error, "data.error.message", messages.somethingWrong))
          setContext("overlay", null)
        }
      })
    )
  }

  function resizeImage(base64Str, maxWidth = 600, maxHeight = 600) {
    return new Promise((resolve) => {
      let img = new Image()
      img.src = base64Str
      img.onload = () => {
        let canvas = document.createElement("canvas")
        const MAX_WIDTH = maxWidth
        const MAX_HEIGHT = maxHeight
        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width
        canvas.height = height
        let ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL())
      }
    })
  }

  return (
    <Form
      onSubmit={handleSubmit}
      className="d-flex flex-column ox-hidden media-avatar-container"
    >
      <div className="media">
        <div className="media-content">
          <h4 className="media-title">メイン画像</h4>
        </div>

        <div className="media-container">
          <div className="media-picker" onClick={() => setActiveIndex(0)}>
            {avatars[0] && avatars[0]?.src !== "" ? (
              <img src={avatars[0].src} alt="Avatar" />
            ) : (
              <img src={Picker} alt="Piker" />
            )}
            {avatars[0]?.status === 0 && (
              <div className="media-picker-overlay">承認待ち</div>
            )}
            {avatars[0]?.status === 1 && (
              <div className="media-picker-overlay">却下</div>
            )}
            <Form.Control
              className="d-none"
              type="file"
              name="file"
              accept="image/*"
              capture
              ref={(el) => (files.current[0] = el)}
              onChange={() => handleChange(0)}
              isInvalid={false}
            />
          </div>
        </div>

        <div className="media-content">
          <h4 className="media-title">サブ画像</h4>
        </div>

        <div className="media-container">
          <div className="media-picker-list d-flex">
            <video ref={videoRef} style={{ display: "none" }} controls />
            <div
              className="media-picker-item"
              onClick={() => setActiveIndex(1)}
              style={
                avatars[1]?.type === "image"
                  ? {
                      backgroundImage: `url(${avatars[1].src})`,
                      backgroundSize: "cover",
                      borderRadius: 8
                    }
                  : {}
              }
            >
              {avatars[1]?.type !== "video" && (
                <img
                  src={PickerSmall}
                  alt="Piker small"
                  style={{
                    opacity: avatars[1] && avatars[1]?.src !== "" ? 0 : 1
                  }}
                />
              )}
              {avatars[1]?.src && avatars[1].type === "video" && (
                <>
                  {browser === "Chrome" ? (
                    <video
                      className="media-picker-video"
                      src={avatars[1]?.src}
                    />
                  ) : (
                    <video
                      poster={avatars[1]?.src}
                      className="media-picker-video"
                      src={avatars[1]?.src}
                    />
                  )}
                </>
              )}
              {avatars[1]?.status === 0 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  承認待ち
                </div>
              )}
              {avatars[1]?.status === 1 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  却下
                </div>
              )}

              <Form.Control
                className="d-none"
                type="file"
                name="file"
                id="file1"
                accept="image/*,video/*"
                capture
                ref={(el) => (files.current[1] = el)}
                onChange={() => handleChange(1)}
                isInvalid={false}
              />
            </div>

            <div
              className="media-picker-item"
              onClick={() => setActiveIndex(2)}
              style={
                avatars[2]?.type === "image"
                  ? {
                      backgroundImage: `url(${avatars[2].src})`,
                      backgroundSize: "cover",
                      borderRadius: 8
                    }
                  : {}
              }
            >
              {avatars[2]?.type !== "video" && (
                <img
                  src={PickerSmall}
                  alt="Piker small"
                  style={{
                    opacity: avatars[2] && avatars[2]?.src !== "" ? 0 : 1
                  }}
                />
              )}
              {avatars[2]?.src && avatars[2].type === "video" && (
                <>
                  {browser === "Chrome" ? (
                    <video
                      className="media-picker-video"
                      src={avatars[2]?.src}
                    />
                  ) : (
                    <video
                      poster={avatars[2]?.src}
                      className="media-picker-video"
                      src={avatars[2]?.src}
                    />
                  )}
                </>
              )}
              {avatars[2]?.status === 0 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  承認待ち
                </div>
              )}
              {avatars[2]?.status === 1 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  却下
                </div>
              )}
              <Form.Control
                className="d-none"
                type="file"
                name="file"
                id="file2"
                accept="image/*,video/*"
                capture
                ref={(el) => (files.current[2] = el)}
                onChange={() => handleChange(2)}
                isInvalid={false}
              />
            </div>

            <div
              className="media-picker-item"
              onClick={() => setActiveIndex(3)}
              style={
                avatars[3]?.type === "image"
                  ? {
                      backgroundImage: `url(${avatars[3].src})`,
                      backgroundSize: "cover",
                      borderRadius: 8
                    }
                  : {}
              }
            >
              {avatars[3]?.type !== "video" && (
                <img
                  src={PickerSmall}
                  alt="Piker small"
                  style={{
                    opacity: avatars[3] && avatars[3]?.src !== "" ? 0 : 1
                  }}
                />
              )}
              {avatars[3]?.src && avatars[3].type === "video" && (
                <>
                  {browser === "Chrome" ? (
                    <video
                      className="media-picker-video"
                      src={avatars[3]?.src}
                    />
                  ) : (
                    <video
                      poster={avatars[3]?.src}
                      className="media-picker-video"
                      src={avatars[3]?.src}
                    />
                  )}
                </>
              )}
              {avatars[3]?.status === 0 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  承認待ち
                </div>
              )}
              {avatars[3]?.status === 1 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  却下
                </div>
              )}
              <Form.Control
                className="d-none"
                type="file"
                name="file"
                id="file3"
                accept="image/*,video/*"
                capture
                ref={(el) => (files.current[3] = el)}
                onChange={() => handleChange(3)}
                isInvalid={false}
              />
            </div>

            <div
              className="media-picker-item"
              onClick={() => setActiveIndex(4)}
              style={
                avatars[4]?.type === "image"
                  ? {
                      backgroundImage: `url(${avatars[4].src})`,
                      backgroundSize: "cover",
                      borderRadius: 8
                    }
                  : {}
              }
            >
              {avatars[4]?.type !== "video" && (
                <img
                  src={PickerSmall}
                  alt="Piker small"
                  style={{
                    opacity: avatars[4] && avatars[4]?.src !== "" ? 0 : 1
                  }}
                />
              )}
              {avatars[4]?.src && avatars[4].type === "video" && (
                <>
                  {browser === "Chrome" ? (
                    <video
                      className="media-picker-video"
                      src={avatars[4]?.src}
                    />
                  ) : (
                    <video
                      poster={avatars[4]?.src}
                      className="media-picker-video"
                      src={avatars[4]?.src}
                    />
                  )}
                </>
              )}
              {avatars[4]?.status === 0 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  承認待ち
                </div>
              )}
              {avatars[4]?.status === 1 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  却下
                </div>
              )}
              <Form.Control
                className="d-none"
                type="file"
                name="file"
                id="file4"
                accept="image/*,video/*"
                capture
                ref={(el) => (files.current[4] = el)}
                onChange={() => handleChange(4)}
                isInvalid={false}
              />
            </div>

            <div
              className="media-picker-item"
              onClick={() => setActiveIndex(5)}
              style={
                avatars[5]?.type === "image"
                  ? {
                      backgroundImage: `url(${avatars[5].src})`,
                      backgroundSize: "cover",
                      borderRadius: 8
                    }
                  : {}
              }
            >
              {avatars[5]?.type !== "video" && (
                <img
                  src={PickerSmall}
                  alt="Piker small"
                  style={{
                    opacity: avatars[5] && avatars[5]?.src !== "" ? 0 : 1
                  }}
                />
              )}
              {avatars[5]?.src && avatars[5].type === "video" && (
                <>
                  {browser === "Chrome" ? (
                    <video
                      className="media-picker-video"
                      src={avatars[5]?.src}
                    />
                  ) : (
                    <video
                      poster={avatars[5]?.src}
                      className="media-picker-video"
                      src={avatars[5]?.src}
                    />
                  )}
                </>
              )}
              {avatars[5]?.status === 0 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  承認待ち
                </div>
              )}
              {avatars[5]?.status === 1 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  却下
                </div>
              )}
              <Form.Control
                className="d-none"
                type="file"
                name="file"
                id="file5"
                accept="image/*,video/*"
                capture
                ref={(el) => (files.current[5] = el)}
                onChange={() => handleChange(5)}
                isInvalid={false}
              />
            </div>
            <div
              className="media-picker-item"
              onClick={() => setActiveIndex(6)}
              style={
                avatars[6]?.type === "image"
                  ? {
                      backgroundImage: `url(${avatars[6].src})`,
                      backgroundSize: "cover",
                      borderRadius: 8
                    }
                  : {}
              }
            >
              {avatars[6]?.type !== "video" && (
                <img
                  src={PickerSmall}
                  alt="Piker small"
                  style={{
                    opacity: avatars[6] && avatars[6]?.src !== "" ? 0 : 1
                  }}
                />
              )}
              {avatars[6]?.src && avatars[6].type === "video" && (
                <>
                  {browser === "Chrome" ? (
                    <video
                      className="media-picker-video"
                      src={avatars[6]?.src}
                    />
                  ) : (
                    <video
                      poster={avatars[6]?.src}
                      className="media-picker-video"
                      src={avatars[6]?.src}
                    />
                  )}
                </>
              )}
              {avatars[6]?.status === 0 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  承認待ち
                </div>
              )}
              {avatars[6]?.status === 1 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  却下
                </div>
              )}
              <Form.Control
                className="d-none"
                type="file"
                name="file"
                id="file6"
                accept="image/*,video/*"
                capture
                ref={(el) => (files.current[6] = el)}
                onChange={() => handleChange(6)}
                isInvalid={false}
              />
            </div>
            <div
              className="media-picker-item"
              onClick={() => setActiveIndex(7)}
              style={
                avatars[7]?.type === "image"
                  ? {
                      backgroundImage: `url(${avatars[7].src})`,
                      backgroundSize: "cover",
                      borderRadius: 8
                    }
                  : {}
              }
            >
              {avatars[7]?.type !== "video" && (
                <img
                  src={PickerSmall}
                  alt="Piker small"
                  style={{
                    opacity: avatars[7] && avatars[7]?.src !== "" ? 0 : 1
                  }}
                />
              )}
              {avatars[7]?.src && avatars[7].type === "video" && (
                <>
                  {browser === "Chrome" ? (
                    <video
                      className="media-picker-video"
                      src={avatars[7]?.src}
                    />
                  ) : (
                    <video
                      poster={avatars[7]?.src}
                      className="media-picker-video"
                      src={avatars[7]?.src}
                    />
                  )}
                </>
              )}
              {avatars[7]?.status === 0 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  承認待ち
                </div>
              )}
              {avatars[7]?.status === 1 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  却下
                </div>
              )}
              <Form.Control
                className="d-none"
                type="file"
                name="file"
                id="file7"
                accept="image/*,video/*"
                capture
                ref={(el) => (files.current[7] = el)}
                onChange={() => handleChange(7)}
                isInvalid={false}
              />
            </div>
            <div
              className="media-picker-item"
              onClick={() => setActiveIndex(8)}
              style={
                avatars[8]?.type === "image"
                  ? {
                      backgroundImage: `url(${avatars[8].src})`,
                      backgroundSize: "cover",
                      borderRadius: 8
                    }
                  : {}
              }
            >
              {avatars[8]?.type !== "video" && (
                <img
                  src={PickerSmall}
                  alt="Piker small"
                  style={{
                    opacity: avatars[8] && avatars[8]?.src !== "" ? 0 : 1
                  }}
                />
              )}
              {avatars[8]?.src && avatars[8].type === "video" && (
                <>
                  {browser === "Chrome" ? (
                    <video
                      className="media-picker-video"
                      src={avatars[8]?.src}
                    />
                  ) : (
                    <video
                      poster={avatars[8]?.src}
                      className="media-picker-video"
                      src={avatars[8]?.src}
                    />
                  )}
                </>
              )}
              {avatars[8]?.status === 0 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  承認待ち
                </div>
              )}
              {avatars[8]?.status === 1 && (
                <div className="media-picker-overlay media-picker-overlay__item">
                  却下
                </div>
              )}
              <Form.Control
                className="d-none"
                type="file"
                name="file"
                id="file8"
                accept="image/*,video/*"
                capture
                ref={(el) => (files.current[8] = el)}
                onChange={() => handleChange(8)}
                isInvalid={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center position-fixed bottom-0 w-100 bg-white p-2">
        <button type="submit" className="btn btn-danger btn-avatar-save">
          アップデート
        </button>
      </div>

      {activeIndex !== null && (
        <Select
          onChange={handleChooseOption}
          className="select-image"
          options={getOption(activeIndex)}
        />
      )}
    </Form>
  )
}

export default Avatars
