import axios from "axios"
import store from "../store"
import { HTTP_CODE, BASE_URL } from "../constants/env"
import { getAccessToken, removeAccessToken, setAccessToken } from "./auth"

var isRefreshToken = false
var callApiStack = []

const callRefreshToken = async (token) => {
  if (!isRefreshToken) {
    isRefreshToken = true
    const res = await callApi("GET", {
      url: "/user/refresh-token",
      token
    })
    if (res && res.status === 200) {
      const refreshToken = res?.token || ""
      if (refreshToken !== "") {
        setAccessToken(refreshToken)

        // TODO fix refresh token late
        callApiStack.forEach((e) => {
          if (e) {
            store.dispatch(e)
          }
        })
        callApiStack = []
      }
      const timer = res?.expires_in * 60 * 1000 - 10 * 1000
      setTimeout(() => {
        isRefreshToken = false
      }, timer)
    } else if (res && res.status == 500) {
      isRefreshToken = false

      setAccessToken("")
    } else if (res && res.status == 401) {
      isRefreshToken = false
      // removeAccessToken()
      // window.location.reload()
    }
  }
}

export default async function callApi(
  method,
  options = {
    url: null,
    data: null,
    token: null,
    params: null,
    useLocalToken: true,
    callback: null,
    raiseError: true,
    useDefaultUrl: true
  },
  action,
  contentType,
  functionName = "",
  shouldLog,
  accToken
) {
  try {
    const data = options.data
    const params = options.params
    const useDefaultUrl =
      options.useDefaultUrl != null ? options.useDefaultUrl : true
    const url = useDefaultUrl ? `${BASE_URL}${options.url}` : options.url
    const useLocalToken = getAccessToken()
    const currentToken = useLocalToken ? useLocalToken : accToken
    const contentTypeHeader = contentType || "application/json"
    const config = {
      headers: {
        Authorization: `Bearer ${currentToken ? currentToken : options?.token}`,
        "Content-Type": contentTypeHeader
      },
      credentials: "omit",
      withCredentials: false,
      params,
      timeout: 30000
    }
    let response = false
    switch (method.toUpperCase()) {
      case "GET": {
        response = await axios.get(url, config)
        break
      }
      case "PUT": {
        response = await axios.put(url, data, config)
        break
      }
      case "POST": {
        response = await axios.post(url, data, config)
        break
      }
      case "DELETE": {
        if (data) {
          response = await axios.delete(url, {
            ...config,
            data
          })
        } else {
          response = await axios.delete(url, config)
        }
        break
      }
      default:
        break
    }
    return { ...response?.data, status: response.status }
  } catch (apiError) {
    let response = apiError?.response
    const errorStatus = response?.status
    const useLocalToken = getAccessToken()

    if (response.status === 401) {
      // localStorage.clear()
    }

    if (
      errorStatus === HTTP_CODE.EXPIRED_TOKEN_ERR &&
      localStorage.getItem("registing") != "1"
    ) {
      callApiStack.push(action)
      // callRefreshToken(options?.token || null)
      if (useLocalToken) {
        removeAccessToken()
        window.location.reload()
      }
      return
    }
    return response
  }
}
