import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TabPanel, useTabs } from "react-headless-tabs"
import {
  getUnreadMatchCountRequested,
  getUnreadMessageCountRequested,
} from "../../actions/message"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import { MatchedList } from "./components/listMessage/List"
import { useHistory, useLocation } from "react-router-dom"

import "./styles.scss"

const Chats = () => {
  const dispatch = useDispatch()
  const [bannerHeight, setBannerHeight] = useState(0)

  const currentUserId = useSelector((state) => state.auth.profile.user_id)
  const unreadMatchCount = useSelector(
    (state) => state.message.unreadMatchCount
  )
  const unreadMessageCount = useSelector(
    (state) => state.message.unreadMessageCount
  )
  const activeTab = useSelector((state) => state.message.activeTab)

  const [selectedTab, setSelectedTab] = useTabs(["matching", "matched"])

  const history = useHistory()
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  let selectTab = query.get("tab")

  const handleSelectTab2 = () => {
    setSelectedTab("matched")
    history.push({
      pathname: history.location.pathname,
      search: "tab=2"
    })
  }

  const handleSelectTab1 = () => {
    setSelectedTab("matching")
    history.push({
      pathname: history.location.pathname,
      search: "tab=1"
    })
  }

  useEffect(() => {
    dispatch(getUnreadMatchCountRequested(currentUserId, dispatch))
    dispatch(getUnreadMessageCountRequested(currentUserId, dispatch))
    if (activeTab === 1) setSelectedTab("matching")
    else setSelectedTab("matched")

    if (selectTab == 2) {
      setSelectedTab("matched")
    }
  }, [])

  return (
    <Layout nav>
      <AppBar title="トーク" search placeholder=" " banner setBannerHeight={setBannerHeight} />
      <div className="bg-white d-flex flex-column full-height-with-navbar wrap-message-list" style={{ top: bannerHeight + 90 }}>
        <div className="h-100">
          <div className="h-100">
            <TabPanel className="h-100">
              <MatchedList valueSearch={history.location.state?.values}/>
            </TabPanel>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Chats
