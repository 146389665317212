import { useContext } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  Layout,
  AppBar,
  PhoneNumberInput,
  LoadingIndicator
} from "../../../components"
import AppContext from "../../../Context"
import {
  checkPhoneNumberRequest,
  checkPhoneNumberSuccess,
  upsertAuthProfile
} from "../../../actions"
import { NATIONAL_CODE } from "../../../constants/env"
import messages from "../../../constants/messages"
import routes from "../../../constants/routes"

const Phone = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { setContext } = useContext(AppContext)

  const handleSignup = (formData) => {
    setContext("overlay", <LoadingIndicator />)
    const data = {
      phone: NATIONAL_CODE + formData.phone?.substring(1)
    }

    dispatch(
      checkPhoneNumberRequest(data, {
        onSuccess: (status) => {
          if (status.isExisted === true) {
            alert(messages.phoneRegistered)
            setContext("overlay", null)
            alert(messages.duplicatePhoneNumber)
          } else {
            dispatch(checkPhoneNumberSuccess(data))
            dispatch(
              upsertAuthProfile({ phone: data.phone, phoneVerified: false })
            )

            setTimeout(() => {
              setContext("overlay", null)
              history.push(routes.TAKEOVER_VERIFY_PHONE)
            }, 400)
          }
        },
        onFailed: (status) => {
          setContext("overlay", null)
          alert(messages.somethingWrong)
        }
      })
    )
  }
  return (
    <Layout>
      <AppBar withBack />
      <div className="container">
        <PhoneNumberInput
          title="アカウントの引き継ぎ"
          onSignup={handleSignup}
        />
      </div>
    </Layout>
  )
}

export default Phone
