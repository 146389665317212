import { Image } from "react-bootstrap"
import BankIcon from "../../../../icons/bank.svg"
import { useHistory } from "react-router-dom"

export const Empty = () => {
	const history = useHistory()
	return (
		<div className="h-100 d-flex flex-column align-items-center">
			<Image
				src={BankIcon}
				width="100"
				className="mt-5 mb-4"
			/>
			<h6 className="text-center w-75 pt-1 pb-1">
        ポイントを指定して、そのポイントに応じた金額を出金することができます。
      </h6>

      <button
        className="btn btn-danger p-3 m-3"
        style={{ width: "90%" }}
        onClick={() => (history.push('/bank-register'))}
      >
        銀行口座を登録する
      </button>
		</div>
	)
}