import _ from "lodash"
import Modal from "react-bootstrap/Modal"
import { useSelector } from "react-redux"
import { useState, useContext } from "react"
import { useHistory } from "react-router-dom"

import "./styles.scss"
import AppContext from "../../../Context"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { removeAccessToken } from "../../../utils/auth"
import { detachAllListeners } from "../../../utils/firebase"
import { LoadingIndicator } from "../../../components/LoadingIndicator"

const Settings = () => {
  const history = useHistory()

  const { setContext } = useContext(AppContext)
  const profileUserData = useSelector((state) => state.auth.profile)

  const [show, setShow] = useState(false)

  const handleShow = () => {
    setContext("overlay", <LoadingIndicator />)
    setShow(true)
  }

  const handleClose = () => {
    setContext("overlay", null)
    setShow(false)
  }

  const handleLogout = () => {
    removeAccessToken()
    detachAllListeners()
    window.location.reload()
  }

  return (
    <Layout>
      <AppBar title="設定" withBack noBorderBottom />
      <div className="bg-white d-flex flex-column mb-5 pb-5 setting-body">
        {!_.isEmpty(profileUserData) ? (
          <>
            <div
              className="text-left button-with-arrow"
              onClick={() => history.push("/noti-setting")}
            >
              通知設定
            </div>
            <div
              className="text-left button-with-arrow"
              onClick={() => history.push("/contact")}
            >
              お問い合わせ
            </div>
            <div
              className="text-left button-with-arrow"
              onClick={() => history.push("/qa")}
            >
              よくある質問・ヘルプ
            </div>
            <div
              className="text-left button-with-arrow"
              onClick={() => history.push("/skip")}
            >
              非表示リスト
            </div>
            <div
              className="text-left button-with-arrow"
              onClick={() => history.push("/hide")}
            >
              ブロックリスト
            </div>
            <div
              className="text-left button-with-arrow"
              onClick={() => history.push("/mail-setting")}
            >
              メールアドレス登録と変更
            </div>
            <div
              className="text-left button-with-arrow"
              onClick={() => history.push("/verify")}
            >
              本人確認
            </div>
            <div
              className="text-left button-with-arrow"
              onClick={() => history.push("/close")}
            >
              退会
            </div>
            <div
              className="text-left button-with-arrow"
              onClick={() => history.push("/about")}
            >
              会社概要
            </div>
            <div
              className="text-left button-with-arrow"
              onClick={() => history.push("/terms")}
            >
              利用規約
            </div>
            <div
              className="text-left button-with-arrow"
              onClick={() => history.push("/privacy")}
            >
              プライバシーポリシー
            </div>
            <div
              className="text-left button-with-arrow"
              onClick={() => history.push("/law")}
            >
              特定商取引法に基づく表示
            </div>
            {/* <div className="text-left button-with-arrow">
              資金決済法に基づく表示
            </div> */}
            <div className="text-left button-with-arrow" onClick={handleShow}>
              ログアウト
            </div>

            <Modal
              show={show}
              onHide={handleClose}
              centered
              dialogClassName="w-75 mx-auto"
            >
              <Modal.Body>
                <h6 className="text-center pt-3 pb-3">
                  ログアウトします。よろしいでしょうか？
                </h6>

                <button
                  className="btn text-danger w-100 m-0 rounded-0 confirm-button"
                  onClick={handleLogout}
                >
                  OK
                </button>
                <button
                  className="btn w-100 m-0 rounded-0 confirm-button"
                  onClick={handleClose}
                >
                  キャンセル
                </button>
              </Modal.Body>
            </Modal>
          </>
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </Layout>
  )
}

export default Settings
