import {
	FETCH_POINT_PACKAGE_REQUEST,
	FETCH_POINT_PACKAGE_SUCCESS,
	FETCH_POINT_PACKAGE_FAILED,
  PURCHASE_POINT_REQUEST,
  PURCHASE_POINT_SUCCESS,
  PURCHASE_POINT_FAILED
} from "../constants/actions/buy-point"
      
  const initialState = {
    pointPackages: [],
    error: {}
  }
      
  const planReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_POINT_PACKAGE_FAILED:
        return {
          ...state,
          error: action.data
        }
  
      case FETCH_POINT_PACKAGE_SUCCESS:
        return {
          ...state,
          pointPackages: action.data.data
        }
  
      default:
        return state
    }
  }
  
  export default planReducer
    