import React, { useEffect } from 'react';
import './style.css';
import LogoTop from '../../images/img/logo-top.png';
import TopImg from '../../images/img/top-img.png';
import TopCatch from '../../images/img/top-catch.png';
import Step1 from '../../images/img/step01.png';
import Step2 from '../../images/img/step02.png';
import Step3 from '../../images/img/step03.png';
import Service1 from '../../images/img/service01.png';
import Service2 from '../../images/img/service02.png';
import Service3 from '../../images/img/service03.png';
import Service4 from '../../images/img/service04.png';
import Prohibited from '../../images/img/prohibited.png';
import { Image } from "react-bootstrap"

const TitlePage = ({ scrollPosition, scrollDemoRef }) => {
  useEffect(() => {
  }, [scrollPosition]);

  return (
    <div id="container" ref={scrollDemoRef}>
      <header>
        <div className="logo">
          <h1><Image src={LogoTop} alt="ブラインドマッチTUTU" /></h1>
        </div>
        <Image src={TopImg} className="top-img" alt="イメージ" />
        <Image src={TopCatch} className="top-catch" alt="30代からの大人の特別な関係" />
      </header>

      <section id="step">
        <h2>デートまでの３ステップ</h2>
        {renderSteps()}
      </section>

      <section id="service">
        <h2>サービスの特徴</h2>
        {renderServices()}
      </section>

      <section id="safety">
        <h2>安全への取り組み</h2>
        {renderSafety()}
      </section>

      <section id="price">
        <h2>料金のご案内</h2>
        {renderPrice()}
      </section>

      <section id="payment">
        <h2>お支払い方法</h2>
        <ul>
          <li>クレジットカード決済</li>
        </ul>
        <p>決済種別は随時追加予定となります。</p>
      </section>
      <div className="bottom"></div>
    </div>
  );
};

const renderSteps = () => (
  <>
    <ul className="box">
      <li className="img-title"><Image src={Step1} alt="STEP1" /></li>
      <li className="txt">
        <h3>① 「気になる」を送る</h3>
        <p>便利な検索機能や、「今からどう？」からお相手を見つけ、「気になる」を送りましょう。お相手からも「気になる」が届けばマッチング！メッセージが出来るようになります。</p>
      </li>
    </ul>
    <ul className="box">
      <li className="img-title"><Image src={Step2} alt="STEP2" /></li>
      <li className="txt">
        <h3>② メッセージを送る<br />通話する</h3>
        <p>マッチングしたらメッセージでお互いのことを知り、関係を深めましょう。TUTU独自の安心安全な通話機能で直接お話するのがおすすめです。</p>
      </li>
    </ul>
    <ul className="box">
      <li className="img-title"><Image src={Step3} alt="STEP3" /></li>
      <li className="txt">
        <h3>③ 素敵なデート、特別な関係を…</h3>
        <p>気に入ったお相手と特別な関係を築き、素敵なひと時をお過ごしください。</p>
      </li>
    </ul>
  </>
);

const renderServices = () => (
  <>
    <ul className="box fadein">
      <li className="txt">
        <h3><span>01</span>条件検索</h3>
        <p>最近入会など、細かい設定情報から検索出来ます。</p>
      </li>
      <li className="img-step"><Image src={Service1} alt="条件検索" /></li>
    </ul>
    <ul className="box fadein">
      <li className="img-step"><Image src={Service2} alt="リアルタイム募集" /></li>
      <li className="txt">
        <h3><span>02</span>リアルタイム募集<br />いまからどう？</h3>
        <p>今日会いたい、事前に空いている日で予定を合わせたいなど…好きな条件で募集、メッセージが出来ます。</p>
      </li>
    </ul>
    <ul className="box fadein">
      <li className="txt">
        <h3><span>03</span>オンライン通話<br />顔合わせ</h3>
        <p>ブラインドマッチングとはいえ、お相手のことをもっと知りたい。実際に会う前に雰囲気を知りたいなどのご要望にお答えします。</p>
      </li>
      <li className="img-step"><Image src={Service3} alt="オンライン通話" /></li>
    </ul>
    <ul className="box fadein">
      <li className="img-step"><Image src={Service4} alt="スーパー気になる" /></li>
      <li className="txt">
        <h3><span>04</span>スーパー気になる</h3>
        <p>お相手に特別な気持ちを込めてアピール出来ます。</p>
      </li>
    </ul>
  </>
);

const renderSafety = () => (
  <>
    <p>特に以下の行為は厳密に取り締まりを行います。</p>
    <ul className="prohibited">
      <li>
        <Image src={Prohibited} alt="禁止" />
        詐欺行為
      </li>
      <li>
        <Image src={Prohibited} alt="禁止" />
        あっせん行為<br />（業者）
      </li>
      <li>
        <Image src={Prohibited} alt="禁止" />
        サクラ
      </li>
    </ul>
    <p>また、お相手とコミュニケーションを取る前に年齢確認が必須です。<br />
      公的証明書の確認を1人1人行い、未成年の利用を防ぎます。</p>
    <h3>対応例</h3>
    <ul>
      <li className="list-disc">サービスの利用停止</li>
      <li className="list-disc">警察等機関への情報提供</li>
      <p>※正式に情報提供を求められた場合に、法令に基づき開示します。</p>
    </ul>
  </>
);

const renderPrice = () => (
  <>
    <h3 className="woman">女性会員様</h3>
    <p>女性会員様は完全無料でご利用いただけます。</p>

    <h3 className="man">男性会員様</h3>
    <p>男性会員様は多数のグレードをご用意しております。</p>

    <h4 className="h4">無料会員様</h4>
    <ul className="ul">
      <li className="list-disc">女性プロフィールの閲覧</li>
      <li className="list-disc">「気になる」の送信</li>
      <li className="list-disc">女性とのメッセージ、通話はできません。</li>
    </ul>

    <h4 className="h4">プラチナ,ダイヤモンド,モノリス会員様</h4>
    <ul className="ul">
      <li className="list-disc">すべての機能がご利用いただけます</li>
    </ul>
    <p>また、ランクごとに女性から見える表示が豪華になります。<br />
      <span>※モノリス会員様は10名様までとなります。</span></p>

    {renderPlans()}
  </>
);

const renderPlans = () => (
  <>
    <div className="box fadein" id="normal">
      <h4>通常プラン</h4>
      <table>
				<tr>
					<th>1ヶ月<br />プラン</th>
					<td>
						<p>¥5,000<span>/月</span></p>
						<p>（お支払い総額 ¥5,000）</p>
					</td>
				</tr>
			</table>
			<table>
				<tr>
					<th>3ヶ月<br />プラン</th>
					<td>
						<p>¥4,000<span>/月</span></p>
						<p>（お支払い総額 ¥12,000）</p>
					</td>
				</tr>
			</table>
			<table>
				<tr>
					<th>12ヶ月<br />プラン</th>
					<td>
						<p>¥3,233<span>/月</span></p>
						<p>（お支払い総額 ¥38,800）</p>
					</td>
				</tr>
			</table>
    </div>

    <div class="box fadein" id="platina">
			<h4>プラチナ会員</h4>
			<table>
				<tr>
					<th>1ヶ月<br />プラン</th>
					<td>
						<p>¥14,800<span>/月</span></p>
						<p>（お支払い総額 ¥14,800）</p>
					</td>
				</tr>
			</table>
			<table>
				<tr>
					<th>3ヶ月<br />プラン</th>
					<td>
						<p>¥11,600<span>/月</span></p>
						<p>（お支払い総額 ¥34,800）</p>
					</td>
				</tr>
			</table>
			<table>
				<tr>
					<th>12ヶ月<br />プラン</th>
					<td>
						<p>¥9,900<span>/月</span></p>
						<p>（お支払い総額 ¥118,800）</p>
					</td>
				</tr>
			</table>
		</div>
		<div class="box fadein" id="diamond">
			<h4>ダイヤモンド会員</h4>
			<table>
				<tr>
					<th>1ヶ月<br />プラン</th>
					<td>
						<p>¥20,000<span>/月</span></p>
						<p>（お支払い総額 ¥20,000）</p>
					</td>
				</tr>
			</table>
			<table>
				<tr>
					<th>3ヶ月<br />プラン</th>
					<td>
						<p>¥16,000<span>/月</span></p>
						<p>（お支払い総額 ¥48,000）</p>
					</td>
				</tr>
			</table>
			<table>
				<tr>
					<th>12ヶ月<br />プラン</th>
					<td>
						<p>¥13,200<span>/月</span></p>
						<p>（お支払い総額 ¥158,400）</p>
					</td>
				</tr>
			</table>
		</div>
		<div class="box fadein" id="monolith">
			<h4>モノリス会員</h4>
			<table>
				<tr>
					<th>1ヶ月<br />プラン</th>
					<td>
						<p>¥100,000<span>/月</span></p>
						<p>（お支払い総額 ¥100,000）</p>
					</td>
				</tr>
			</table>
			<table>
				<tr>
					<th>3ヶ月<br />プラン</th>
					<td>
						<p>¥90,000<span>/月</span></p>
						<p>（お支払い総額 ¥270,000）</p>
					</td>
				</tr>
			</table>
			<table>
				<tr>
					<th>12ヶ月<br />プラン</th>
					<td>
						<p>¥83,333<span>/月</span></p>
						<p>（お支払い総額 ¥1,000,000）</p>
					</td>
				</tr>
			</table>
		</div>
  </>
);

export default TitlePage;