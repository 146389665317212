import AppBar from "../../../../components/AppBar"
import Layout from "../../../../components/Layout"
import TutorialBanner from "../../../../images/tutorial_banner.png"
import TutorialStepOne from "../../../../images/tutorial_step_one.png"
import TutorialStepTwo from "../../../../images/tutorial_step_two.png"
import TutorialStepThree from "../../../../images/tutorial_step_three.png"
import TutorialStepFour from "../../../../images/tutorial_step_four.png"
import TutorialStepFive from "../../../../images/tutorial_step_five.png"
import TextStepOne from "../../../../images/text_step_one.png"
import EyeIcon from "../../../../images/eye.png"
import HandIcon from "../../../../images/hand.png"
import HeartIcon from "../../../../images/tutorial_heath.png"
import { Image } from "react-bootstrap"
import "./styles.scss"
import { useHistory } from "react-router-dom"

const Manual = () => {
  const history = useHistory()

  return (
		<Layout>
			<AppBar title="サービスの使い方" withBack />
			<div
        className="bg-white d-flex flex-column full-height-with-navbar text-center"
      >
				<Image
          src={TutorialBanner}
        />
        <br/>
				<h5>
					基本的な使い方
				</h5>
        <h2
          className="text-warning"
        >
          簡単５ステップ
        </h2>
        <br/>
        <div
          className="step-label"
        >
          ステップ１
        </div>
        <div className="triangle-down">
        </div>
        <br/>
        <h4>
          自分のプロフィールを<br/>
          設定しましょう！
        </h4>

        <p className="p-2">
          プロフィール編集から、魅力的なプロフィール写真や自己紹介文、趣味などを設定しましょう。既に設定している情報も何度でも変更可能です。
        </p>

        <Image
          src={TutorialStepOne}
          width={230}
          className="mx-auto"
        />
        <Image
          src={TextStepOne}
          width={300}
          className="mx-auto text-step-one"
        />
        <br/>
        <button
          className="btn btn-danger p-3 m-2"
          onClick={() => history.push(`/profile`)}
        >
          プロフィール編集へ
        </button>
        <br/>

        <div className="step-label">
          ステップ２
        </div>
        <div className="triangle-down">
        </div>
        <br/>
        <h4>
          気になるお相手を<br/>
          探してみましょう！
        </h4>
        <p className="p-2">
          ユーザー一覧より気になるお相手を探しましょう。検索条件を細かく設定でき、より好みのお相手を検索できます。
        </p>
        <Image
          src={TutorialStepTwo}
          className="background-orange m-3"
        />
        <button
          className="btn btn-danger p-3 m-2"
          onClick={() => history.push(`/users`)}
        >
          ユーザー一覧へ
        </button>
        <br/>
        <div className="step-label">
          ステップ３
        </div>
        <div className="triangle-down">
        </div>
        <br/>
        <h4>
          気になるお相手に<br/>
          いいね！を送ろう！
        </h4>

        <p className="p-2">
          気になるお相手を見つけたら、「いいね！」を押して、お相手に好意を伝えてみましょう。
        </p>
				
        <Image
          src={TutorialStepThree}
          className="background-orange m-3"
        />
        <button
          className="btn btn-danger p-3 m-2"
          onClick={() => history.push(`/users`)}
        >
          ユーザー一覧へ
        </button>
        <br/>

        <div className="step-label">
          ステップ４
        </div>
        <div className="triangle-down">
        </div>
        <br/>
        <h4>
          もらった「いいね」に返事をしよう！相手からもいいねが届くと<br/>
          マッチング成立！
        </h4>

        <p className="p-2">
          「いいね！」が届くと、お相手のいいねページに「いいね！」を送ってくれたお相手が表示されます。
        </p>
				
        <Image
          src={TutorialStepFour}
          className="background-orange m-3"
        />

        <button
          className="btn btn-danger p-3 m-2"
          onClick={() => history.push(`/likes`)}  
        >
          お相手からのいいねへ
        </button>
        <br/>

        <div className="step-label">
          ステップ５
        </div>
        <div className="triangle-down">
        </div>
        <br/>
        <h4>
          メッセージで仲を深めましょう！
        </h4>

        <p>
          マッチングが成立したお相手に、メッセージを送ってみましょう。
        </p>
				
        <Image
          src={TutorialStepFive}
          className="background-orange m-3"
        />

        <p className="p-3 text-small">
          ※ メッセージの送信には年齢確認が必須となっています。<br/>
          ※ 男性の方は、有料プランへの加入が必要です。
        </p>

        <button
          className="btn btn-danger p-3 m-2"
          onClick={() => history.push(`/chats`)}
        >
          メッセージ一覧へ
        </button>
        <br/>

        <div className="background-grey pt-5 pb-5">
          <h3 className="text-warning">
            安心安全の取り組み
          </h3>
          <br/>
          <div className="icon-background d-flex align-items-center">
            <Image 
              src={EyeIcon}
              width={80}
              height={50}
              className="mx-auto"
            />
          </div>
          <br/>
          <h5>
            24時間365日の常時監視体制
          </h5>
          <p className="p-3">
            24時間365日、オペレーターによる厳正な監視体制で、会員の方の皆様に安心・安全なサービスの提供をお届けいたします。
          </p>
          <br/>

          <div className="icon-background d-flex align-items-center">
            <Image 
              src={HandIcon}
              width={70}
              height={80}
              className="ms-5"
            />
          </div>
          <br/>
          <h5>
            徹底した不正ユーザーの排除
          </h5>
          <p className="p-3">
            Tutuでの出会い目的以外で、他のサービスに誘導したり勧誘を行う悪質なユーザーは、見つけ次第、警告表示や強制退会などの対応をいたします。
          </p>
          <br/>

          <div className="icon-background d-flex align-items-center">
            <Image 
              src={HeartIcon}
              width={70}
              height={60}
              className="mx-auto"
            />
          </div>
          <br/>
          <h5>
            安心安全のための充実した機能
          </h5>
          <p className="p-3">
            ブロックや通報機能など、本人確認などを通して、Tutuをより安心安全にご利用いただけるよう取り組んでいます。
          </p>

        </div>
			</div>
		</Layout>
	)
}

export default Manual
