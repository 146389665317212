import * as actionTypes from "../constants/actions"

const initialState = {}

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default errorReducer
