import _ from "lodash"
import classNames from "classnames"
import { useContext, useEffect, Fragment } from "react"
import Container from "react-bootstrap/Container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import AppContext from "../../../Context"

import "./styles.scss"

const Select = ({
  className: classNameses,
  name,
  options,
  value: selected,
  onChange,
}) => {
  const { setContext } = useContext(AppContext)

  useEffect(() => {
    setContext(
      "overlay",
      <div
        className={classNames(
          "element element-select d-flex justify-content-center h-100",
          classNameses
        )}
      >
        <div className="select-options align-self-end w-100 text-center bg-white py-2">
          <Container>
            {_.map(options, (label, value) => (
              <div
                key={`${value}-${label}`}
                className={classNames(`option py-2 item-${label}`, {
                  "option-selected": _.isEqual(selected, value),
                })}
                onClick={() => onChange(name, value)}
              >
                {label}
                <span className="checked text-warning">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </div>
            ))}
          </Container>
        </div>
      </div>
    )

    return () => {
      setContext("overlay", null)
    }
  }, [])

  return <Fragment />
}

export default Select
