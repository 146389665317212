import { Fragment, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { getAccessToken, removeAccessToken } from "./utils/auth"
import {
  fetchAuthProfileRequest,
  addDeviceTokenRequested
} from "./actions"

const Auth = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const deviceToken = useSelector((state) => state.auth.deviceToken)
  const staticPagePath = ["/services", "/law", "/privacy"]

  useEffect(() => {
    const accessToken = getAccessToken()
    if (accessToken) {
      dispatch(
        fetchAuthProfileRequest(
          {},
          {
            onSuccess: (response) => {},
            onFailed: (response) => {
              if (response.status === 401) {
                // removeAccessToken()
                // window.location.reload()
                // history.push("/title")
              }
            }
          }
        )
      )
      if (!deviceToken) {
        dispatch(addDeviceTokenRequested())
      }
    } else {
      if (staticPagePath.indexOf(history.location.pathname) === -1) {
        history.push("/title")
      }
    }
  }, [])

  return <Fragment>{children}</Fragment>
}

export default Auth
