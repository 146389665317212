import { useContext } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import _ from "lodash"
import { Layout, AppBar, LoadingIndicator } from "../../../components"
import AvatarsForm from "../../../components/Form/Avatars"
import routes from "../../../constants/routes"
import messages from "../../../constants/messages"
import AppContext from "../../../Context"
import { updateUserProfileRequest } from "../../../actions/auth"

const limit = 4

const Avatars = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)

  const handleFinish = (avatars) => {
    setContext("overlay", <LoadingIndicator />)
    const form = new FormData()

    var idx = 0
    for (var i = 0; i < 4; i++) {
      if (i === 0) {
        if (avatars[i]) {
          form.append(`images[${idx}][type]`, 1)
          form.append(`images[${idx}][display_order]`, i + 1)
          form.append(`images[${idx}][image]`, avatars[i].file)
          idx++
        }
      } else {
        var fileInput = document.getElementById("file" + i)
        if (fileInput && fileInput.files.length > 0) {
          form.append(`images[${idx}][type]`, 1)
          form.append(`images[${idx}][display_order]`, i + 1)
          form.append(
            `images[${idx}][image]`,
            fileInput.files[0],
            fileInput.files[0].name
          )
          idx++
        }
      }
    }

    dispatch(
      updateUserProfileRequest(form, {
        onSuccess: () => {
          history.push(routes.USERS)
          setContext("overlay", null)
        },
        onFailed: (error) => {
          alert(_.get(error, "data.error.message", messages.somethingWrong))
          setContext("overlay", null)
        }
      })
    )
  }

  return (
    <Layout>
      <AppBar withBack title="新規登録" colorTitle="red"/>
      <div className="container">
        <AvatarsForm onSubmit={handleFinish} limit={limit} />
      </div>
    </Layout>
  )
}

export default Avatars
