import { useHistory } from "react-router-dom"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { Empty } from "./Components/Empty"
import { useDispatch, useSelector } from "react-redux"
import { useState, useContext } from "react"
import {
  withdrawalRequest,
} from "../../../actions/other"
import { LoadingIndicator } from "../../../components/LoadingIndicator"
import _ from "lodash"
import "./styles.scss"
import AppContext from "../../../Context"
import { ErrorModal } from "../../../components/ErrorModal"

const Withdrawal = () => {
  const dispatch = useDispatch()
	const history = useHistory()

	const numberWithCommas = (x) => {
		return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	}

  const profileUserData = useSelector((state) => state.auth.profile)
  const bankRegisterStatus = profileUserData?.bank_name
  const [withDrawalPoint, setWithDrawalPoint] = useState('')
  const [errorModal, setErrorModal] = useState(false)
  const [error, setError] = useState('')
  const { setContext } = useContext(AppContext)

  const handleRequest = () => {
    let regex = /^[0-9]*$/
    if(!regex.test(withDrawalPoint)){
      setContext("overlay", null)
      setError("10ポイント単位で指定してください")
      setErrorModal(true)
    }else{
      let pointSave = parseInt(profileUserData?.points_balance, 10)
      let withDrawalPointInt = parseInt(withDrawalPoint, 10)
      if(withDrawalPointInt > pointSave){
        setError("ポイントは不足しています。")
        setErrorModal(true)
      }else if(withDrawalPointInt < 50){
        setError("最低出金ポイントは50ポイント(5,000円)になります")
        setErrorModal(true)
      }else{
        setContext("overlay", <LoadingIndicator />)
        const data = {
          withdrawal_amount:  withDrawalPointInt
        }
        
        dispatch(withdrawalRequest(data,{
          onSuccess: (response) => {
            if(response.status == 422){
              setContext("overlay", null)
              setError("10ポイント単位で指定してください")
              setErrorModal(true)
            }else if(response.status == 200){    
              setContext("overlay", null)
              profileUserData.points_balance = pointSave - withDrawalPointInt 
              history.push('withdrawal-confirm?point=' + withDrawalPoint)
            }else{
              setContext("overlay", null)
              setError("サーバーに接続できません。")
              setErrorModal(true)
            }
          },
          onFailed: (error) => {
            setError("システムエラー")
            setErrorModal(true)
          }
        }))
      }
    }
  }

  return (
    <Layout>
      <AppBar title="出金申請" withBack />
      {bankRegisterStatus != null &&
        <div
          className="options-btn yu-gothic-bold"
          onClick={() => (history.push('/withdrawal-history'))}
        >
          履歴
        </div>
      }
      <div
        className="bg-white d-flex flex-column full-height-with-navbar pt-2"
      >
        { !_.isEmpty(profileUserData)
            ? ( bankRegisterStatus == null
                  ? ( <Empty /> )
                  : ( <div
                        className="p-3 d-flex flex-column"
                      >
                        <div
                          className="p-3 border btn-without-arrow"
                        >
                          <div className="float-left-without-icon yu-gothic-bold">ポイント残高</div>
                          <div className="float-right content-with-coin yu-gothic-bold">{numberWithCommas(Number(profileUserData.points_balance))}</div>
                        </div>

                        <p className="smaller-text yu-gothic-bold p-1">
                          最低出金ポイントは50ポイント（5,000円）になります。また、出金ポイントは10ポイント単位で指定可能です。<br/>
                          年齢確認時に提出いただいた書類と同一名義の口座をご利用ください。他人口座等はトラブル防止のため振り込み対応しておりません。
                        </p>

                        <div
                          className="d-flex flex-column border-bottom"
                        >
                          <label
                            className="float-left-without-icon yu-gothic-bold mt-4"
                          >
                            出金するポイント
                          </label>
                          <input
                            type="number"
                            inputMode="numeric"
                            className="border-0 yu-gothic-bold padding-5"
                            value={withDrawalPoint}
                            onChange={(e) => setWithDrawalPoint(e.target.value)}
                            placeholder="0"
                          />
                        </div>

                        <div>
                          <div
                            className="d-flex yu-gothic-bold justify-content-between h3 mt-5"
                          >
                            <div>振込金額</div>
                            <div>{`${withDrawalPoint > 49 ?'¥' + numberWithCommas(Number(withDrawalPoint * 100 - 500)) :'-'}`}</div>
                          </div>

                          <div
                            className="d-flex yu-gothic-bold justify-content-between text-secondary mt-3"
                          >
                            <div>出金金額</div>
                            <div>{`${withDrawalPoint > 0 ?'¥' + numberWithCommas(Number(withDrawalPoint * 100)) :'-'}`}</div>
                          </div>

                          <div
                            className="d-flex yu-gothic-bold justify-content-between text-secondary mt-3"
                          >
                            <div>振込手数料</div>
                            <div>{`${withDrawalPoint > 0 ?'-¥500' :'-'}`}</div>
                          </div>

                          <button
                            className="btn btn-danger mt-4 sumbit-btn w-100 pt-3 pb-3"
                            onClick={handleRequest}
                          >
                            この内容で申請する
                          </button>
                        </div>
                        
                        <h6
                          className="mx-auto text-decoration-underline mt-3"
                          onClick={() => (history.push('/bank-register'))}
                        >銀行情報の変更</h6>

                        <ErrorModal
                          show={errorModal}
                          content={error}
                          onClose={() => setErrorModal(false)}
                        />
                      </div>)
              ) 
            : ( <LoadingIndicator />)
        }
      </div>
    </Layout>
  )
}

export default Withdrawal