import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import {
  fetchDetailNotification,
  fetchUpdateReadNotification,
} from "../../../actions/other"
import { LoadingIndicator } from "../../../components/LoadingIndicator"
import { Image } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import moment from "moment"
import "moment/locale/ja"
import _ from "lodash"

const DetailNotification = () => {
  const dispatch = useDispatch()
  const [notiContent, setNotiContent] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const notification = useSelector((state) => state.notification.data)
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()
  const noticeId = query.get("id")
  useEffect(() => {
    if (notification) {
      setNotiContent(notification[0])
      setIsLoading(false)
    }
  })

  useEffect(() => {
    dispatch(fetchDetailNotification(noticeId))
    dispatch(fetchUpdateReadNotification(noticeId))
    setNotiContent({})
  }, [])

  return (
    <Layout>
      <AppBar title="お知らせ" withBack />
      <div className="bg-white d-flex flex-column full-height-with-navbar p-3">
        {_.isEmpty(notiContent) ? (
          <LoadingIndicator />
        ) : (
          <div>
            <h6>{moment(notiContent?.created_at).format("LLL")}</h6>
            <h3 className="pt-2">{notiContent.title}</h3>
            {notiContent.image_url && (
              <Image src={notiContent.image_url} className="mw-100 pt-3" />
            )}
            <p className="pt-3">{notiContent.body}</p>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default DetailNotification
