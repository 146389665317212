import _ from "lodash"
import moment from "moment"
import { initializeApp } from "firebase/app"
import {
  getFirestore,
  collection,
  getDocs,
  updateDoc,
  query,
  where,
  documentId,
  onSnapshot,
  doc,
  addDoc,
  orderBy,
  serverTimestamp,
  getDoc
} from "firebase/firestore"
import {
  getAuth,
  signInWithPhoneNumber,
  signOut as firebaseSignOut
} from "firebase/auth"
import {
  FIREBASE_COLLECTION_LIKES,
  FIREBASE_COLLECTION_USERS,
  FIREBASE_COLLECTION_MESSAGES,
  LIKE_STATUS,
  FIREBASE_COLLECTION_VIDEO_CALLS,
  VIDEO_CALL_STATUS,
  MESSAGE_TYPES
} from "../constants/env"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)
const db = getFirestore()

const likesRef = collection(db, FIREBASE_COLLECTION_LIKES)
const usersRef = collection(db, FIREBASE_COLLECTION_USERS)

let getLikersUnsubscribe = null
let getLikedUsersUnsubscribe = null
let getMatchListUnsubscribe = null
let getChatListUnsubscribe = null
let getUnreadMatchCountUnsubscribe = null
let getUnreadMessageCountUnsubscribe = null
let getMessageListUnsubscribe = null

export const getApp = () => app

export const sendVerifyCode = async (phone, onSuccess, onFailure) => {
  const auth = getAuth()
  const appVerifier = window.recaptchaVerifier

  await signInWithPhoneNumber(auth, phone, appVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      onSuccess && onSuccess(confirmationResult)
    })
    .catch((error) => {
      // Error; SMS not sent
      onFailure && onFailure(error)
    })
}

export const signOut = async (onSuccess, onFailure) => {
  const auth = getAuth()
  firebaseSignOut(auth)
    .then((success) => {
      if (onSuccess) {
        onSuccess && onSuccess(success)
      }
    })
    .catch((error) => {
      if (onFailure) {
        onFailure && onFailure(error)
      }
    })
}

export const addDeviceId = async (userId, uid) => {
  const docRef = doc(db, FIREBASE_COLLECTION_USERS, userId.toString())
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    const deviceIds = []
    if (docSnap.data().deviceIds && !docSnap.data().deviceIds.includes(uid)) {
      deviceIds.push(...docSnap.data().deviceIds)
    }
    deviceIds.push(uid)
    await updateDoc(docRef, { deviceIds })
  } else {
    console.log("User doc does not exist")
  }
}

export const getUserDeviceIds = async (userId) => {
  const docRef = doc(db, FIREBASE_COLLECTION_USERS, userId.toString())
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    if (docSnap.data().deviceIds) return docSnap.data().deviceIds
    else return []
  } else {
    console.log("User doc does not exist")
  }
}

const sortUsers = (arr, fieldName) => {
  for (let i = 0; i < arr.length - 1; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (arr[i][fieldName] < arr[j][fieldName]) {
        const temp = arr[i]
        arr[i] = arr[j]
        arr[j] = temp
      }
    }
  }
  return arr
}

export const getLikes = async (userId, callback, isFromMe) => {
  const userIds = []
  const likesData = {}

  const q = query(
    likesRef,
    where(isFromMe ? "fromUserId" : "toUserId", "==", userId),
    where("status", "==", LIKE_STATUS.NO_RESPONSE)
  )

  const handleSnapshot = async (snapshot) => {
    snapshot.forEach(async (doc) => {
      const data = doc.data()

      if (
        (isFromMe &&
          (!data.isMutedByFromUser || data.isMutedByFromUser !== userId)) ||
        (!isFromMe &&
          (!data.isMutedByToUser || data.isMutedByToUser !== userId))
      ) {
        const id = isFromMe
          ? data.toUserId.toString()
          : data.fromUserId.toString()
        userIds.push(id)
        likesData[id] = {
          likeId: doc.id,
          likedDatetime: data.likedDatetime,
          biography: data.biography,
          isLikeRead: isFromMe ? 1 : data.isLikeRead,
          isLabel: _.includes(data.labelFromUserId, userId) ? true : false,
          likeType: data.likeType
        }
      }
    })

    const users = []
    while (userIds.length > 0) {
      const batch = userIds.splice(0, 10)
      const snapshot = await getDocs(
        query(usersRef, where(documentId(), "in", batch))
      )

      snapshot.forEach((doc) => {
        if (doc.data()?.isArchived !== true) {
          users.push({
            ...doc.data(),
            id: doc.id,
            likeId: likesData[doc.id].likeId,
            likedDatetime: likesData[doc.id].likedDatetime,
            biography: likesData[doc.id].biography,
            isLikeRead: likesData[doc.id].isLikeRead,
            is_label: likesData[doc.id].isLabel,
            likeType: likesData[doc.id].likeType
          })
        }
      })
    }
    callback(sortUsers(users, "likedDatetime"))
  }
  if (isFromMe) {
    if (getLikedUsersUnsubscribe) getLikedUsersUnsubscribe()
    getLikedUsersUnsubscribe = onSnapshot(q, async (snapshot) => {
      await handleSnapshot(snapshot)
    })
  } else {
    if (getLikersUnsubscribe) getLikersUnsubscribe()
    getLikersUnsubscribe = onSnapshot(q, async (snapshot) => {
      await handleSnapshot(snapshot)
    })
  }
}

export const getLikeStatus = async (userId, partnerId) => {
  const likeIds = []
  const snapshot1 = await getDocs(
    query(
      likesRef,
      where("fromUserId", "==", userId),
      where("toUserId", "==", partnerId)
    )
  )
  snapshot1.forEach((doc) => {
    const data = doc.data()
    if (data.status === 3) {
      likeIds.push(doc.id)
    }
  })
  if (likeIds.length === 0) {
    const snapshot2 = await getDocs(
      query(
        likesRef,
        where("toUserId", "==", userId),
        where("fromUserId", "==", partnerId)
      )
    )
    snapshot2.forEach((doc) => {
      const data = doc.data()
      if (data.status === 3) {
        likeIds.push(doc.id)
      }
    })
  }
  if (likeIds.length > 0) {
    return likeIds[0]
  }
  return null
}

export const updateLikeStatus = async (likeId, status) => {
  const docRef = doc(db, FIREBASE_COLLECTION_LIKES, likeId.toString())
  await updateDoc(docRef, { status })
}

export const updateLikeReadStatus = async (userId) => {
  const likeIds = []
  const snapshot = await getDocs(
    query(
      likesRef,
      where("toUserId", "==", userId),
      where("isLikeRead", "==", 0)
    )
  )
  snapshot.forEach((doc) => {
    likeIds.push(doc.id)
  })

  await Promise.all(
    likeIds.map(async (docId) => {
      await updateDoc(doc(db, FIREBASE_COLLECTION_LIKES, docId), {
        isLikeRead: 1
      })
    })
  )
}

export const updateLikeRead = async (likeId) => {
  await updateDoc(doc(db, FIREBASE_COLLECTION_LIKES, likeId), {
    isLikeRead: 1
  })
}

export const getMatchList = async (userId, callback) => {
  const userIds = []
  const likesData = {}

  const q = query(
    likesRef,
    where("members", "array-contains", userId.toString()),
    where("status", "==", LIKE_STATUS.MATCHED),
    where("lastSentDatetime", "==", null),
    where("matchingDatetime", ">", 0),
    orderBy("matchingDatetime", "desc")
  )

  if (getMatchListUnsubscribe) getMatchListUnsubscribe()
  getMatchListUnsubscribe = onSnapshot(q, async (snapshot) => {
    snapshot.forEach((doc) => {
      const data = doc.data()
      if (
        data.isMutedByFromUser !== userId &&
        data.isMutedByToUser !== userId
      ) {
        const otherId =
          data.fromUserId === userId ? data.toUserId : data.fromUserId
        userIds.push(otherId.toString())
        likesData[otherId] = {
          matchingDatetime: data.matchingDatetime,
          likeId: doc.id
        }
      }
    })
    const users = []
    while (userIds.length > 0) {
      const batch = userIds.splice(0, 10)
      const snapshot = await getDocs(
        query(usersRef, where(documentId(), "in", batch))
      )
      snapshot.forEach((doc) => {
        if (doc.data()?.isArchived !== true) {
          users.push({
            ...doc.data(),
            id: doc.id,
            likeId: likesData[doc.id].likeId,
            matchingDatetime: likesData[doc.id].matchingDatetime
          })
        }
      })
    }
    callback(sortUsers(users, "matchingDatetime"))
  })
}

export const getChatList = async (userId, onSuccess, onError) => {
  const userIds = []
  const likesData = {}

  const q = query(
    likesRef,
    where("members", "array-contains", userId.toString()),
    where("status", "==", LIKE_STATUS.MATCHED)
  )

  if (getChatListUnsubscribe) getChatListUnsubscribe()
  getChatListUnsubscribe = onSnapshot(
    q,
    async (snapshot) => {
      snapshot.forEach((doc) => {
        const data = doc.data()
        if (
          data.isMutedByFromUser !== userId &&
          data.isMutedByToUser !== userId
        ) {
          const otherId =
            data.fromUserId === userId ? data.toUserId : data.fromUserId
          userIds.push(otherId.toString())

          let isPinned = false
          let pinnedAt = null
          let isOffnotificationChat = false
          let isHide = false
          let hideAt = null
          if (userId == data.fromUserId) {
            isPinned = !_.isNil(data.pinnedFromUserIdAt)
            pinnedAt = data.pinnedFromUserIdAt
            isOffnotificationChat = data.isOffnotificationChatFromUser
            isHide = !_.isNil(data.hideFromUserIdAt)
            hideAt = data.hideFromUserIdAt
          }
          if (userId == data.toUserId) {
            isPinned = !_.isNil(data.pinnedToUserIdAt)
            pinnedAt = data.pinnedToUserIdAt
            isOffnotificationChat = data.isOffnotificationChatToUser
            isHide = !_.isNil(data.hideToUserIdAt)
            hideAt = data.hideToUserIdAt
          }

          const q = query(
            collection(
              db,
              FIREBASE_COLLECTION_LIKES,
              doc.id,
              FIREBASE_COLLECTION_MESSAGES
            ),
            orderBy("createdAt", "desc")
          )

          const messages = []
          getMessageListUnsubscribe = onSnapshot(q, async (snapshot) => {
            snapshot.forEach((doc) => {
              let data = doc.data()
              messages.push(data)
            })
          })

          likesData[otherId] = {
            likeId: doc.id,
            lastSentDatetime: data.lastSentDatetime
              ? data.lastSentDatetime
              : data.matchingDatetime,
            unreadMessageCount: data.unreadMessageCount,
            lastMessage: data.lastMessage,
            isMatchingRead: data.isMatchingRead,
            isRead: data.isRead,
            paymentType: data.paymentType,
            isHide: isHide,
            hideAt: hideAt,
            isLabel: _.includes(data.labelFromUserId, userId) ? true : false,
            isPinned: isPinned,
            pinnedAt: pinnedAt,
            isOffnotificationChat: isOffnotificationChat,
            matchingDatetime: data.matchingDatetime || null,
            messages: messages,
          }
        }
      })
      const users = []
      while (userIds.length > 0) {
        const batch = userIds.splice(0, 10)
        const snapshot = await getDocs(
          query(usersRef, where(documentId(), "in", batch))
        )
        let batchUsers = []
        snapshot.forEach((doc) => {
          if (doc.data()?.isArchived !== true) {
            batchUsers.push({
              ...doc.data(),
              id: doc.id,
              likeId: likesData[doc.id].likeId,
              lastSentDatetime: likesData[doc.id].lastSentDatetime,
              unreadMessageCount: likesData[doc.id].unreadMessageCount,
              lastMessage: likesData[doc.id].lastMessage,
              isMatchingRead: likesData[doc.id].isMatchingRead,
              isRead: likesData[doc.id].isRead,
              isHide: likesData[doc.id].isHide,
              isHideAt: likesData[doc.id].hideAt,
              is_label: likesData[doc.id].isLabel,
              is_pinned: likesData[doc.id].isPinned,
              pinned_at: likesData[doc.id].pinnedAt,
              matchingDatetime: likesData[doc.id].matchingDatetime,
              notificationSettings: doc.data().notificationSettings,
              isOffnotificationChat: likesData[doc.id].isOffnotificationChat,
              messages: likesData[doc.id].messages,
            })
          }
        })
        batchUsers = sortUsers(batchUsers, "lastSentDatetime")
        users.push(...batchUsers)
      }
      onSuccess(users.filter(item => !item.isHide))
    },
    (error) => {
      throw error
    }
  )
}

export const getHideList = async (userId, onSuccess, onError) => {
  const userIds = []
  const likesData = {}

  const q = query(
    likesRef,
    where("members", "array-contains", userId.toString()),
    where("status", "==", LIKE_STATUS.MATCHED)
  )

  if (getChatListUnsubscribe) getChatListUnsubscribe()
  getChatListUnsubscribe = onSnapshot(
    q,
    async (snapshot) => {
      snapshot.forEach((doc) => {
        const data = doc.data()
        if (
          data.isMutedByFromUser !== userId &&
          data.isMutedByToUser !== userId &&
          (data.isHide && (data.fromUserId === userId && data.hideFromUserIdAt || data.toUserId === userId && data.hideToUserIdAt))
        ) {
          const otherId =
            data.fromUserId === userId ? data.toUserId : data.fromUserId
          userIds.push(otherId.toString())
          likesData[otherId] = {
            likeId: doc.id,
            lastSentDatetime: data.lastSentDatetime
              ? data.lastSentDatetime
              : data.matchingDatetime,
            unreadMessageCount: data.unreadMessageCount,
            lastMessage: data.lastMessage,
            isMatchingRead: data.isMatchingRead,
            isHide: data.isHide,
            skippedDatetime: data.skippedDatetime,
            isLabel: _.includes(data.labelFromUserId, userId) ? true : false
          }
        }
      })
      const users = []
      while (userIds.length > 0) {
        const batch = userIds.splice(0, 10)
        const snapshot = await getDocs(
          query(usersRef, where(documentId(), "in", batch))
        )
        let batchUsers = []
        snapshot.forEach((doc) => {
          batchUsers.push({
            ...doc.data(),
            id: doc.id,
            likeId: likesData[doc.id].likeId,
            lastSentDatetime: likesData[doc.id].lastSentDatetime,
            unreadMessageCount: likesData[doc.id].unreadMessageCount,
            lastMessage: likesData[doc.id].lastMessage,
            isMatchingRead: likesData[doc.id].isMatchingRead,
            isHide: likesData[doc.id].isHide,
            skippedDatetime: likesData[doc.id].skippedDatetime,
            is_label: likesData[doc.id].isLabel
          })
        })
        batchUsers = sortUsers(batchUsers, "lastSentDatetime")
        users.push(...batchUsers)
      }
      onSuccess(users)
    },
    (error) => {
      throw error
    }
  )
}

export const getUnreadMatchCount = async (userId, callback) => {
  const q = query(
    likesRef,
    where("members", "array-contains", userId.toString()),
    where("status", "==", LIKE_STATUS.MATCHED)
  )

  if (getUnreadMatchCountUnsubscribe) getUnreadMatchCountUnsubscribe()
  getUnreadMatchCountUnsubscribe = onSnapshot(q, async (snapshot) => {
    let count = 0
    snapshot.forEach((doc) => {
      const data = doc.data()
      const matchingReadUsers = data.matchingReadUsers || []
      if (
        data.isMutedByFromUser !== userId &&
        data.isMutedByToUser !== userId &&
        !matchingReadUsers.includes(userId.toString())
      ) {
        count++
      }
    })
    callback(count)
  })
}

export const clearUnreadMatchCount = async (userId) => {
  const snapshot = await getDocs(
    query(likesRef, where("members", "array-contains", userId.toString()))
  )
  const promises = []
  snapshot.forEach((docSnapshot) => {
    const matchingReadUsers = docSnapshot.data().matchingReadUsers || []
    if (!matchingReadUsers.includes(userId.toString())) {
      matchingReadUsers.push(userId.toString())
      promises.push(
        updateDoc(doc(db, FIREBASE_COLLECTION_LIKES, docSnapshot.id), {
          matchingReadUsers
        })
      )
    }
  })
  if (promises.length > 0) {
    await Promise.all(promises)
  }
}

export const getUnreadMessageCount = async (userId, callback) => {
  const q = query(
    likesRef,
    where("members", "array-contains", userId.toString()),
    where("status", "==", LIKE_STATUS.MATCHED),
    where("lastSentDatetime", ">", 0)
  )

  if (getUnreadMessageCountUnsubscribe) getUnreadMessageCountUnsubscribe()
  getUnreadMessageCountUnsubscribe = onSnapshot(q, async (snapshot) => {
    let count = 0
    snapshot.forEach((doc) => {
      const data = doc.data()
      const isFromMe =
        data.lastMessage &&
        data.lastMessage?.userId?.toString() === userId?.toString()
      if (
        !isFromMe &&
        data.isMutedByFromUser !== userId &&
        data.isMutedByToUser !== userId
      ) {
        const unreadMessageCount = data.unreadMessageCount || 0
        if (unreadMessageCount > 0) {
          count++
        }
      }
    })
    callback(count)
  })
}

export const clearUnreadMessages = async (likeId, myId) => {
  const likeDocRef = doc(db, FIREBASE_COLLECTION_LIKES, likeId.toString())
  const likeDocSnap = await getDoc(likeDocRef)
  if (likeDocSnap.exists()) {
    if (
      likeDocSnap.data().lastMessage &&
      likeDocSnap.data().lastMessage?.userId !== myId
    ) {
      const lastMessage = {
        ...likeDocSnap.data().lastMessage,
        isRead: 1
      }
      await updateDoc(likeDocRef, { lastMessage })
    }
  } else {
    console.log("Like document not exist")
    return
  }

  const q = query(
    collection(
      db,
      FIREBASE_COLLECTION_LIKES,
      likeId.toString(),
      FIREBASE_COLLECTION_MESSAGES
    ),
    where("userId", "!=", myId.toString(), where("isRead", "==", 0))
  )
  const snaps = await getDocs(q)
  const promises = []
  snaps.forEach(async (snap) => {
    promises.push(updateDoc(snap.ref, { isRead: 1 }))
  })
  await Promise.all(promises)
}

export const clearUnreadMessageCount = async (likeId, myId) => {
  const docRef = doc(db, FIREBASE_COLLECTION_LIKES, likeId.toString())
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    const lastSenderId = docSnap.data().lastMessage?.userId || -1
    if (lastSenderId.toString() !== myId.toString()) {
      await updateDoc(docRef, {
        unreadMessageCount: 0
      })
    }
  } else {
    console.log("this like document does not exist: ", likeId)
  }
}

export const getMessageList = async (likeId, onSuccess, onError) => {
  const q = query(
    collection(
      db,
      FIREBASE_COLLECTION_LIKES,
      likeId,
      FIREBASE_COLLECTION_MESSAGES
    ),
    orderBy("createdAt", "desc")
  )

  if (getMessageListUnsubscribe) getMessageListUnsubscribe()
  getMessageListUnsubscribe = onSnapshot(q, async (snapshot) => {
    const messages = []
    snapshot.forEach((doc) => {
      let data = doc.data()
      messages.push(data)
    })
    onSuccess(messages)
  })
}

export const sendMessage = async (message, likeId) => {
  let data = {...message, createdAt: serverTimestamp()};
  const docRef = await addDoc(
    collection(
      db,
      FIREBASE_COLLECTION_LIKES,
      likeId,
      FIREBASE_COLLECTION_MESSAGES
    ),
    data
  )
  if(docRef.id) {
    // const msgDocSnap = await getDoc(docRef)
    // if (msgDocSnap.exists()) {
      const likeDocRef = doc(db, FIREBASE_COLLECTION_LIKES, likeId)
      const likeDocSnap = await getDoc(likeDocRef)
      if (likeDocSnap.exists()) {
        const unreadMessageCount = likeDocSnap.data().unreadMessageCount || 0
        await updateDoc(likeDocRef, {
          lastMessage: data,
          lastSentDatetime: moment().valueOf(),
          unreadMessageCount: unreadMessageCount + 1
        })
        // await getDoc(likeDocRef)
      } else throw new Error("Likes document not found")
    // } else throw new Error("Message document not found")
  }
  // await updateDoc(docRef, {
  //   createdAt: serverTimestamp()
  // })
}

export const updateVideoCall = async (videoCallId, status, endDatetime) => {
  const docRef = doc(db, FIREBASE_COLLECTION_VIDEO_CALLS, videoCallId)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    let data = null
    if (docSnap.data().status !== VIDEO_CALL_STATUS.ENDED) {
      if (status === VIDEO_CALL_STATUS.ENDED) {
        data = {
          status,
          updatedAt: endDatetime || moment().valueOf(),
          endDatetime: endDatetime || moment().valueOf()
        }
      } else {
        data = {
          status:
            status === VIDEO_CALL_STATUS.MISSED
              ? VIDEO_CALL_STATUS.ENDED
              : status
        }
      }
      await updateDoc(docRef, data)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        return docSnap.data()
      }
    } else {
      console.log("This video call has ended")
    }
  } else {
    console.log("video call document not exists: ", videoCallId)
  }
}

export const updateVideoMessage = async (likeId, messageId, message) => {
  const likeDocRef = doc(db, FIREBASE_COLLECTION_LIKES, likeId.toString())
  const likeDocSnap = await getDoc(likeDocRef)
  if (!likeDocSnap.exists()) {
    console.log("Cannot find like document: ", likeId)
    return
  }
  const likeData = likeDocSnap.data()
  if (
    likeData.lastMessage &&
    likeData.lastMessage.videoCallId === message.videoCallId
  ) {
    console.log("Last message has been updated: ", message.videoCallId)
    return
  }
  const msg = {
    ...message,
    createdAt: serverTimestamp(),
    videoCallStatus:
      message.videoCallStatus === VIDEO_CALL_STATUS.MISSED
        ? VIDEO_CALL_STATUS.ENDED
        : message.videoCallStatus
  }
  if (messageId) {
    const docRef = doc(
      db,
      FIREBASE_COLLECTION_LIKES,
      likeId.toString(),
      FIREBASE_COLLECTION_MESSAGES,
      messageId
    )
    let docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      await updateDoc(docRef, msg)
      docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        await updateLastMessage(likeId, docSnap.data())
      } else {
        console.log("Message document not found")
      }
    } else {
      throw new Error("Message not found")
    }
  } else {
    const docRef = await addDoc(
      collection(
        db,
        FIREBASE_COLLECTION_LIKES,
        likeId.toString(),
        FIREBASE_COLLECTION_MESSAGES
      ),
      msg
    )
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      await updateLastMessage(likeId, docSnap.data())
    } else {
      console.log("Message document not found")
    }
  }
}

export const updateLastMessage = async (likeId, lastMessage) => {
  const docRef = doc(db, FIREBASE_COLLECTION_LIKES, likeId.toString())
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    const unreadMessageCount = docSnap.data().unreadMessageCount
    const lastSentDatetime =
      lastMessage.type === MESSAGE_TYPES.VIDEO_CALL
        ? lastMessage.videoCallEndDatetime
          ? lastMessage.videoCallEndDatetime
          : lastMessage.createdAt
        : lastMessage.createdAt
    const updateData = {
      unreadMessageCount: unreadMessageCount + 1,
      lastMessage,
      lastSentDatetime: moment().valueOf()
    }
    await updateDoc(docRef, updateData)
  } else {
    console.log("Like document does not exist")
  }
}

export const updatedIsMatchRead = async (data) => {
  const docRef = doc(db, FIREBASE_COLLECTION_LIKES, data.likeID.toString())
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    let updateData = {}
    let lastMessage = {}
    if (data.isMatchingRead) {
      updateData.isMatchingRead = 1
      updateData.unreadMessageCount = 0
      lastMessage = {
        ...docSnap.data().lastMessage,
        isRead: 1
      }
      await updateDoc(docRef, updateData)
      await updateDoc(docRef, { lastMessage })
    } else {
      const isRead = docSnap.data().isRead
      updateData.isRead = true
      await updateDoc(docRef, updateData)
    }
  } else {
    console.log("Like document does not exist")
  }
}

export const updatedIsHideUser = async (data) => {
  const docRef = doc(db, FIREBASE_COLLECTION_LIKES, data.likeID.toString())
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    let updateData = {}
    const isHide = docSnap.data().isHide
    let hideFromUserIdAt = docSnap.data().hideFromUserIdAt || null
    let hideToUserIdAt = docSnap.data().hideToUserIdAt || null
    updateData.skippedDatetime = serverTimestamp()
      if (data.isHide) {
        if (data.fromUserId == docSnap.data().fromUserId) {
          hideFromUserIdAt = moment().valueOf()
        }
        if (data.fromUserId == docSnap.data().toUserId) {
          hideToUserIdAt = moment().valueOf()
        }
        updateData.isHide = true;
      } else {
        if (data.fromUserId == docSnap.data().fromUserId) {
          hideFromUserIdAt = null
        }
        if (data.fromUserId == docSnap.data().toUserId) {
          hideToUserIdAt = null
        }
        updateData.isHide = !!hideFromUserIdAt || !!hideToUserIdAt;
      }
      updateData.hideFromUserIdAt = hideFromUserIdAt
      updateData.hideToUserIdAt = hideToUserIdAt
    await updateDoc(docRef, updateData)
  } else {
    console.log("Like document does not exist")
  }
}

export const pinUser = async (likeID, fromUserId, isPinned) => {
  try {
    const likeDocRef = doc(db, FIREBASE_COLLECTION_LIKES, likeID.toString())
    const likeDocSnap = await getDoc(likeDocRef)
    if (likeDocSnap.exists()) {
      let dataUpdate = {}
      let pinnedFromUserIdAt = likeDocSnap.data().pinnedFromUserIdAt || null
      let pinnedToUserIdAt = likeDocSnap.data().pinnedToUserIdAt || null
      if (isPinned) {
        if (fromUserId == likeDocSnap.data().fromUserId) {
          pinnedFromUserIdAt = moment().valueOf()
        }
        if (fromUserId == likeDocSnap.data().toUserId) {
          pinnedToUserIdAt = moment().valueOf()
        }
      } else {
        if (fromUserId == likeDocSnap.data().fromUserId) {
          pinnedFromUserIdAt = null
        }
        if (fromUserId == likeDocSnap.data().toUserId) {
          pinnedToUserIdAt = null
        }
      }
      dataUpdate.pinnedFromUserIdAt = pinnedFromUserIdAt
      dataUpdate.pinnedToUserIdAt = pinnedToUserIdAt
      await updateDoc(likeDocRef, dataUpdate)

      return {
        status: 200,
        message: "更新されました"
      }
    } else {
      return {
        status: 400,
        message: "サーバーに接続できません。"
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const offNotifyUser = async (likeID, fromUserId) => {
  try {
    const likeDocRef = doc(db, FIREBASE_COLLECTION_LIKES, likeID?.toString())
    const likeDocSnap = await getDoc(likeDocRef)
    if (likeDocSnap.exists()) {
      let dataUpdate = {}
      if (fromUserId == likeDocSnap.data().toUserId) {
        dataUpdate.isOffnotificationChatToUser =
          !likeDocSnap.data()?.isOffnotificationChatToUser || false
      }
      if (fromUserId == likeDocSnap.data().fromUserId) {
        dataUpdate.isOffnotificationChatFromUser =
          !likeDocSnap.data()?.isOffnotificationChatFromUser || false
      }
      await updateDoc(likeDocRef, dataUpdate)

      return {
        status: 200,
        message: "更新されました"
      }
    } else {
      return {
        status: 400,
        message: "サーバーに接続できません。"
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const detachAllListeners = () => {
  getLikersUnsubscribe && getLikersUnsubscribe()
  getLikedUsersUnsubscribe && getLikedUsersUnsubscribe()
  getMatchListUnsubscribe && getMatchListUnsubscribe()
  getChatListUnsubscribe && getChatListUnsubscribe()
  getUnreadMatchCountUnsubscribe && getUnreadMatchCountUnsubscribe()
  getUnreadMessageCountUnsubscribe && getUnreadMessageCountUnsubscribe()
  getMessageListUnsubscribe && getMessageListUnsubscribe()
}
