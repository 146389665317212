import { Row, Col } from "react-bootstrap"
import { getLikeTime } from "../../../../utils"
import DefaultAvatar from "../../../../images/avatar-app.png"
import "../styles.scss"
import { onHideUser } from "../../../../actions/other"
import { toast } from "react-toastify"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"

const Item = ({ user }) => {
  const dispatch = useDispatch()
  const currentUserId = useSelector((state) => state.auth.profile.user_id)
  const dateTimeStr = user.skipped_at
  let time = null
  let date = null
  if (dateTimeStr) {
    const t = getLikeTime(dateTimeStr)
    time = t.time
    date = t.date
  }

  const handleUnHide = () => {
    dispatch(
      onHideUser({ likeID: user?.likeId, isHide: false, fromUserId: currentUserId } , {
        onSuccess: (response) => {
          toast("再表示にしました")
        },
        onFailed: () => {
          // setContext("overlay", null)
        }
      })
    )
  }

  return (
    <div className="px-4 yu-gothic">
      <Row className="mb-2 pb-1 py-2 border-bottom border-1">
        <div className="field field-avatar in-list">
          <img
            className={`avatar ${
              user.paymentType === 4
                ? "vip_4"
                : user.paymentType === 3
                ? "vip_3"
                : user.paymentType === 2
                ? "vip_2"
                : ""
            }`}
            src={user.image_url || DefaultAvatar}
            alt={user.nickname}
          />

          {(user.paymentType === 4 ||
            user.paymentType === 3 ||
            user.paymentType === 2) && (
            <p
              className="vip"
              style={{
                backgroundColor:
                  user.paymentType === 4
                    ? "#00205D"
                    : user.paymentType === 3
                    ? "#5E08B4"
                    : user.paymentType === 2
                    ? "#35ADD9"
                    : "black"
              }}
            >
              VIP
            </p>
          )}
        </div>
        <Col
          xs={9}
          md={9}
          className="px-2 py-1 d-flex flex-column justify-content-between"
        >
          <Row>
            <Col>
              <Row>
                <Col className="d-flex justify-content-between">
                  <div className="name overflow d-flex align-items-center">
                    {user?.nickname && (
                      <span className="overflow yu-gothic-bold">
                        {`${user.nickname} `}
                      </span>
                    )}
                    {user?.residence && user?.residence.length > 0 && (
                      <span className="overflow residence yu-gothic-bold">
                        {`${user?.residence}`}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Col className="d-flex align-items-center">
                <div className="d-flex yu-gothic-regular text-end mb-0">
                  {moment(new Date(user?.skippedDatetime.seconds * 1000)).format("YYYY/MM/DD HH:mm")}
                </div>
                <div className="block-item-action d-flex justify-content-end" style={{ flex: 'auto' }}>
                  <div
                    className="btn btn-outline-danger block-item-button"
                    onClick={handleUnHide}
                  >
                    再表示
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Item
