import { Link, useHistory } from "react-router-dom"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import Button from "react-bootstrap/Button"
import Layout from "../../components/Layout"
import routes from "../../constants/routes"
import SRLogo from "../../images/startlogo2.png"
import { updatePhoneNumber } from "../../actions/signup"
import TitlePage from "./TitlePage"
import './style.css';
import { runTitleTrackingScript } from "./titleTrackingScript";

const Title = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  localStorage.removeItem("registing")
  localStorage.removeItem("sort")

  const handleClick = (route) => {
    history.push(route)
  }

  useEffect(() => {
    dispatch(updatePhoneNumber(""))
  }, [])

  useEffect(() => {
    runTitleTrackingScript();
  }, []);

  return (
    <Layout>
      <div className="container px-0">
        <div
          className="page page-title min-vh-100 justify-content-center"
          style={{ paddingBottom: 100 }}
        >
          <TitlePage />
          <div className="w-100 align-self-center">
            <div className="d-grid gap-1 justify-content-center">
              {/* <img className="mx-auto" src={SRLogo} alt="" /> */}
              {/* <p className="text-center mt-4 mb-0 fs-4 text-grey-bold">
                アカウントをお持ちの方
              </p> */}
              <div className="wrap-btn-signins">
                <Button
                  onClick={() => handleClick(routes.LOGIN)}
                  variant="outline-dark"
                  size="lg"
                  className="btn fs-4 text-red-bold btn-signins"
                >
                  ログインする
                </Button>
                <Button
                  onClick={() => handleClick(routes.SIGNUP)}
                  variant="outline-dark"
                  size="lg"
                  className="btn fs-4 text-red-bold btn-signins"
                >
                  TUTUを始める
                </Button>
              </div>
              {/* <Button
                onClick={() => handleClick(routes.LOGIN)}
                variant="outline-dark"
                size="lg"
                className="btn fs-3 text-grey-bold btn-signins"
              >
                ログインする
              </Button> */}
              {/* <p className="text-center mt-4 mb-0 fs-4 text-grey-bold">
                かんたん１分スタート
              </p> */}
              {/* <Button
                onClick={() => handleClick(routes.SIGNUP)}
                variant="danger"
                size="lg"
                className="btn fs-3 btn-signups"
              >
                TUTUを始める
              </Button> */}
              <p className="text-center fs-6 text-grey-bold">
                アカウントを作成すると
                <br />
                <Link
                  to="/terms"
                  className="text-center text-decoration-underline mb-4 fs-6 text-grey-bold"
                >
                  利用規約
                </Link>
                に同意することになります
              </p>
              <Link
                to="/law"
                className="text-center text-decoration-underline mt-4 fs-6 text-grey-bold"
              >
                特定商法に基づく記載
              </Link>
              <Link
                to="/privacy"
                className="text-center text-decoration-underline mb-4 fs-6 text-grey-bold"
              >
                プライバシーポリシー
              </Link>

              <p className="text-center fs-8">
                インターネット異性紹介事業 届出済み
                <br />
                登録番号：新宿24-052402 (受理番号：30240033-000)
              </p>
              {/* Phase 1 Hidden 一旦フェース１に非表示 */}
              {/* <Button
                onClick={() => handleClick(routes.TAKEOVER)}
                variant="outline-dark"
                size="lg"
                className="btn fs-5 text-grey-bold"
              >
                アカウント引き継ぎの方はこちら
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Title
