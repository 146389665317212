import _ from "lodash"
import { useState, useContext } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import { useParams, useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { LoadingIndicator, Layout, AppBar } from "../../components"
import {
  conditionMeets,
  bodyMale,
  bodyFeMale,
  myPersonality,
  holiday
} from "../../constants/user"
import {
  updateUserProfileRequest
} from "../../actions"
import AppContext from "../../Context"
import messages from "../../constants/messages"

const EditItem = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)

  var profile = JSON.parse(localStorage.getItem("profileEdit"))

  const { field } = useParams()
  const [value, setValue] = useState(profile[field])
  const [multipleValue, setMultipleValue] = useState(
    profile.personality ? JSON.parse(profile.personality) : []
  )
  const [desiredArea, setDesiredArea] = useState(
    profile.match_area_1 ? JSON.parse(profile.match_area_1) : []
  )

  let dataHeight = []
  for (let i = 120; i <= 200; i++) {
    let itemHeight = {
      id: `${i}`,
      value: `${i}cm`
    }
    dataHeight.push(itemHeight)
  }

  let title
  let textButton
  switch (field) {
    case "comment":
      title = "ひとこと"
      textButton = "次へ"
      break
    case "biography":
      title = "自己紹介"
      textButton = "次へ"
      break
    case "height":
      title = "身長"
      textButton = "決定"
      break
    case "residence":
      title = "居住地"
      textButton = "決定"
      break
    case "figure":
      title = "体型"
      textButton = "決定"
      break
    case "birthplace":
      title = "出身地"
      textButton = "決定"
      break
    case "educational_background":
      title = "学歴"
      textButton = "決定"
      break
    case "baby_aspiration":
      title = "子供の有無"
      textButton = "決定"
      break
    case "date":
      title = "交際タイプ"
      textButton = "決定"
      break
    case "cigarette":
      title = "タバコ"
      textButton = "決定"
      break
    case "alcohol":
      title = "飲酒"
      textButton = "決定"
      break
    case "annual_income":
      title = "年収"
      textButton = "決定"
      break
    case "marriage":
      title = "結婚歴"
      textButton = "決定"
      break
    case "occupation":
      title = "職業"
      textButton = "決定"
      break
    case "condition":
      title = "会う条件"
      textButton = "決定"
      break
    case "desired_area":
      title = "希望エリア"
      textButton = "決定"
      break
    case "body":
      title = "体型"
      textButton = "決定"
      break
    case "personality":
      title = "自分の性格"
      textButton = "決定"
      break
    case "holiday":
      title = "休日"
      textButton = "決定"
      break
    default:
      title = "プロフィール"
      textButton = "次へ"
      break
  }

  let user = JSON.parse(localStorage.getItem("profileEdit"))
  let userOption = JSON.parse(localStorage.getItem("profileEditOption"))

  const setValueHere = (event) => {
    setValue(event.target.value)
  }

  const handleChangeMultipleValue = (value) => {
    setMultipleValue((prev) => {
      if (prev.some((i) => i.id === value.id)) {
        // If the value already exists in the state, remove it from the previous state
        return prev.filter((item) => item.id !== value.id)
      } else {
        // If the value is not in the state, append it to the previous state
        return [...prev, value]
      }
    })
  }

  const handleChangeDesiredArea = (value) => {
    setDesiredArea((prev) => {
      if (prev.some((i) => i.residence_id === value.residence_id)) {
        // If the value already exists in the state, remove it from the previous state
        return prev.filter((item) => item.residence_id !== value.residence_id)
      } else {
        // If the value is not in the state, append it to the previous state
        return [...prev, value]
      }
    })
  }

  const handleFinish = () => {
    setContext("overlay", <LoadingIndicator />)
    if (value && field !== "personality") {
      if (field === "comment" || field === "biography" || field === "holiday") {
        user[field] = value
      }
      if (field === "height") {
        user["height"] = value
      } else if (field === "condition") {
        user[field] = value
      } else if (field === "body") {
        user[field] = value
      } else {
        user[field + "_id"] = value
      }
    }

    if (multipleValue) {
      if (field === "personality") {
        user[field] = JSON.stringify(multipleValue)
      }
    }

    if (desiredArea) {
      if (field === "desired_area") {
        user["match_area_1"] = JSON.stringify(desiredArea)
      }
    }

    localStorage.setItem("profileEdit", JSON.stringify(user))
    let userEdit = JSON.parse(localStorage.getItem("profileEdit"))

    const form = new FormData()

    if (userEdit.residence_id) {
      form.append("residence_id", userEdit.residence_id)
    }

    if (userEdit.biography) {
      form.append("biography", userEdit.biography)
    }

    if (userEdit.match_area_1) {
      form.append("match_area_1", userEdit.match_area_1)
    }

    if (userEdit.condition) {
      form.append("condition", userEdit.condition)
    }

    if (userEdit.height) {
      form.append("height", userEdit.height)
    }

    if (userEdit.body) {
      form.append("body", userEdit.body)
    }

    if (userEdit.birthplace_id) {
      form.append("birthplace_id", userEdit.birthplace_id)
    }

    if (userEdit.occupation_id) {
      form.append("occupation_id", userEdit.occupation_id)
    }

    if (userEdit.annual_income_id) {
      form.append("annual_income_id", userEdit.annual_income_id)
    }

    if (userEdit.baby_aspiration_id) {
      form.append("baby_aspiration_id", userEdit.baby_aspiration_id)
    }

    if (userEdit.personality) {
      form.append("personality", userEdit.personality)
    }

    if (userEdit.alcohol_id) {
      form.append("alcohol_id", userEdit.alcohol_id)
    }

    if (userEdit.cigarette_id) {
      form.append("cigarette_id", userEdit.cigarette_id)
    }

    if (userEdit.holiday) {
      form.append("holiday", userEdit.holiday)
    }

    dispatch(
      updateUserProfileRequest(form, {
        onSuccess: () => {
          setContext("overlay", null)
          alert("保存しました。")
          history.push("/profile/edit")
        },
        onFailed: (error) => {
          alert(_.get(error, "data.error.message", messages.somethingWrong))
          setContext("overlay", null)
        }
      })
    )
  }

  return (
    <Layout>
      <AppBar title={title} onGoBack={() => history.goBack()} />
      <div className="bg-white d-flex flex-column full-height-with-navbar user-review user-review-fix">
        {userOption && (
          <div className="user-detail">
            <Container>
              <div className="user-fields">
                <div className="field field-bio clearfix pr-0">
                  <div className="field-label"></div>
                  <p></p>
                  <div className="field-value">
                    {field === "comment" && (
                      <textarea
                        maxLength="20"
                        className="w-100"
                        onChange={setValueHere}
                        rows="3"
                      >
                        {profile[field]}
                      </textarea>
                    )}
                    {field === "biography" && (
                      <div>
                        <textarea
                          className="w-100 p-2"
                          onChange={setValueHere}
                          rows="20"
                          maxLength="1000"
                        >
                          {profile[field]}
                        </textarea>
                        <p>{value?.length ? (1000 - value?.length) : 1000}文字残っております。</p>
                      </div>
                    )}

                    {field === "height" && (
                      <div className="field-value">
                        <div className="mb-5rem form-check">
                          {dataHeight.map((item, index) => (
                            <Form.Check
                              key={index}
                              inline
                              label={item.value}
                              name="height"
                              check="true"
                              value={item.id}
                              type="radio"
                              onChange={setValueHere}
                              id={`inline-height-` + index}
                              defaultChecked={user.height === item.id}
                            />
                          ))}
                        </div>
                      </div>
                    )}

                    {field === "condition" && (
                      <div className="field-value">
                        <div className="mb-5rem form-check">
                          {conditionMeets.map((item, index) => (
                            <Form.Check
                              key={item.id}
                              inline
                              label={item.name}
                              name="condition"
                              check="true"
                              value={item.name}
                              type="radio"
                              onChange={setValueHere}
                              id={`inline-height-` + index}
                              defaultChecked={user.condition === item.name}
                            />
                          ))}
                        </div>
                      </div>
                    )}

                    {field === "holiday" && (
                      <div className="field-value">
                        <div className="mb-5rem form-check">
                          {holiday.map((item, index) => (
                            <Form.Check
                              key={item.id}
                              inline
                              label={item.name}
                              name="holiday"
                              check="true"
                              value={item.name}
                              type="radio"
                              onChange={setValueHere}
                              id={`inline-height-` + index}
                              defaultChecked={user.holiday === item.name}
                            />
                          ))}
                        </div>
                      </div>
                    )}

                    {field === "desired_area" && (
                      <div className="field-value">
                        <div className="mb-5rem form-check">
                          {userOption.residences.map((item, index) => (
                            <Form.Check
                              key={item.id}
                              inline
                              label={item.name}
                              name="desired_area"
                              check="true"
                              value={item.residence_id}
                              type="checkbox"
                              onChange={() => handleChangeDesiredArea(item)}
                              id={`inline-height-` + index}
                              defaultChecked={desiredArea.some(
                                (value) =>
                                  value.residence_id === item.residence_id
                              )}
                            />
                          ))}
                        </div>
                      </div>
                    )}

                    {field === "body" && (
                      <>
                        {user.sex === 1 ? (
                          <div className="field-value">
                            <div className="mb-5rem form-check">
                              {bodyMale.map((item, index) => (
                                <Form.Check
                                  key={item.id}
                                  inline
                                  label={item.name}
                                  name="body"
                                  check="true"
                                  value={item.name}
                                  type="radio"
                                  onChange={setValueHere}
                                  id={`inline-height-` + index}
                                  defaultChecked={user.body === item.name}
                                />
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div className="field-value">
                            <div className="mb-5rem form-check">
                              {bodyFeMale.map((item, index) => (
                                <Form.Check
                                  key={item.id}
                                  inline
                                  label={item.name}
                                  name="body"
                                  check="true"
                                  value={item.name}
                                  type="radio"
                                  onChange={setValueHere}
                                  id={`inline-height-` + index}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {field === "personality" && (
                      <div className="field-value">
                        <div className="mb-5rem form-check">
                          {myPersonality.map((item, index) => {
                            return (
                              <Form.Check
                                key={item.id}
                                inline
                                label={item.name}
                                name="personality"
                                check="true"
                                value={item.id}
                                type="checkbox"
                                onChange={() => handleChangeMultipleValue(item)}
                                id={`inline-height-` + index}
                                defaultChecked={multipleValue.some(
                                  (value) => value.id === item.id
                                )}
                              />
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </div>

                  {(field === "residence" ||
                    field === "figure" ||
                    field === "birthplace" ||
                    field === "educational_background" ||
                    field === "annual_income" ||
                    field === "baby_aspiration" ||
                    field === "date" ||
                    field === "cigarette" ||
                    field === "alcohol" ||
                    field === "marriage" ||
                    field === "occupation") && (
                    <div className="field-value">
                      {["radio"].map((type) => {
                        const t =
                          field === "figure" ? "my_figures" : field + "s"
                        // 首藤さんの依頼　３つまで表示される。
                        field === "baby_aspiration" &&
                          userOption[t].splice(3, 2)
                        return (
                          <div
                            key={`inline-${type}`}
                            className="mb-5rem form-check"
                          >
                            {userOption[t].map((item, index) => (
                              <Form.Check
                                key={index}
                                inline
                                label={item.name}
                                name={field}
                                check="true"
                                value={item[field + "_id"]}
                                type={type}
                                onChange={setValueHere}
                                id={`inline-${type}-` + index}
                                defaultChecked={
                                  user[field + "_id"] == item[field + "_id"]
                                    ? true
                                    : false
                                }
                              />
                            ))}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
            </Container>
            <Container className="fixed-bottom">
              <div className="d-grid position-relative py-3 bg-white">
                <Button
                  className="align-self-center"
                  variant="danger"
                  type="button"
                  onClick={() => handleFinish()}
                >
                  {textButton}
                </Button>
              </div>
            </Container>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default EditItem
