import _ from "lodash"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Form from "react-bootstrap/Form"
import { useSelector } from "react-redux"
import Button from "react-bootstrap/Button"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"

import "./styles.scss"
import {
  getShowMatchConfirmation,
  setShowMatchConfirmation
} from "../../utils/persist"
import { getAvatarUrl } from "../../utils"
import routes from "../../constants/routes"
import Layout from "../../components/Layout"
import DefaultAvatar from "../../images/avatar-app.png"

const Match = () => {
  const history = useHistory()

  const auth = useSelector((state) => {
    return state.auth.profile
  })

  const user = useSelector((state) => {
    return state.user.detail
  })

  const handleConfirm = () => {
    if (user.user_id) {
      history.push(`/private-chat/${user.user_id}/`)
    } else if (user.id) {
      history.push(`/private-chat/${user.id}/`)
    }
  }

  const handleBack = () => {
    history.goBack()
  }

  const persist = getShowMatchConfirmation()
  const persistUid = _.get(persist, "uid", false)
  const persistFlag = _.get(persist, "flag", false)

  const [showConfirm, setShowConfirm] = useState(
    persistUid == auth.user_id ? persistFlag : false
  )

  const handleFlagChange = () => {
    setShowConfirm(!showConfirm)
    setShowMatchConfirmation({ uid: auth.user_id, flag: !showConfirm })
  }

  useEffect(() => {
    if (_.isEmpty(user)) {
      history.push(routes.USERS)
    }
  }, [])

  const myAvatar = auth ? getAvatarUrl(auth.images) : null
  const partnerAvatar = user
    ? user.images
      ? getAvatarUrl(user.images)
      : user.image_url
    : null

  return (
    <Layout>
      <div className="container">
        <div className="page d-flex min-vh-100 justify-content-center">
          <div className="align-self-center">
            <div className="match-body">
              <h1 className="match-title">Match</h1>
              <h2 className="h4 fw-bold">おめでとうございます！</h2>
              <p>
                <span className="match-nickname text-truncate">
                  {user.nickname}
                </span>
                <span>さんとマッチしました！</span>
              </p>
              <div className="px-5 pb-4">
                <Row>
                  <Col xs={5}>
                    <div className="field field-avatar ratio ratio-1x1 rounded">
                      {myAvatar && (
                        <img
                          className="rounded"
                          src={myAvatar}
                          alt="My avatar"
                        />
                      )}
                    </div>
                  </Col>
                  <Col xs={2}>
                    <div className="d-flex h-100 justify-content-center">
                      <div className="align-self-center">
                        <FontAwesomeIcon color="red" icon={faHeart} size="lg" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={5}>
                    <div className="field field-avatar ratio ratio-1x1 rounded">
                      <img
                        className="rounded"
                        src={partnerAvatar ? partnerAvatar : DefaultAvatar}
                        alt="Partner avatar"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="d-grid gap-2">
                <Button variant="danger" type="button" onClick={handleConfirm}>
                  メッセージを送る
                </Button>
                <Button
                  variant="outline-danger"
                  type="button"
                  onClick={handleBack}
                >
                  あとで送る
                </Button>
                <Form.Check
                  className="checkbox"
                  type="checkbox"
                  label="今後表示しない"
                  checked={showConfirm}
                  onChange={handleFlagChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Match
