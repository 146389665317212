import _ from "lodash"
import { useDispatch } from "react-redux"
import Modal from "react-bootstrap/Modal"
import PropTypes from 'prop-types';
import Filter from "./Filter"
import { useRef } from "react"
import { scheduleListRequested } from "../../../actions/schedule"

import "./styles.scss"

const FilterSchedule = ({ openFilterSchedule, onCloseFilterSchedule }) => {
  const dispatch = useDispatch()
  const ageRef = useRef({ valueAge: 0, valueTime: 0, valueDay: 0})
  const handleSubmit = (values) => {
    ageRef.current = {
      valueAge: parseInt(values.age_type),
      valueTime: parseInt(values.date_type),
      valueDay: parseInt(values.created_at_type)
    }
    dispatch(scheduleListRequested({ params:
      {
        date_type: values.date_type,
        age_type: values.age_type,
        created_at_type: values.created_at_type,
      }
    }))
    onCloseFilterSchedule()
  }

  return (
    <Modal show={openFilterSchedule} centered onHide={onCloseFilterSchedule} className="dialog-search-schedule">
      <Modal.Header closeButton style={{ borderBottom: 'none' }}>
        <Modal.Title>検索条件</Modal.Title>
      </Modal.Header>
      <Filter onSubmit={handleSubmit} props={ageRef.current} />
    </Modal>
  )
}

FilterSchedule.propTypes = {
  openFilterSchedule: PropTypes.bool,
  onCloseFilterSchedule: PropTypes.func
};

export default FilterSchedule;
