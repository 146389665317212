import React, { useEffect, useState } from "react"
import moment from 'moment';

export const PointItem = (props) => {
  const { point } = props

  const toStringNumber = (num) => {
    if (num == null || num == '') {
      return '0'
    }
    var nums = (num + '').split('.');
    return (Math.round(nums[0]) + "").replace(/(\d)(?=(\d{3})+$)/g, "$1,") + (nums.length > 1 && nums[1] != '0' ? ('.' + nums[1]) : '')
  }

  let title = "";
  let prefix = "";
  if (point.deposit_reason === 1) title = "ポイント購入";
  else if (point.deposit_reason === 2) title = "ビデオ通話延長";
  else if (point.deposit_reason === 3) title = "投げ銭";
  else if (point.withdrawal_reason === 1) {
    title = "いいねに交換";
    prefix = "-"
  }
  else if (point.withdrawal_reason === 2) {
    title = "ビデオ通話延長";
    prefix = "-"
  }
  else if (point.withdrawal_reason === 3) {
    title = "ギフトと交換";
    prefix = "-"
  }
  else if (point.withdrawal_reason === 4) {
    title = "出金";
    prefix = "-"
  }
  const time = moment(point.transacted_at).format("HH:mm")
  const date = moment(point.transacted_at).year() + "年" + moment(point.transacted_at).format("MM") + "月" + moment(point.transacted_at).format("DD") + "日"

  return (
    <div className="point-item">
      <div className="title">
        <h1>{title}</h1>
        <p>{date}　{time}</p>
      </div>
      <h4>{prefix}{toStringNumber(point.points)}</h4>
    </div>
  )
}
