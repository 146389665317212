// import HeathGray from '../../../assets/svgs/heathGray.svg';
// import HeathRed from '../../../assets/svgs/heathRed.svg';

import { Image, Row, Col } from "react-bootstrap"
import { StatusDot } from "../../../../components/StatusDot"
import { getLikeTime } from "../../../../utils"
import DefaultAvatar from "../../../../images/avatar-app.png"
import HeartRed from "../../../../images/red-heart.svg"
import HeartGray from "../../../../icons/heartGray.svg"
import "../styles.scss"

const windowWidth = window.innerWidth

const Item = ({ user, onClick }) => {
  const dateTimeStr = user.footprint_at
  let time = null
  let date = null
  if (dateTimeStr) {
    const t = getLikeTime(dateTimeStr)
    time = t.time
    date = t.date
  }

  return (
    <div
      className="px-4 yu-gothic"
      onClick={() => onClick(user.user_id)}
    >
      <Row className="mb-2 pb-1 border-bottom border-1">
        <Col
          xs={3}
          md={3}
          className="d-flex justify-content-center align-items-center p-0"
        >
          <Image
            className="img m-1"
            height={(windowWidth * 80) / 360}
            width={(windowWidth * 80) / 360}
            src={user.image_url || DefaultAvatar}
            rounded
          />
        </Col>
        <Col
          xs={9}
          md={9}
          className="px-2 py-1 d-flex flex-column justify-content-between"
        >
          <Row>
            <Col>
              <Row>
                <Col className="d-flex justify-content-between">
                  <div className="name overflow d-flex align-items-center">
                    {user.nickname && (
                      <span className="overflow yu-gothic-bold">
                        {`${user.nickname} `}
                      </span>
                    )}

                    {user.residence && user.residence.length > 0 && (
                      <span className="overflow residence yu-gothic-bold">
                        {`${user.residence}`}
                      </span>
                    )}

                    {user.login_status === 3 && <StatusDot color={"#4cc6ab"} />}
                    {user.login_status === 1 && <StatusDot color={"#FF7018"} />}
                    {user.login_status === 2 && <StatusDot color={"#a8a8ab"} />}
                  </div>
                    <div className="d-flex yu-gothic-regular text-end">
                      {time + ' ' + date}
                    </div>
                </Col>
              </Row>
              <Row>
                <Col className="overflow yu-gothic-regular">
                  {`${user.age + "歳" || ""}　${user.occupation_name || ""}`}
                </Col>
              </Row>
              <Row>
                <div className="d-flex justify-content-between row-with-heart">
                  <p className="overflow small-text align-items-center comment">
                    {user.comment}
                  </p>
                  <div className="d-flex justify-content-center align-items-center heart-icon-border rounded-circle border">
                    {user.is_liked === 1 && (
                      <Image src={HeartRed} width={20} height={20} />
                    )}
                    {user.is_liked === 0 && (
                      <Image src={HeartGray} width={20} height={20} />
                    )}
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Item
