import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import Layout from "../../components/Layout"

import "./style.scss"
import { AppBar } from "../../components"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const PaymentComplete = () => {
  const query = useQuery()
  const history = useHistory()

  const [params, setParrams] = useState({})
  window.parent.postMessage("hidden-close-button")
  useEffect(() => {
    // if (!inIframe()) {
    //   window.location = "/profile"
    // }
    const inputParams = {}
    for (const [key, value] of query) {
      inputParams[key] = params[key] || value
    }
    setParrams(inputParams)
  }, [])

  const onFinish = (e) => {
    history.push("/profile")
  }

  const inIframe = () => {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }

  return (
    <Layout>
      <AppBar title="購入完了" />
      <div className="bg-white d-flex flex-column payment-complete-page full-height-with-navbar">
        <div className="container pt-2">
          {params["rel"] && params["rel"] == "no" ? (
            <p>
              決済が失敗しました。
              <br />
              カード会社、もしくはCredixまでお問い合わせください。
            </p>
          ) : (
            <div>
              <div className="payment-complete-page-icon d-flex justify-content-center align-items-center">
                <FontAwesomeIcon size="3x" color="white" icon={faCheck} />
              </div>
              <p className="payment-complete-page-text">購入完了しました</p>
            </div>
          )}
          <button type="button" className="btn-finish" onClick={onFinish}>
            戻る
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default PaymentComplete
