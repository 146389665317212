import { useState, useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import "./style.scss"
import AppContext from "../../Context"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import { TopOfPage } from "./components/top"
import { PanelMenu } from "./components/panel-menu"
import { fetchAuthProfileRequest } from "../../actions/auth"
import { getFilterOptionsRequest } from "../../actions/filter"
import { LoadingIndicator } from "../../components/LoadingIndicator"

const MyPage = () => {
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)
  const [bannerHeight, setBannerHeight] = useState(0)

  const me = useSelector((state) => {
    return state.auth.profile
  })
  const isLoading = useSelector((state) => {
    return state.auth.loadingProfile
  })
  const options = useSelector((state) => {
    return state.filter.options
  })

  useEffect(() => {
    dispatch(
      fetchAuthProfileRequest(
        {},
        {
          onSuccess: (response) => {
            localStorage.setItem("profileEdit", JSON.stringify(response))
            setContext("overlay", null)
          },
          onFailed: () => {
            setContext("overlay", null)
          }
        }
      )
    )
    dispatch(
      getFilterOptionsRequest(
        {},
        {
          onSuccess: (response) => {
            localStorage.setItem("profileEditOption", JSON.stringify(response))
            setContext("overlay", null)
          },
          onFailed: () => {
            setContext("overlay", null)
          }
        }
      )
    )
    localStorage.removeItem("curent_scroll")
  }, [])

  return (
    <Layout nav>
      <AppBar title="マイページ" banner titleLeft setBannerHeight={setBannerHeight} />
      <div
        className="bg-white d-flex flex-column wrap-profile max-w-screen-xl"
        style={{ 
          top: `${bannerHeight + 90}px`, 
          height: `calc(100vh - (80px + ${bannerHeight}px))` 
        }}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <div className="my-page">
            <TopOfPage me={me} options={options} />
            <PanelMenu me={me} options={options} />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default MyPage
