import { useHistory } from "react-router-dom"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState, useContext } from "react"
import Item from "./Components/Item"
import "./styles.scss"
import _ from "lodash"
import { getHideListRequested } from "../../../actions/message"
import { ErrorModal } from "../../../components/ErrorModal"
import AppContext from "../../../Context"

const SkipUsers = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [errorModal, setErrorModal] = useState(false)
  const [listUser, setListUser] = useState([])
  const [countPage, setCountPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [loading, setLoading] = useState(true)
  const perpage = 5

  const { setContext } = useContext(AppContext)

  const error = useSelector((state) => state.likes.error)

  const currentUserId = useSelector((state) => {
    return state.auth.profile.user_id
  })

  const hideList = useSelector((state) => {
    return state.message.hideList
  })

  useEffect(() => {
    dispatch(getHideListRequested(currentUserId, dispatch))
  }, [currentUserId])

  return (
    <Layout>
      <AppBar title="非表示リスト" withBack />
      <div className="bg-white d-flex flex-column px-3 wrap-child-setting">
        {!_.isEmpty(hideList) ? (
          <div className="users">
            {hideList?.map((user, index) => (
              <Item user={user} key={index} />
            ))}
          </div>
        ) : (
          <div className="h-100 w-75 d-flex justify-content-center mx-auto align-items-center text-center">
            あなたが非表示にしたユーザーはまだいません。
          </div>
        )}
        <ErrorModal
          show={errorModal}
          content={error}
          onClose={() => setErrorModal(false)}
        />
      </div>
    </Layout>
  )
}

export default SkipUsers
