import { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Layout, AppBar, LoadingIndicator } from "../../components"
import PhoneNumberInput from "./PhoneNumberInput"
import AppContext from "../../Context"
import {
  NATIONAL_CODE,
  PHONE_NUMBER_REGEX,
  PHONE_NUMBER_LENGTH,
  PHONE_NUMBER_VN_LENGTH
} from "../../constants/env"
import messages from "../../constants/messages"
import routes from "../../constants/routes"
import {
  checkPhoneNumberRequest,
  checkPhoneNumberSuccess,
  updatePhoneNumber,
  upsertAuthProfile
} from "../../actions"
import _ from "lodash"
import { runSignupTrackingScript } from "./signupTrackingScript"

const Signup = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    runSignupTrackingScript();
  }, []);

  const { setContext } = useContext(AppContext)

  const phone = useSelector((state) => {
    return state.signup.phone.replace(NATIONAL_CODE, "0")
  })

  const handleSignup = (formData) => {
    if (_.isEmpty(formData.phone)) {
      alert(messages.emptyPhoneNumber)
      setContext("overlay", null)
      return
    }

    formData.phone = formData.phone.replaceAll(/ /g, "")

    setContext("overlay", <LoadingIndicator />)
    const data = {
      phone:
        formData.phone?.startsWith("+1101100") ||
        formData.phone?.startsWith("+81") ||
        formData.phone?.startsWith("+84")
          ? formData.phone
          : NATIONAL_CODE + formData.phone?.substring(1)
    }

    if (
      !data.phone.startsWith("+1101100") &&
      (!PHONE_NUMBER_REGEX.test(data.phone) ||
        (data.phone.toString().length != PHONE_NUMBER_LENGTH &&
          data.phone.toString().length != PHONE_NUMBER_VN_LENGTH))
    ) {
      alert(messages.wrongPhoneNumber)
      setContext("overlay", null)
      return
    }

    dispatch(
      checkPhoneNumberRequest(data, {
        onSuccess: (status) => {
          if (status.isExisted === true) {
            alert(messages.phoneRegistered)
            setContext("overlay", null)
          } else {
            dispatch(checkPhoneNumberSuccess(data))
            dispatch(
              upsertAuthProfile({ phone: data.phone, phoneVerified: false })
            )
            if (data.phone.startsWith("+1101100")) {
              dispatch(upsertAuthProfile({ phoneVerified: true }))
              dispatch(updatePhoneNumber(""))
              localStorage.setItem("registing", 1)
              setTimeout(() => {
                setContext("overlay", null)
                history.push(routes.REGISTER_PROFILE)
              }, 100)
            } else {
              setTimeout(() => {
                setContext("overlay", null)
                history.push(routes.REGISTER_VERIFY_PHONE)
              }, 400)
            }
          }
        },
        onFailed: (status) => {
          setContext("overlay", null)
          alert(messages.somethingWrong)
        }
      })
    )
  }
  return (
    <Layout height={false}>
      <AppBar withBack title="新規登録" colorTitle="red" />
      <div className="container">
        <PhoneNumberInput onSignup={handleSignup} phone={phone} />
      </div>
    </Layout>
  )
}

export default Signup
