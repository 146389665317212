import _ from "lodash"
import queryString from "querystring"
import { useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import PropTypes from 'prop-types';
import Filter from "./Filter"
import routes from "../../../constants/routes"

import "./styles.scss"

const FilterChat = ({ openFilterChat, onCloseFilterChat, parentCallback }) => {
  const history = useHistory()
  const filterChatRef = useRef({ label: false, read: false, unread: false, reply: false, valueKeyword: ''})

  const handleSubmit = (values) => {
    filterChatRef.current = {
      label: values.label,
      read: values.read,
      unread: values.unread,
      reply: values.reply,
      valueKeyword: values.valueKeyword
    }
    onCloseFilterChat()
    history.push({
      pathname: routes.CHAT,
      // search: `?${queryString.stringify(values)}`,
      state: { values }
    })
    parentCallback(filterChatRef.current)
  }

  return (
    // <Layout>
    //   <AppBar title="絞り込み検索" withBack />
    <Modal show={openFilterChat} centered onHide={onCloseFilterChat} className="modal-search-chat" backdrop="static">
      <Modal.Header closeButton style={{ borderBottom: 'none' }}>
        <Modal.Title>検索条件</Modal.Title>
      </Modal.Header>
      <Filter onSubmit={handleSubmit} props={filterChatRef.current} />
    </Modal>
  )
}

FilterChat.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default FilterChat;
