import _ from "lodash"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { useDispatch, useSelector } from "react-redux"
import { useState, useContext } from "react"
import { Image } from "react-bootstrap"
import { VerifySuccess, VerifyLoading } from "./components/verifyStatus"
import VerifyCard from "../../../images/cmnd.png"
import CheckIcon from "../../../icons/check.svg"
import AppContext from "../../../Context"
import "./styles.scss"
import { TakePhoto } from "./components/selectPopup"
import { updateUserProfileRequest } from "../../../actions/auth"
import messages from "../../../constants/messages"
import { LoadingIndicator } from "../../../components/LoadingIndicator"

const VerifyId = () => {
  const dispatch = useDispatch()

  const { setContext } = useContext(AppContext)

  const profileUserData = useSelector((state) => state.auth.profile)
  const verifyStatus = profileUserData?.identity_verification_status

  const handleVerify = (imageData) => {
    setContext("overlay", <LoadingIndicator />)

    const form = new FormData()
    form.append("images[" + 0 + "][image]", imageData.imageFile)
    form.append("images[" + 0 + "][type]", 3)
    form.append("images[" + 0 + "][display_order]", 8)

    dispatch(
      updateUserProfileRequest(form, {
        onSuccess: () => {
          setContext("overlay", null)
          window.location.reload()
        },
        onFailed: (error) => {
          setContext("overlay", null)
          alert(_.get(error, "data.error.message", messages.somethingWrong))
        }
      })
    )
  }

  return (
    <Layout>
      <AppBar title="年齢確認" withBack />
      <div className="pt-2 bg-white d-flex flex-column full-height-with-navbar verify">
        {!_.isEmpty(profileUserData) ? (
          verifyStatus === 3 ? (
            <VerifySuccess />
          ) : verifyStatus === 1 ? (
            <VerifyLoading />
          ) : (
            <>
              <div className="d-flex flex-column align-items-center p-3 ">
                <h6 className="yu-gothic-bold text-secondary text-center small-text">
                  Tutuでは関連法規に基づき、他の会員様とのメッセージのやりとりには、年齢確認を必須としています。
                  <br />
                </h6>
                <h6 className="text-danger small-text yu-gothic-bold mt-1 mb-2 text-center">
                  年齢確認に必要な書類をご用意ください。
                  <br />
                  以下のどれか一点のみ。
                </h6>
                <Image src={VerifyCard} width={320} />
                <div className="d-flex mt-2 align-items-start">
                  <Image src={CheckIcon} width={30} className="mx-2" />
                  <p className="small-text align-items-center yu-gothic-bold">
                    確認書類の端を画面に表示される枠線に合わせて、撮影してください。
                  </p>
                </div>
                <div className="d-flex mt-2 align-items-start">
                  <Image src={CheckIcon} width={30} className="mx-2" />
                  <p className="small-text yu-gothic-bold">
                    以下の４つの確認項目がはっきりと見えるように撮影してください。
                    <br />
                    ・氏名
                    <br />
                    ・生年月日
                    <br />
                    ・発行元の名称
                    <br />
                    ・書面の書類
                    <br />
                  </p>
                </div>
                <TakePhoto onSend={handleVerify} />
                <p className="text-small p-3 text-secondary">
                  ※
                  保険証のみ、撮影前に付箋やテープなどで記号と番号、保険者番号、QRコードは隠してください。当該箇所が隠れていない場合、再撮影となる可能性があります。
                  <br />
                  <br />
                  ※
                  ご登録いただいた生年月日と提出された書類の生年月日が一致しない場合は承認されず、再撮影となりますので、ご注意ください。
                  <br />
                  <br />
                  ※ ご提出いただいた画像は年齢確認以外では一切使用しません。
                  <br />
                  <br />
                </p>
              </div>
            </>
          )
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </Layout>
  )
}

export default VerifyId
