import _ from "lodash"
import { Fragment, useState, useRef, useCallback } from "react"
import classNames from "classnames"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import AppContext from "../../Context"
import { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import "./styles.scss"
import { connectStorageEmulator } from "@firebase/storage"
const limit = 6

const CropImages = () => {
  let user = JSON.parse(localStorage.getItem("profileEdit"))
  // let userOption = JSON.parse(localStorage.getItem('profileEditOption'));
  const { id } = useParams()
  let setImg

  if ("images_change" in user) {
    setImg = user.images_change[id]
  }

  const [upImg, setUpImg] = useState(setImg.src)
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 16 / 16 })
  const [completedCrop, setCompletedCrop] = useState(null)
  let imgCrop = ""

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener("load", () => setUpImg(reader.result))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext("2d")
    const pixelRatio = window.devicePixelRatio

    canvas.width = crop.width * pixelRatio * scaleX
    canvas.height = crop.height * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = "high"

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )
    imgCrop = canvas.toDataURL("image/jpeg")
  }, [completedCrop])

  const handleCancel = () => {
    window.location.href = "/media"
  }

  const handleComplete = () => {
    user.images_change[id].src = imgCrop
    localStorage.setItem("profileEdit", JSON.stringify(user))
    window.location.href = "/media"
  }

  return (
    <Layout>
      <AppBar title="画像を切り抜く" />

      <div className="bg-white d-flex flex-column full-height-with-navbar user-review user-review-fix">
        {user && (
          <div className="user-detail">
            <div className="user-fields">
              {/* <div className="mt-3">
                    <input type="file" accept="image/*" onChange={onSelectFile} />
                  </div> */}
              <ReactCrop
                src={upImg}
                imageStyle={{ width: window.outerWidth }}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
              />
              <div className="d-none">
                <canvas
                  ref={previewCanvasRef}
                  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                  style={{
                    width: Math.round(completedCrop?.width ?? 0),
                    height: Math.round(completedCrop?.height ?? 0)
                  }}
                />
              </div>
            </div>

            <Container className="fixed-bottom">
              <div className="d-grid position-relative py-3 bg-white">
                <div className="row">
                  <div className="col-6">
                    <Button
                      className="align-self-center col-12"
                      variant="secondary"
                      type="button"
                      onClick={() => handleCancel()}
                    >
                      戻る
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      className="align-self-center col-12"
                      variant="danger"
                      type="button"
                      onClick={() => handleComplete()}
                    >
                      完了
                    </Button>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default CropImages
