import { useState, useContext } from "react"
import _ from 'lodash'
import { useHistory } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { Navbar, Container, Image, Modal } from "react-bootstrap"
import IconMenu from "../../../icons/ic_dot.png"
import DefaultAvatar from "../../../images/avatar-app.png"
import routes from "../../../constants/routes"
import { VideoCallConfirmModal } from "./VideoCallConfirmModal"
import {
  fetchAuthProfileRequest,
  labelUserRequested,
  onHideUser,
  fetchNotiMessageRequested,
  pinUserRequested,
  onDataReport
} from "../../../actions"
import AppContext from "../../../Context"
import { LoadingIndicator } from "../../../components/LoadingIndicator"
import { ErrorModal } from "../../../components/ErrorModal"
import { ReportModal } from "../../../components/ReportModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt, faSearch, faChevronLeft, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { StatusDot } from "../../../components/StatusDot"
import { USER_STATUS, COUNT_PINNED_MAX } from "../../../constants/env"
import { getUserStatus, calculateAge } from "../../../utils"
import IconTag from "../../../icons/tag.png"
import { toast } from "react-toastify"
import Form from "react-bootstrap/Form"
import { useLocation } from "react-router-dom";
import { useEffect } from "react"

import "./styles.scss"

export const ChatNavBar = ({
  user,
  goBack,
  showVideoCall,
  onHide,
  onStartVideoCall,
  messageInfo,
  countPinned,
  onSearch,
}) => {
  const history = useHistory()
  const location = useLocation();
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)
  const status = user.last_login
    ? getUserStatus(user.last_login)
    : USER_STATUS.OFFLINE

  const [modal, setModal] = useState(false)
  const [videoCallConfirmModal, setVideoCallConfirmModal] = useState(false)
  const [errorModal, setErrorModal] = useState({ visible: false })
  const currentUserId = useSelector((state) => state.auth.profile.user_id)
  const [inputSearch, setInputSearch] = useState(false)
  const [textSearch, setTextSearch] = useState({ value: '' })
  const [reportModal, setReportModal] = useState(false)

  const startVideoCall = () => {
    setVideoCallConfirmModal(false)
    setContext("overlay", <LoadingIndicator />)
    dispatch(
      fetchAuthProfileRequest(
        {},
        {
          onSuccess: (data) => {
            setContext("overlay", null)
            onStartVideoCall()
          },
          onFailed: (err) => {
            setContext("overlay", null)
          }
        }
      )
    )
  }

  useEffect(() => {
    let UrlParams = new URLSearchParams(location.search)
    var text = UrlParams.get('textSearch')
    var countRelatedMessage = UrlParams.get('countRelatedMessage')
    if(text && countRelatedMessage > 0) {
      setInputSearch(true)
      handleSearch(text)
    }
    if (UrlParams.has('textSearch')) {
      UrlParams.delete('textSearch')
      history.replace({
        search: UrlParams.toString(),
      })
    }
    if (UrlParams.has('countRelatedMessage')) {
      UrlParams.delete('countRelatedMessage')
      history.replace({
        search: UrlParams.toString(),
      })
    }
  }, [location]);

  const onLabel = (toUserId, isLabel) => {
    const data = {
      toUserId: toUserId,
      isLabel: isLabel
    }

    dispatch(
      labelUserRequested(data, {
        onSuccess: () => {
          toast("更新されました")
          setModal(false)
          history.push(routes.CHAT)
        },
        onFailed: () => {
          setContext("overlay", null)
          setModal(false)
          history.push(routes.CHAT)
        }
      })
    )
  }

  const onHideUserChat = () => {
    dispatch(
      onHideUser({ likeID: user?.likeId, isHide: true } , {
        onSuccess: (response) => {
          toast("非表示にしました")
          setModal(false)
        },
        onFailed: () => {
          setContext("overlay", null)
          setModal(false)
        }
      })
    )
  }

  const onPushNotification = (isOffnotificationChat) => {
    const data = {
      likeID: messageInfo?.likeId,
      fromUserId: currentUserId,
      isOffnotificationChat: !isOffnotificationChat,
    }
    dispatch(
      fetchNotiMessageRequested(data, {
        onSuccess: (response) => {
          if(isOffnotificationChat) {
            toast('通知をオンしました')
          } else {
            toast('通知をオフしました')
          }
          setContext("overlay", null)
          setModal(false)
        },
        onFailed: (response) => {
          toast(response?.message)
          setContext("overlay", null)
          setModal(false)
        }
      })
    )
  }

  const onReport = () => {
    setContext("overlay", <LoadingIndicator />)
    dispatch(
      onDataReport(user.user_id, {
        onSuccess: () => {
          setContext("overlay", null)
          setReportModal(true)
          setModal(false)
        },
        onFailed: (err) => {
          toast(err?.message)
          setContext("overlay", null)
          setModal(false)
        }
      })
    )
  }

  const onPin = (isPinned) => {
    if(countPinned >= COUNT_PINNED_MAX && !isPinned) {
      toast('１０個まで')
      setModal(false)
      return;
    }
    const data = {
      likeID: user?.likeId,
      fromUserId: currentUserId,
      isPinned: !isPinned
    }

    dispatch(
      pinUserRequested(data, {
        onSuccess: (response) => {
          toast(response.message)
          setContext("overlay", null)
          setModal(false)
        },
        onFailed: (response) => {
          toast(response.message)
          setContext("overlay", null)
          setModal(false)
        }
      })
    )
  }

  const renderActionModal = () => (
    <Modal
      show={modal}
      onHide={handleCloseDialog}
      centered
      dialogClassName="w-90 mx-auto modal-list-chat"
      user={user}
    >
      <Modal.Body>
        <h6 className="text-center pt-2 pb-2 yu-gothic-bold h4">
          {user?.nickname || ""}
          ({user.date_of_birth
            ? `${calculateAge(user.date_of_birth)}歳`
            : `${user?.age}歳`}
          )
        </h6>
        <button
          className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
          onClick={() => {
            onPushNotification(messageInfo?.isOffnotificationChat)
          }}
        >
          {messageInfo?.isOffnotificationChat ? "通知オン" : "通知オフ"}
        </button>
        <button
          className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
          disabled={countPinned >= COUNT_PINNED_MAX && !messageInfo.is_pinned}
          onClick={() => {
            onPin(_.has(messageInfo, 'is_pinned') ? messageInfo.is_pinned : false)
          }}
        >
          {messageInfo?.is_pinned ? 'ピン外す' : countPinned >= COUNT_PINNED_MAX && !messageInfo.is_pinned ? 'ピン留め（１０個まで）' : 'ピン留め'}
        </button>
        <button
          className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
          onClick={() => {
            onLabel(messageInfo.id || messageInfo.user_id, messageInfo?.is_label)
            handleCloseDialog()
          }}
        >
          {messageInfo?.is_label ? "ラベルを削除する" : "ラベル"}
        </button>
        <button
          className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
          onClick={onHideUserChat}
        >
          一覧から非表示
        </button>
        <button
          className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
          onClick={onHide}
        >
          ブロック
        </button>
        <button
          className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
          onClick={onReport}
        >
          通報する
        </button>
        <button
          className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
          onClick={handleCloseDialog}
        >
          キャンセル
        </button>
      </Modal.Body>
    </Modal>
  )

  const handleAvatar = () => {
    history.push(`${routes.USERS}/${user.user_id}`)
  }

  const handleCloseDialog = () => {
    setModal(false)
  }

  const handleSearch = (value) => {
    setTextSearch({ ...textSearch, value: value })
    onSearch(value)
  }

  const handleInputSearch = () => {
    setInputSearch(!inputSearch)
    handleSearch('')
  }

  return (
    <>
      <Navbar className="nav-detail border-bottom">
        <Container fluid className="py-2">
          <div style={{ marginRight: "1rem" }}>
            <FontAwesomeIcon
              // className="text-danger"
              icon={faChevronLeft}
              size="md"
              onClick={goBack}
            />
          </div>
          <div className="nav-stretch" style={{ overflow: "hidden" }}>
            <div className="d-flex align-items-center position-relative">
              <StatusDot
                color={
                  status === 3 ? "#38C638" : status === 1 ? "#FF7018" : "#939393"
                }
                left='32px'
              />
              <Image
                className="avatar-container"
                src={user.avatarUrl || DefaultAvatar}
                fluid
                onClick={handleAvatar}
              />
              <span className="one-line-text name yu-gothic-bold">
                {user.nickname} ({user.age})
                {messageInfo?.is_label && (
                  <img src={IconTag} width={18} alt="Icon tag" />
                )}
              </span>
            </div>
          </div>
          <div style={{ minWidth: "max-content", marginLeft: "1rem" }}>
            <FontAwesomeIcon
              // className="text-danger"
              icon={faSearch}
              size="lg"
              style={{ marginRight: '12px' }}
              onClick={() => handleInputSearch()}
            />
            {showVideoCall && (
              <FontAwesomeIcon
                // className="text-danger"
                icon={faPhoneAlt}
                size="lg"
                style={{ marginRight: '8px' }}
                onClick={() => setVideoCallConfirmModal(true)}
              />
            )}
            <Image src={IconMenu} fluid style={{ transform: "rotate(90deg)", height: 4 }} onClick={() => setModal(true)} />
          </div>
          {
            inputSearch && (
              <input
                type="search"
                className="input-search-text form-control"
                onChange={(value) => handleSearch(value.target.value)}
                value={textSearch.value}
              />
            )
          }
        </Container>
      </Navbar>
      {modal && renderActionModal()}
      {videoCallConfirmModal && (
        <VideoCallConfirmModal
          onClose={() => setVideoCallConfirmModal(false)}
          onConfirm={startVideoCall}
        />
      )}
      {errorModal.visible && (
        <ErrorModal
          show={true}
          content={errorModal.content}
          onClose={() => setErrorModal({ visible: false })}
        />
      )}
      <ReportModal
        show={reportModal}
        onClose={() => setReportModal(false)}
      />
    </>
  )
}
