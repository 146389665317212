import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import PhoneForm from "../../components/Form/Phone"
import { LoadingIndicator } from "../../components/LoadingIndicator"
import AppContext from "../../Context"
import {
  NATIONAL_CODE,
  PHONE_NUMBER_REGEX,
  PHONE_NUMBER_LENGTH,
  PHONE_NUMBER_VN_LENGTH,
  USER_TYPE
} from "../../constants/env"
import {
  checkPhoneNumberRequest,
  checkPhoneNumberSuccess,
  updatePhoneNumber
} from "../../actions/signup"
import {
  upsertAuthProfile,
  loginWithPhoneRequest,
  fetchAuthProfileRequest,
  showPopupAfterLogin,
  addDeviceTokenRequested
} from "../../actions/auth"
import messages from "../../constants/messages"
import routes from "../../constants/routes"
import { getAccessToken } from "../../utils/auth"
import _ from "lodash"

const Login = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [password, setPassword] = useState("")

  const { setContext } = useContext(AppContext)

  const phone = useSelector((state) => {
    return state.signup.phone.replace(NATIONAL_CODE, "0")
  })

  useEffect(() => {
    const accessToken = getAccessToken()
    if (accessToken) {
      history.push("/")
    }
  }, [])

  const handleSignup = (formData) => {
    if (_.isEmpty(formData.phone)) {
      alert(messages.emptyPhoneNumber)
      setContext("overlay", null)
      return
    }

    formData.phone = formData.phone.replaceAll(/ /g, "")

    setContext("overlay", <LoadingIndicator />)
    const data = {
      phone:
        formData.phone?.startsWith("+1101100") ||
        formData.phone?.startsWith("+81") ||
        formData.phone?.startsWith("+84")
          ? formData.phone
          : NATIONAL_CODE + formData.phone?.substring(1)
    }

    if (
      !data.phone.startsWith("+1101100") &&
      (!PHONE_NUMBER_REGEX.test(data.phone) ||
        (data.phone.toString().length != PHONE_NUMBER_LENGTH &&
          data.phone.toString().length != PHONE_NUMBER_VN_LENGTH))
    ) {
      alert(messages.wrongPhoneNumber)
      setContext("overlay", null)
      return
    }

    dispatch(
      checkPhoneNumberRequest(data, {
        onSuccess: (status) => {
          if (!status.isExisted) {
            alert(messages.phoneNotRegistered)
            setContext("overlay", null)
          } else {
            dispatch(checkPhoneNumberSuccess(data))
            // dispatch(upsertAuthProfile({ phone: data.phone, phoneVerified: false }))
            if (data.phone.startsWith("1101100") || status.userType == USER_TYPE.KOL_OR_IDOL) {
              dispatch(
                loginWithPhoneRequest(data.phone, {
                  onSuccess: async () => {
                    setTimeout(() => {
                      dispatch(
                        fetchAuthProfileRequest(
                          {},
                          {
                            onSuccess: async (response) => {
                              dispatch(
                                upsertAuthProfile({ phoneVerified: true })
                              )
                              dispatch(updatePhoneNumber(""))
                              dispatch(showPopupAfterLogin(true))
                              setContext("overlay", null)
                              setTimeout(() => {
                                if (response.is_created == 0) {
                                  history.push({
                                    pathname: routes.REGISTER_PROFILE,
                                    search: "?login=true"
                                  })
                                } else {
                                  dispatch(addDeviceTokenRequested())
                                  history.push({
                                    pathname: routes.USERS,
                                    search: "?login=true"
                                  })
                                }
                              }, 100)
                            },
                            onFailed: () => {
                              setContext("overlay", null)
                              alert(messages.somethingWrong)
                            }
                          }
                        )
                      )
                    }, 100)
                  },
                  onFailed: () => {
                    setContext("overlay", null)
                    alert(messages.registerFail)
                  }
                })
              )
            } else {
              setTimeout(() => {
                setContext("overlay", null)
                history.push(routes.LOGIN_VERIFY_PHONE)
              }, 400)
            }
          }
        },
        onFailed: (status) => {
          setContext("overlay", null)
          alert(messages.somethingWrong)
        }
      })
    )
  }
  return (
    <Layout height={false}>
      <AppBar withBack title="TUTUにログイン" colorTitle="red" />
      <div className="container">
        <PhoneForm onSignup={handleSignup} phone={phone} password={password} />
      </div>
    </Layout>
  )
}

export default Login
