import { useState, useContext } from "react"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import Modal from "react-bootstrap/Modal"
import { useDispatch, useSelector } from "react-redux"
import { profileAction } from "../../../actions/other"
import { updateUserProfileSuccess } from "../../../actions/auth"
import { LoadingIndicator } from "../../../components/LoadingIndicator"
import AppContext from "../../../Context"
import _ from "lodash"

import "./styles.scss"

const LikeExchange = () => {
  const dispatch = useDispatch()

  const profileUserData = useSelector((state) => state.auth.profile)
  const [show, setShow] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [exchangeHeartNum, setExchangeHeartNum] = useState(0)
  const [notificationContent, setContent] = useState("")
  const { setContext } = useContext(AppContext)

  const handleClose = () => {
    setShow(false)
    setExchangeHeartNum(0)
  }

  const handleShow = (number) => {
    if (profileUserData.points_balance < number) {
      setContent("ポイントが不足しています。")
      setShowNotification(true)
    } else {
      setShow(true)
      setExchangeHeartNum(number)
    }
  }

  const handleCloseNotification = () => {
    setShowNotification(false)
  }
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  const handleExchangePoint = () => {
    setShow(false)
    setContext("overlay", <LoadingIndicator />)
    const data = { like_amount: exchangeHeartNum }
    dispatch(
      profileAction(data, {
        onSuccess: (response) => {
          setContent("いいねをポイントと交換しました")
          setContext("overlay", null)
          setShowNotification(true)
          profileUserData.points_balance = response.points_balance
          dispatch(updateUserProfileSuccess(profileUserData))
        },
        onFailed: (error) => {
          setContent("ポイントが不足しています。")
          setShowNotification(true)
          setContext("overlay", null)
        },
      })
    )
  }

  return (
    <Layout>
      <AppBar title="いいね交換" withBack />
      {!_.isEmpty(profileUserData) ? (
        <div className="bg-white d-flex flex-column full-height-with-navbar padding-20 yu-gothic-bold">
          <h6>いいねをポイントと交換できます。</h6>

          <div className="p-3 border btn-without-arrow">
            <div className="float-left-without-icon">ポイント残高</div>
            <div className="float-right content-with-coin">
              {numberWithCommas(Number(profileUserData.points_balance))}
            </div>
          </div>

          <p className="yu-gothic-regular">
            1ポイントを１いいねに交換することができます。
          </p>

          <div
            className="p-3 border btn-with-arrow"
            onClick={() => handleShow(5)}
          >
            <div className="float-left content-with-coin">5</div>
            <div className="float-right content-with-heart m-right-1">5</div>
          </div>

          <div
            className="p-3 border btn-with-arrow"
            onClick={() => handleShow(10)}
          >
            <div className="float-left content-with-coin">10</div>
            <div className="float-right content-with-heart">10</div>
          </div>

          <div
            className="p-3 border btn-with-arrow"
            onClick={() => handleShow(20)}
          >
            <div className="float-left content-with-coin">20</div>
            <div className="float-right content-with-heart">20</div>
          </div>

          <div
            className="p-3 border btn-with-arrow"
            onClick={() => handleShow(30)}
          >
            <div className="float-left content-with-coin">30</div>
            <div className="float-right content-with-heart">30</div>
          </div>

          <div
            className="p-3 border btn-with-arrow"
            onClick={() => handleShow(50)}
          >
            <div className="float-left content-with-coin">50</div>
            <div className="float-right content-with-heart">50</div>
          </div>
        </div>
      ) : (
        <div className="bg-white d-flex flex-column full-height-with-navbar padding-20 yu-gothic-bold">
          <LoadingIndicator />
        </div>
      )}

      <Modal show={show} onHide={handleClose} centered size="sm">
        <Modal.Body>
          <h6 className="text-center">ポイントを交換します</h6>

          <button className="btn w-100 btn-with-arrow">
            <div className="float-left content-with-coin">
              {exchangeHeartNum}
            </div>
            <div className="float-right content-with-heart">
              {exchangeHeartNum}
            </div>
          </button>

          <button
            className="btn text-danger w-100 m-0 rounded-0 confirm-button"
            onClick={handleExchangePoint}
          >
            OK
          </button>

          <button
            className="btn w-100 rounded-0 confirm-button"
            onClick={handleClose}
          >
            キャンセル
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showNotification}
        onHide={handleCloseNotification}
        centered
        size="sm"
      >
        <Modal.Body>
          <h5 className="text-center p-3">{notificationContent}</h5>
          <button
            className="btn text-danger w-100 m-0 rounded-0 confirm-button"
            onClick={handleCloseNotification}
          >
            OK
          </button>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export default LikeExchange
