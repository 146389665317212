import { useHistory, useLocation } from "react-router-dom"
import AppBar from "../../../../components/AppBar"
import Layout from "../../../../components/Layout"
import { LoadingIndicator } from "../../../../components/LoadingIndicator"
import _ from "lodash"
import "../styles.scss"

const WithdrawalConfirm = () => {
  const history = useHistory()
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  const withDrawalPoint = query.get("point")

  const numberWithCommas = (x) => {
		return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	}
  return (
    <Layout>
      <AppBar title="出金履歴" withBack />
      <div
        className="bg-white d-flex flex-column full-height-with-navbar"
      > 
        { withDrawalPoint > 49
            ? (
                <div className="p-3">
                  <div
                    className="border p-3"
                    style={{ borderRadius: "20px" }}
                  >
                    <div
                      className="d-flex yu-gothic-bold justify-content-between h3"
                    >
                      <div>振込金額</div>
                      <div>{`${withDrawalPoint > 0 ?'¥' + numberWithCommas(Number(withDrawalPoint * 100 - 500)) :'-'}`}</div>
                    </div>

                    <div
                      className="d-flex yu-gothic-bold justify-content-between text-secondary mt-3"
                    >
                      <div>出金金額</div>
                      <div>{`${withDrawalPoint > 0 ?'¥' + numberWithCommas(Number(withDrawalPoint * 100)) :'-'}`}</div>
                    </div>

                    <div
                      className="d-flex yu-gothic-bold justify-content-between text-secondary mt-3"
                    >
                      <div>振込手数料</div>
                      <div>{`${withDrawalPoint > 0 ?'-¥500' :'-'}`}</div>
                    </div>
                  </div>
                  <br/>
                  <h6 className="text-center">
                    上記の内容で出金申請を承りました。<br/>
                    振込みまでしばらくお待ちください。
                  </h6>
                  <br/>
                  <button
                    className="btn btn-danger w-100 p-3"
                    onClick={() => {history.goBack()}}
                  >
                    閉じる
                  </button>
                </div>
              ) 
            : ( <LoadingIndicator />)
        }
      </div>
    </Layout>
  )
}

export default WithdrawalConfirm