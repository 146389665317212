import { useState, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Modal, Image } from "react-bootstrap"
import moment from "moment"
import _ from "lodash"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import AppContext from "../../../Context"
import { LoadingIndicator } from "../../../components/LoadingIndicator"
import { onDeleteAccountRequest } from "../../../actions/other"
import AlertIcon from "../../../icons/alert-red.svg"
import { removeAccessToken } from "../../../utils/auth"

const CloseAccount = () => {
  const { setContext } = useContext(AppContext)
  const [notice, setNotice] = useState("")
  const [isError, setIsError] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const [showNotification, setShowNotification] = useState(false)
  const myProfile = useSelector(state => state.auth.profile)

  const handleConfirm = () => {
    setIsError(false)
    //if (profileUserData?.contract_type === 2) {
    setNotice("2")
    //} else setNotice("退会します、本当によろしいですか？")
    setShowNotification(true)
  }

  const handleCloseNotification = () => {
    setShowNotification(false)
  }

  var endContractTime = null
  if (myProfile?.contract_type === 2 && myProfile?.sex !== 2) {
    endContractTime = moment(myProfile?.contract_end_datetime).format(
      "YYYY/MM/DD"
    )
  } else {
    endContractTime = ""
  }

  const handleClose = () => {
    setShowNotification(false)
    setContext("overlay", <LoadingIndicator />)
    dispatch(
      onDeleteAccountRequest(
        {},
        {
          onSuccess: response => {
            removeAccessToken()
            window.location.reload()
          },
          onFailed: error => {
            setIsError(true)
            setNotice("システムエラー")
          }
        }
      )
    )
  }

  return (
    <Layout>
      <AppBar title="退会について" withBack />
      <div
        className="bg-white d-flex flex-column container pt-5 pb-5 mt-5 align-items-center"
        id="formInput"
      >
        {!_.isEmpty(myProfile) ? (
          <>
            <h6 className="text-danger mb-4 text-center">
              退会する前に、以下の内容をご確認ください。
            </h6>

            <div className="d-flex align-items-start">
              <Image src={AlertIcon} width={30} />
              <h6 className="mt-0 ms-4 mb-4">
                一度退会すると、一定期間再登録することができません。
              </h6>
            </div>

            <div className="d-flex align-items-start">
              <Image src={AlertIcon} width={30} />
              <div className="mt-0 ms-4 mb-4">
                <h6>
                  退会すると、以下の情報を含む全てのアカウント情報が削除され、
                  <span className="text-danger">
                    アプリが使用できなくなります。
                  </span>
                </h6>
                <p>
                  ・プロフィール情報 <br />
                  ・マッチングやメッセージの履歴 <br />
                  {myProfile?.sex === 1 && (
                    <>
                      ・有料会員としての権利 <br />
                      　※現在有料会員の方は退会と同時にプラン解約となります。
                    </>
                  )}
                </p>
                {myProfile?.sex === 1 && (
                  <p className="text-danger">
                    有料プラン有効期限：{endContractTime}
                  </p>
                )}
              </div>
            </div>

            {false && myProfile?.sex === 1 && (
              <div className="d-flex align-items-start">
                <Image src={AlertIcon} width={30} />
                <div className="mt-0 ms-4">
                  <h6>
                    退会すると、一定期間保管された後、以下の情報がすべて削除されます。
                  </h6>
                  <p className="text-danger">
                    有料プラン有効期限：{endContractTime}
                  </p>
                </div>
              </div>
            )}

            <button
              className="btn btn-danger p-3 m-2 mt-4"
              onClick={handleConfirm}
              id="confirmBtn"
            >
              退会手続きを進める
            </button>

            <button
              className="btn p-3 m-2"
              style={{ color: "red", border: "1px solid red" }}
              onClick={() => history.push("/settings")}
              id="confirmBtn"
            >
              戻る
            </button>

            <Modal
              show={showNotification}
              onHide={handleCloseNotification}
              centered
              size="sm"
            >
              <Modal.Body>
                {notice == "2" ? (
                  <h6 className="text-center p-3 border-bottom">
                    ログアウトすることも可能です。
                    <br />
                    それでも、退会しますか？
                    <br />
                    退会されると同時に有料会員の権利も削除され再登録される際は、もう一度決済していただくことになります。
                  </h6>
                ) : (
                  <h6 className="text-center p-3 border-bottom">{notice}</h6>
                )}

                {isError ? (
                  <button
                    className="btn w-100 m-0 rounded-0"
                    onClick={handleCloseNotification}
                  >
                    キャンセル
                  </button>
                ) : (
                  <>
                    <button
                      className="btn text-danger w-100 m-0 rounded-0 border-bottom"
                      onClick={handleClose}
                    >
                      退会する
                    </button>

                    <button
                      className="btn w-100 m-0 rounded-0"
                      onClick={handleCloseNotification}
                    >
                      いいえ
                    </button>
                  </>
                )}
              </Modal.Body>
            </Modal>
          </>
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </Layout>
  )
}

export default CloseAccount
