import _ from "lodash"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useContext, useState, useEffect } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"

import AppContext from "../../../Context"
import routes from "../../../constants/routes"
import { AppBar, Layout, LoadingIndicator } from "../../../components"
import { addEmail, verifyEmail } from "../../../actions"

const VerifyEmail = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { setContext } = useContext(AppContext)

  const [code, setCode] = useState("")
  const [show, setShow] = useState(false)
  const [reSendCode, setReSendCode] = useState(false)
  let [segments, setSements] = useState(_.times(6, ""))

  const handleResendCode = () => {
    setContext("overlay", <LoadingIndicator />)
    setTimeout(() => {
      setReSendCode(false)
    }, 15000)

    const data = {
      email: history.location.state?.email
    }

    dispatch(
      addEmail(data, {
        onSuccess: () => {
          setContext("overlay", null)
        },
        onFailed: () => {
          setContext("overlay", null)
        }
      })
    )

    setReSendCode(true)
  }

  const handleChange = ({ target: { value } }) => {
    setCode(value)
  }

  const handleSegmentChange = (seg, val) => {
    segments[seg] = val
    setSements(segments)

    if (val !== "") {
      // Get the next input field
      const nextSibling = document.querySelector(
        `input[name=segment-${parseInt(seg, 10) + 1}]`
      )

      // If found, focus the next field
      if (nextSibling !== null) {
        nextSibling.focus()
      }
    }

    handleChange({ target: { value: segments.join("") } })
  }

  useEffect(() => {
    const inputs = document.getElementsByClassName("input-segment-sms")

    _.map(inputs, (el, idx) => {
      el.onkeydown = (e) => {
        var key = e.charCode || e.keyCode || 0
        // allow backspace, tab, delete, enter, arrows, numbers and keypad numbers ONLY
        // home, end, period, and numpad decimal

        if (
          !(
            key === 8 ||
            key === 9 ||
            key === 13 ||
            key === 46 ||
            key === 110 ||
            key === 190 ||
            (key >= 35 && key <= 40) ||
            (key >= 48 && key <= 57) ||
            (key >= 96 && key <= 105)
          )
        ) {
          e.preventDefault()
        }

        if (key === 8 && segments[idx - 1] !== undefined) {
          const nexSibling = document.querySelector(
            `input[name=segment-${parseInt(idx, 10) + 1}]`
          )
          if (
            !(nexSibling && nexSibling.length > 0) &&
            segments[idx] !== "" &&
            segments[idx] !== undefined
          ) {
            segments[idx] = ""
          } else {
            segments[idx - 1] = ""
            const prevSibling = document.querySelector(
              `input[name=segment-${parseInt(idx, 10) - 1}]`
            )
            // If found, focus the next field
            if (prevSibling !== null) {
              prevSibling.focus()
            }
          }
          setSements(segments)
        }
      }
    })
  }, [])

  const onSubmit = () => {
    const data = {
      email: history.location.state?.email,
      code: code
    }

    setContext("overlay", <LoadingIndicator />)
    dispatch(
      verifyEmail(data, {
        onSuccess: () => {
          setContext("overlay", null)
          setShow(true)
        },
        onFailed: () => {
          setContext("overlay", null)
          alert("認証コードが正しくありません。")
        }
      })
    )
  }

  return (
    <Layout>
      <AppBar withBack title="認証コード" />
      <hr className="mt-0" />
      <div className="container" style={{ marginTop: 63 }}>
        <div className="d-flex flex-column full-height-with-navbar disable-focus hide-scroll">
          <p className="mt-3 mb-5 fs-6 text-grey">
            認証コードを入力してください
          </p>
          <Form.Group className="mb-3" controlId="otp">
            <Form.Label className="fs-4 text-grey-bold">認証コード</Form.Label>
            <Row>
              {_.map(Array(6), (_v, idx) => (
                <Col key={idx}>
                  <Form.Control
                    className="input-segment-sms"
                    type="tel"
                    name={`segment-${idx}`}
                    maxLength={1}
                    onChange={({ target: { value } }) => {
                      handleSegmentChange(idx, value)
                    }}
                  />
                </Col>
              ))}
            </Row>
          </Form.Group>
          <Button
            className="align-self-center mt-5 w-100 mb-5"
            variant="danger"
            onClick={onSubmit}
            disabled={code.length === 6 ? false : true}
            style={{
              fontSize: 20,
              fontWeight: "bold",
              padding: 12,
              borderRadius: 6
            }}
          >
            次へ
          </Button>
          <Button
            onClick={handleResendCode}
            className="btn-resend mt-4 fs-4 text-grey-bold mt-auto"
            variant="outline-dark"
            size="lg"
            type="button"
            disabled={reSendCode}
          >
            認証コードを再送
          </Button>
          <br />
          <br />
        </div>
      </div>

      <Modal show={show} centered size="sm">
        <Modal.Body>
          <h6 className="text-center p-3">メール登録が完了しました。</h6>
          <button
            className="btn text-danger w-100 m-0 rounded-0 confirm-button"
            onClick={() => {
              history.push(routes.SETTING)
            }}
          >
            OK
          </button>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export default VerifyEmail
