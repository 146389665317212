import { useState, useEffect } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import ja from "date-fns/locale/ja"
import AppContext from "./Context"
import { Overlay, OverlayFilter, Toast, IncomingCallModal } from "./components"
import routes from "./constants/routes"
import {
  SplashScreen,
  Login,
  LoginVeriryPhone,
  Account,
  TakeOverPhone,
  TakeOverVerify,
  PhoneVerification,
  Title,
  Guides,
  Review,
  EditItem,
  Crop,
  Edit,
  SignUp,
  Profile,
  Avatars,
  Filter,
  Users,
  Match,
  UserDetail,
  Likes,
  Chats,
  PrivateChat,
  Schedules,
  CreateSchedule,
  ScheduleDetail,
  OutgoingCall,
  VideoCall,
  LikeExchange,
  MyPage,
  Plan,
  BuyPoint,
  PaymentComplete,
  PointHistories,
  FootPrints,
  Notification,
  DetailNotification,
  VerifyId,
  Settings,
  About,
  TermOfServices,
  PrivacyPolicy,
  SkipUsers,
  HideUsers,
  Law,
  QA,
  Contact,
  CloseAccount,
  MailSettings,
  Withdrawal,
  BankRegister,
  Manual,
  WithdrawalHistory,
  ShowImage,
  WithdrawalConfirm,
  ProfileMedia,
  NotiSetting,
  PaymentType,
  MessageTemplate,
  MessageTemplateEdit,
  VerifyEmail,
  Myplan
} from "./pages"
import Auth from "./Auth"
import { useSelector } from "react-redux"

import {
  MEASUREMENT_ID,
} from "./constants/env"

import "./App.scss"
import ReactGA from 'react-ga4';

registerLocale("ja", ja)
setDefaultLocale("ja")

ReactGA.initialize(MEASUREMENT_ID);

function App() {
  const [context, setContext] = useState({ auth: false })
  const profileUserData = useSelector((state) => state.auth.profile)

  const handleSetContext = (name, value) => {
    setContext({ ...context, [name]: value })
  }

  const handleGetContext = (name) => context[name]

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);


  return (
    <div className="app">
      <AppContext.Provider
        value={{
          getContext: handleGetContext,
          setContext: handleSetContext
        }}
      >
        <Router>
          <Switch>
            <Route exact path="/">
              <SplashScreen />
            </Route>
            <Route exact path={routes.LOGIN}>
              <Login />
            </Route>
            <Route exact path={routes.LOGIN_VERIFY_PHONE}>
              <LoginVeriryPhone />
            </Route>
            <Route exact path={routes.TAKEOVER}>
              <Account />
            </Route>
            <Route exact path={routes.TAKEOVER_UPDATE_PHONE}>
              <TakeOverPhone />
            </Route>
            <Route exact path={routes.TAKEOVER_VERIFY_PHONE}>
              <TakeOverVerify />
            </Route>
            <Route exact path={routes.REGISTER_VERIFY_PHONE}>
              <PhoneVerification />
            </Route>
            <Route exact path={routes.TITLE}>
              <Title />
            </Route>
            <Route exact path={`${routes.GUIDES}/:guide?`}>
              <Guides />
            </Route>
            <Route exact path={routes.PROFILE_REVIEW}>
              <Review />
            </Route>
            <Route exact path={`${routes.PROFILE_EDIT_ITEM}/:field`}>
              <EditItem />
            </Route>
            <Route exact path={`${routes.PROFILE_CROP_IMAGES}/:id`}>
              <Crop />
            </Route>
            <Route exact path={routes.PROFILE_EDIT}>
              <Edit />
            </Route>
            <Route exact path={routes.SIGNUP}>
              <SignUp />
            </Route>
            <Route exact path={routes.REGISTER_PROFILE}>
              <Profile />
            </Route>
            <Route exact path={routes.TERM}>
              <TermOfServices />
            </Route>
            <Auth>
              <Route exact path={routes.MYPLAN}>
                {profileUserData.sex === 1 && <Myplan />}
              </Route>
              <Route exact path={routes.REGISTER_AVATARS}>
                <Avatars />
              </Route>
              <Route exact path={routes.FILTER}>
                <Filter />
              </Route>
              <Route exact path={routes.USERS}>
                <Users />
              </Route>
              <Route exact path={routes.MATCH}>
                <Match />
              </Route>
              <Route path={`${routes.USERS}/:id`}>
                <UserDetail />
              </Route>
              <Route exact path={routes.LIKE}>
                <Likes />
              </Route>
              <Route exact path={routes.CHAT}>
                <Chats />
              </Route>
              <Route exact path={routes.NOTI_SETTING}>
                <NotiSetting />
              </Route>
              <Route exact path={routes.SCHEDULE}>
                <Schedules />
              </Route>
              <Route exact path={`${routes.SCHEDULE}/:id`}>
                <ScheduleDetail />
              </Route>
              <Route exact path={routes.CREATE_SCHEDULE}>
                <CreateSchedule />
              </Route>
              <Route exact path={`${routes.PRIVATE_CHAT}/:id`}>
                <PrivateChat />
              </Route>
              <Route exact path={`${routes.OUTGOING_CALL}/:id`}>
                <OutgoingCall />
              </Route>
              <Route exact path={`${routes.VIDEO_CALL}/:id`}>
                <VideoCall />
              </Route>
              <Route path={routes.EXCHANGE}>
                <LikeExchange />
              </Route>
              <Route exact path={routes.PROFILE_REVIEW}>
                <Review />
              </Route>
              <Route exact path={routes.PROFILE_EDIT}>
                <Edit />
              </Route>
              <Route path={`${routes.PROFILE}`}>
                <MyPage />
              </Route>
              <Route path={routes.PROFILE_MEDIA}>
                <ProfileMedia />
              </Route>
              <Route path={`${routes.PLAN}`}>
                {profileUserData.sex === 1 && <Plan />}
              </Route>
              <Route path={routes.MESSAGE_TEMPLATE}>
                <MessageTemplate />
              </Route>
              <Route path={routes.MESSAGE_TEMPLATE_EDIT}>
                <MessageTemplateEdit />
              </Route>
              <Route path={`${routes.PAYMENT_TYPE}`}>
                <PaymentType />
              </Route>
              <Route path={`${routes.BUY_POINT}`}>
                <BuyPoint />
              </Route>
              <Route path={`${routes.PAYMENT_COMPLETE}`}>
                <PaymentComplete />
              </Route>
              <Route path={`${routes.POINT_HISTORIES}`}>
                <PointHistories />
              </Route>
              <Route path="/footprints">
                <FootPrints />
              </Route>
              <Route path="/notices">
                <Notification />
              </Route>
              <Route path="/notice">
                <DetailNotification />
              </Route>
              <Route path="/verify">
                <VerifyId />
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/privacy">
                <PrivacyPolicy />
              </Route>
              <Route path="/skip">
                <SkipUsers />
              </Route>
              <Route path="/hide">
                <HideUsers />
              </Route>
              <Route path="/law">
                <Law />
              </Route>
              <Route path="/qa">
                <QA />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/close">
                <CloseAccount />
              </Route>
              <Route path="/mail-setting">
                <MailSettings />
              </Route>
              <Route path={routes.VERIFY_EMAIL}>
                <VerifyEmail />
              </Route>
              <Route path="/withdrawal">
                <Withdrawal />
              </Route>
              <Route path="/bank-register">
                <BankRegister />
              </Route>
              <Route path="/manual">
                <Manual />
              </Route>
              <Route path="/withdrawal-history">
                <WithdrawalHistory />
              </Route>
              <Route path="/image">
                <ShowImage />
              </Route>
              <Route path="/withdrawal-confirm">
                <WithdrawalConfirm />
              </Route>
            </Auth>
          </Switch>
          <IncomingCallModal />
        </Router>
        {context.overlayfilter && (
          <OverlayFilter>{context.overlayfilter}</OverlayFilter>
        )}
        {context.overlay && <Overlay>{context.overlay}</Overlay>}
        <Toast />
      </AppContext.Provider>
    </div>
  )
}

export default App
